import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'components/UI/Loader';
import * as R from 'ramda';

import NoteModal from 'pages/ContributionView/components/NoteModal/NoteModalContainer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useSessionAttachments } from 'hooks/useSessionAttachments';
import { isEmptyOrNil } from 'utils/utils';
import { TemplateType } from 'helpers/constants/templateType';
import './BottomPanel.scss';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import styled from 'styled-components';
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';

const StyledProgressWrap = styled(Box)`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: -8px;
  margin-bottom: -8px;
`;
const StyledAttachText = styled.span`
  text-transform: initial;
`;
export const BottomPanel = ({ sessionId, contributionId, attachments }) => {
  const contribution = useContribution();
  const { activeTemplate } = contribution;
  const { themedColor } = getThemedColors(contribution);

  const [modalOpened, setModalOpened] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    progress,
    isFileUpload,
    loading,
    isCoach,
    handleUploadFile,
    handleDownloadFile,
    handleRemoveFile,
  } = useSessionAttachments(sessionId, contributionId, true);

  if (loading) {
    return <Loader />;
  }

  const handleOpenModal = () => {
    setModalOpened(true);
  };

  const handleCloseModal = () => {
    setModalOpened(false);
  };

  return (
    <>
      {activeTemplate != TemplateType.TemplateThree && !isCoach && mobileView ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', color: themedColor }}>
            <div>
              <span style={{ color: themedColor }}>Attachments</span>
              {isEmptyOrNil(attachments) && (
                <span style={{ color: 'gray', marginLeft: '5px', fontSize: '13px' }}>None</span>
              )}
            </div>
            <div>
              <span tabIndex="0" role="button" onClick={handleOpenModal} onKeyPress={handleOpenModal}>
                <span style={{ color: themedColor }}>Personal Notes</span>
                <span style={{ color: 'gray', marginLeft: '5px' }}>&#x2295;</span>
              </span>
            </div>
          </div>
          <div>
            {!isEmptyOrNil(attachments) &&
              attachments.map(
                ({ id: documentId, contentType, documentKeyWithExtension, documentOriginalNameWithExtension }, idx) => (
                  <div key={documentId} className="d-flex align-items-center">
                    <Button
                      classes={{
                        root: 'text-lowercase regular-tex download-attach',
                      }}
                      onClick={handleDownloadFile}
                      disableRipple
                      data-document-id={documentId}
                      data-document-name={documentOriginalNameWithExtension}
                      data-content-type={contentType}
                    >
                      <span>{`${R.inc(idx)}.`}</span>
                      <span className="ml-2">{documentOriginalNameWithExtension}</span>
                    </Button>
                    {isCoach && (
                      <CloseIcon
                        fontSize="small"
                        className="cursor-pointer"
                        onClick={handleRemoveFile}
                        data-document-id={documentId}
                        data-document-extension={documentKeyWithExtension}
                      />
                    )}
                  </div>
                ),
              )}
          </div>
        </>
      ) : (
        <>
          <div className="session-attachments position-relative mt-4">
            <span style={{ color: themedColor }} className="h4">
              Attachments
            </span>
            <span
              tabIndex="0"
              role="button"
              className="h4 notes"
              onClick={handleOpenModal}
              onKeyPress={handleOpenModal}
            >
              <span style={{ color: themedColor }}>Personal Notes</span>
              <span className="ml-3 session-empty-panel-content">Open Notes </span>
            </span>
            <div className="mt-2 attached-file-list">
              {isEmptyOrNil(attachments) ? (
                <span className="session-empty-panel-content">None</span>
              ) : (
                attachments.map(
                  (
                    { id: documentId, contentType, documentKeyWithExtension, documentOriginalNameWithExtension },
                    idx,
                  ) => (
                    <div key={documentId} className="d-flex align-items-center">
                      <Button
                        classes={{
                          root: 'text-lowercase regular-tex download-attach',
                        }}
                        onClick={handleDownloadFile}
                        disableRipple
                        data-document-id={documentId}
                        data-document-name={documentOriginalNameWithExtension}
                        data-content-type={contentType}
                      >
                        <span>{`${R.inc(idx)}.`}</span>
                        <span className="ml-2">{documentOriginalNameWithExtension}</span>
                      </Button>
                      {isCoach && (
                        <CloseIcon
                          fontSize="small"
                          className="cursor-pointer"
                          onClick={handleRemoveFile}
                          data-document-id={documentId}
                          data-document-extension={documentKeyWithExtension}
                        />
                      )}
                    </div>
                  ),
                )
              )}
            </div>
            {isCoach && (
              <>
                <input
                  accept="*"
                  className="d-none"
                  id={`attach-file-${sessionId}`}
                  type="file"
                  onChange={handleUploadFile}
                  multiple
                />
                <label htmlFor={`attach-file-${sessionId}`}>
                  <Button
                    classes={{
                      root: 'position-absolute text-capitalize attach-button',
                    }}
                    disableRipple
                    component="span"
                  >
                    {isFileUpload ? (
                      <StyledProgressWrap position="relative">
                        <CircularProgressWithLabel value={progress} />
                      </StyledProgressWrap>
                    ) : (
                      <AttachFileIcon style={{ color: themedColor }} className="mr-3" />
                    )}

                    <StyledAttachText style={{ color: themedColor }} className="regular-text">
                      Attach a File
                    </StyledAttachText>
                  </Button>
                </label>
              </>
            )}
          </div>
        </>
      )}
      {modalOpened && (
        <NoteModal
          noteProps={{
            classId: sessionId,
            contributionId,
          }}
          isOpen={modalOpened}
          onModalClose={handleCloseModal}
          onConfirm={handleCloseModal}
        />
      )}
    </>
  );
};

BottomPanel.propTypes = {
  number: PropTypes.number.isRequired,
  sessionId: PropTypes.string.isRequired,
  contributionId: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
