import React from 'react';
import MainContainer from 'pages/Collective/Components/MainContainer/MainContainer';
import classes from './AcademyPage.module.scss';
// import { Box, useMediaQuery } from '@mui/material';
// import { theme } from 'styles/theme';
import AcademyCard from 'pages/Collective/Components/Cards/AcademyCard/AcademyCard';
import AcademyService from '../../../../assets/academyService.png';
import SessionsImg from '../../../../assets/SessionsImg.png';
import AllServices from 'pages/Collective/Components/Cards/Component/AllServices';
import AcademyCalendar from 'pages/Collective/Components/Calender/AcademyCalendar';
import SearchBar from 'pages/Collective/Components/SearchBar/SearchBar';
import CalenderIcon from 'pages/Collective/Icons/CalenderIcon';

const AcademyCardData = [
  {
    id: 1,
    image_url: AcademyService,
    title: 'The Cohere Academy',
    subTitle: 'Cohere Team',
    calenderIcon: <CalenderIcon />,
    date: 'Jan 23rd 2023  1:40 am PST',
    paymentType: 'Free',
    btnText: 'Enroll',
  },
  {
    id: 2,
    image_url: AcademyService,
    title: 'Cohere Platform Support & Weekly Office Hours',
    subTitle: 'Cohere Team',
    calenderIcon: <CalenderIcon />,
    date: 'Jan 23rd 2023  1:40 am PST',
    paymentType: 'Free',
    btnText: 'Enroll',
  },
];
const SessionCardData = [
  {
    id: 1,
    image_url: SessionsImg,
    title: 'Cohere Platform Support & Weekly Office Hours',
    subTitle: 'Session Name',
    calenderIcon: <CalenderIcon />,
    date: 'Jan 23rd 2023  1:40 am PST',
    btnText: 'Join',
  },
  ,
  {
    id: 2,
    image_url: SessionsImg,
    title: 'Cohere Platform Support & Weekly Office Hours',
    subTitle: 'Session Name',
    calenderIcon: <CalenderIcon />,
    date: 'Jan 23rd 2023  1:40 am PST',
    btnText: 'Join',
  },
  {
    id: 3,
    image_url: SessionsImg,
    title: 'Cohere Platform Support & Weekly Office Hours',
    subTitle: 'Session Name',
    calenderIcon: <CalenderIcon />,
    date: 'Jan 23rd 2023  1:40 am PST',
    btnText: 'Join',
  },
  {
    id: 4,
    image_url: SessionsImg,
    title: 'Cohere Platform Support & Weekly Office Hours',
    subTitle: 'Session Name',
    calenderIcon: <CalenderIcon />,
    date: 'Jan 23rd 2023  1:40 am PST',
    btnText: 'Join',
  },
  {
    id: 5,
    image_url: SessionsImg,
    title: 'Cohere Platform Support & Weekly Office Hours',
    subTitle: 'Session Name',
    calenderIcon: <CalenderIcon />,
    date: 'Jan 23rd 2023  1:40 am PST',
    btnText: 'Join',
  },
  {
    id: 6,
    image_url: SessionsImg,
    title: 'Cohere Platform Support & Weekly Office Hours',
    subTitle: 'Session Name',
    calenderIcon: <CalenderIcon />,
    date: 'Jan 23rd 2023  1:40 am PST',
    btnText: 'Join',
  },
  {
    id: 7,
    image_url: SessionsImg,
    title: 'Cohere Platform Support & Weekly Office Hours',
    subTitle: 'Session Name',
    calenderIcon: <CalenderIcon />,
    date: 'Jan 23rd 2023  1:40 am PST',
    btnText: 'Join',
  },
  {
    id: 8,
    image_url: SessionsImg,
    title: 'Cohere Platform Support & Weekly Office Hours',
    subTitle: 'Session Name',
    calenderIcon: <CalenderIcon />,
    date: 'Jan 23rd 2023  1:40 am PST',
    btnText: 'Join',
  },
  {
    id: 9,
    image_url: SessionsImg,
    title: 'Cohere Platform Support & Weekly Office Hours',
    subTitle: 'Session Name',
    calenderIcon: <CalenderIcon />,
    date: 'Jan 23rd 2023  1:40 am PST',
    btnText: 'Join',
  },
];

const AcademyPage = () => {
  // const isMobileViewActive = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MainContainer>
      <div className={classes.AcademyPageContainer}>
        <div className={classes.detailsSection}>
          <div className={classes.title}>The Cohere Academy</div>
          <div className={classes.subTitle}>Scale Your Impact & Income</div>
          <div className={classes.text}>
            Cohere is more than an all-in-one platform… It’s also a global community and academy designed to propel you
            forward in your business and life. It connects you with a conscious community of service providers,
            providing a powerful environment to accelerate and transform more lives.
          </div>
        </div>

        <div className={classes.wrapper}>
          <div className={classes.searchComponent}>
            <SearchBar placeholder="Search" variant="outlined" />
          </div>
          <div className={classes.calenderComponent}>
            <AcademyCalendar />
          </div>
        </div>

        <div className={classes.academyServices}>
          <div className={classes.heading}>The Cohere Academy</div>
          <div className={classes.academyServicesCards}>
            {AcademyCardData.map(item => (
              <AcademyCard item={item} />
            ))}
          </div>
        </div>

        <div className={classes.upcomingSessions}>
          <AllServices heading="Upcoming Sessions" seeAll="See All Services" sessionCards={SessionCardData} />
        </div>
      </div>
    </MainContainer>
  );
};

export default AcademyPage;
