import React, { useState, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import { useAccount } from 'hooks';
import * as Yup from 'yup';

import { colors } from 'utils/styles';
import { PageSubtitle } from 'components/UI/Text/TextStyles';
import { PageSubtitleCoach } from 'components/UI/Text/TextStyles';
import Loader from 'components/UI/Loader';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarContributionView.scss';
import { TIMEZONES } from 'constants.js';
import { UserRoles } from 'helpers/constants';
import { TemplateType } from 'helpers/constants/templateType';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors, determineDarkThemedColorToUse } from 'services/contributions.service';
import Button from 'components/FormUI/Button';
import { CircularProgress, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { determineColorToUse } from 'services/contributions.service';
import { lightOrDark } from '../../../../utils/utils';
import ScheduleMeetingModal from './ScheduleMeetingModal';
import TemplateThree from 'pages/ContributionView/Templates/TemplateThree';

const localizer = momentLocalizer(moment);

const allViews = [Views.MONTH];

const CalendarContributionView = ({
  events,
  onSelectEvent,
  onDrillDown,
  calendarRef,
  rescheduleMode,
  dateToShow,
  loadingTimes,
  timeZoneId,
  activeTemplate,
  selectedDate,
  showSelfbookModal,
  setShowSelfbookModal,
}) => {
  const contributionTimes = useSelector(state => state.contributions?.contributionTimes);
  const { currentRole } = useAccount();
  const isTemplateThree = activeTemplate === TemplateType.TemplateThree;
  const isCoach = currentRole === UserRoles.cohealer;
  const modifiedevents = events.filter(e => moment(e.start).isAfter(moment().add(24, 'h')));
  const SubTitle = isCoach || isTemplateThree ? PageSubtitleCoach : PageSubtitle;
  const contribution = useContribution();
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const [isScheduleMeetingVisible, setIsScheduleMeetingVisible] = useState(showSelfbookModal);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  let colorToUse = determineColorToUse(activeContribution);
  const { themedColor } = getThemedColors(contribution);
  const currentDate = moment();
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const closeModal = () => {
    setIsScheduleMeetingVisible(false);
    setShowSelfbookModal(false);
  };

  const onClickOpenModal = () => {
    setIsScheduleMeetingVisible(true);
  };
  const startTimes = contributionTimes?.map(item => item.startTime);
  const filteredDates = startTimes?.filter(date => {
    const dateObj = moment(date);
    return dateObj.isSameOrAfter(currentDate, 'day');
  });

  return (
    <div className="calendar-container" ref={calendarRef}>
      <Grid container justifyContent="space-between" spacing={1} style={{ padding: '5px' }} alignItems="center">
        <Grid item xs={8}>
          <SubTitle style={{ color: themedColor }}>
            Session Availability in {TIMEZONES[timeZoneId]}.{' '}
            {isCoach
              ? 'These are the session times that can be booked.'
              : 'Choose a time on the calendar below that works best for you.'}
          </SubTitle>
        </Grid>
        {isCoach && activeContribution.activeTemplate != TemplateType.TemplateThree && (
          <Grid item xs={4} style={{ display: 'flex' }} justifyContent="flex-end">
            {contributionTimes === undefined ? (
              <CircularProgress style={{ marginLeft: '10px' }} size={20} />
            ) : (
              <Button
                backgroundColor={colorToUse?.PrimaryColorCode}
                textColor={textColor}
                onClick={() => onClickOpenModal()}
                style={{ textTransform: 'capitalize' }}
                variant="secondary"
                className="banner-container__button"
                autoWidth
              >
                Schedule Client
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      {loadingTimes && (
        <div className="custom-calendar" style={{ position: 'relative' }}>
          <div className="rbc-month-view">
            <div className="">
              <Loader relative />
            </div>
          </div>
        </div>
      )}
      {!loadingTimes && (
        <BigCalendar
          className={`custom-calendar ${isDarkThemeEnabled ? 'dark-theme' : ''}`}
          style={{ color: themedColor }}
          localizer={localizer}
          events={rescheduleMode ? modifiedevents : events}
          views={allViews}
          defaultView={Views.MONTH}
          // date={dateToShow}
          step={30}
          showMultiDayTimes
          titleAccessor={event => `${event.title.substring(0, 15)}...`}
          defaultDate={new Date()}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={onSelectEvent}
          onDrillDown={onDrillDown}
          eventPropGetter={event => {
            const newStyle = {
              backgroundColor: colors.darkOceanBlue,
            };

            if (event.isBooked) {
              newStyle.backgroundColor = colors.darkBlue;
            }

            return {
              className: '',
              style: newStyle,
            };
          }}
        />
      )}
      {isScheduleMeetingVisible === true && activeContribution.activeTemplate != TemplateType.TemplateThree && (
        <ScheduleMeetingModal
          isVisible={true}
          closeModal={closeModal}
          isCoach={isCoach}
          paymentInfo={activeContribution?.paymentInfo}
          setIsScheduleMeetingVisible={setIsScheduleMeetingVisible}
          selectedCalDate={moment(filteredDates[0])?.toDate()}
        />
      )}
    </div>
  );
};

CalendarContributionView.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectEvent: PropTypes.func.isRequired,
  calendarRef: PropTypes.shape({ current: PropTypes.any }),
  loadingTimes: PropTypes.bool,
  timeZoneId: PropTypes.string,
};

CalendarContributionView.defaultProps = {
  calendarRef: null,
  loadingTimes: false,
};

const mapStateToProps = ({ contributions }) => ({
  loadingTimes: contributions?.loadingTimes,
});

export default connect(mapStateToProps, null)(CalendarContributionView);
