import React from 'react';
import classes from './AllServicesFilter.module.scss';
import Button from 'components/FormUI/Button';
import Select from 'components/UI/Select';
import CrossIcon from 'pages/Collective/Icons/CrossIcon';

const AllServicesFilter = ({ onCancelClick, onClear = () => {} }) => {
  return (
    <div className={classes.container}>
      <div className={classes.top_container}>
        <div className={classes.heading}>Filter By:</div>
        <div className={classes.cross_btn} onClick={onCancelClick}>
          <CrossIcon />
        </div>
      </div>
      <div className={classes.body_container}>
        <div className={classes.main_container}>
          <div className={classes.heading}>By Category</div>
          <div className={classes.filter_container}>
            <Select
              className={classes.filter}
              disableUnderline={true}
              showHelperText={false}
              fullWidth
              multiple
              items={''}
              value={''}
              classes={{
                select: classes.filter_select,
              }}
              onChange={e => {
                // setFieldValue('contributions', e.target.value);
              }}
              MenuProps={{
                disablePortal: true,
              }}
            />
          </div>
        </div>
        <div className={classes.main_container}>
          <div className={classes.heading}>By Price</div>
          <div className={classes.filter_container}>
            <Select
              className={classes.filter}
              disableUnderline={true}
              showHelperText={false}
              fullWidth
              multiple
              items={''}
              value={''}
              classes={{
                select: classes.filter_select,
              }}
              onChange={e => {
                //   setFieldValue('contributions', e.target.value);
              }}
              MenuProps={{
                disablePortal: true,
              }}
            />
          </div>
        </div>
        <div className={classes.main_container}>
          <div className={classes.heading}>By Type</div>
          <div className={classes.filter_container}>
            <Select
              className={classes.filter}
              disableUnderline={true}
              showHelperText={false}
              fullWidth
              multiple
              items={''}
              value={''}
              classes={{
                select: classes.filter_select,
              }}
              onChange={e => {
                //  setFieldValue('contributions', e.target.value);
              }}
              MenuProps={{
                disablePortal: true,
              }}
            />
          </div>
        </div>

        <div className={classes.footerBtn_container}>
          <Button
            type="button"
            className={classes.cancel_btn}
            autoWidth
            invert
            onClick={() => {
              //   resetForm(getInitial(type));
              onClear();
            }}
          >
            Cancel
          </Button>
          <Button className={classes.submit_btn} autoWidth type="submit" /* onClick={handleSubmit} */>
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AllServicesFilter;
