import React from 'react';

const ScrollRightIcon = ({ width = '32', height = '32' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1433_75257)">
        <rect x="4" y="4" width="24" height="24" rx="12" fill="white" />
        <path
          d="M12.2949 20.59L16.8749 16L12.2949 11.41L13.7049 10L19.7049 16L13.7049 22L12.2949 20.59Z"
          fill="#4A4A4A"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1433_75257"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1433_75257" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1433_75257" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default ScrollRightIcon;
