import React from 'react';

const CrossIcon = ({ width = '16', height = '16', color = '#213649', onClick }) => {
  return (
    <div className="d-flex" style={{ cursor: 'pointer' }} onClick={onClick}>
      <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 2L14 14" stroke={color} stroke-width="1.5" stroke-linecap="round" />
        <path d="M2 14L14 2" stroke={color} stroke-width="1.5" stroke-linecap="round" />
      </svg>
    </div>
  );
};

export default CrossIcon;
