import React, { useRef, useState } from 'react';

import classes from './CollapsedCategories.module.scss';
import ep_more_filled from '../../../../assets/ep_more-filled.png';
import ScrollRightIcon from 'pages/Collective/Icons/ScrollRightIcon';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Box, useMediaQuery } from '@mui/material';
import { theme } from 'styles/theme';
const CollapsedCategories = ({ setShowExtendedCategories, collapsedCategories }) => {
  const scrollRef = useRef(null);
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { pathname } = useLocation();
  const history = useHistory();
  const scrollToRight = () => {
    console.log('Scroll To Right');
    scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.collapsedCategories} ref={scrollRef}>
          {collapsedCategories?.map((category, index) => (
            <div className={classes.category} key={category.id}>
              <img className={classes.img} src={category.imageSrc} />
              <div className={classes.title}>{category.title}</div>
            </div>
          ))}
          {!isMobileView ? (
            <div
              className={classes.seeMore}
              onClick={() => {
                setShowExtendedCategories(true);
              }}
            >
              <img className={classes.img} src={ep_more_filled} alt="ep_more_filled" />
              <div className={classes.text}>More</div>
            </div>
          ) : (
            <div
              className={classes.seeMore}
              onClick={() => {
                history.push('services');
              }}
            >
              <img className={classes.img} src={ep_more_filled} alt="ep_more_filled" />
              <div className={classes.text}>More</div>
            </div>
          )}
        </div>
        {isMobileView && (
          <div className={classes.scrollIcon} onClick={scrollToRight}>
            {' '}
            <ScrollRightIcon />{' '}
          </div>
        )}
      </div>
    </>
  );
};

export default CollapsedCategories;
