import React, { useState } from 'react';
import classes from './AllPage.module.scss';
import MainContainer from 'pages/Collective/Components/MainContainer/MainContainer';
import { Box, useMediaQuery } from '@mui/material';
import { theme } from 'styles/theme';
import CollapsedCategories from 'pages/Collective/Components/CollapsedCategories/CollapsedCategories';
import ExtendedCategories from 'pages/Collective/Components/ExtendedCategories/ExtendedCategories';
import { extendedCategories } from 'pages/Collective/Components/ExtendedCategories/categories';
import { collapsedCategories } from 'pages/Collective/Components/CollapsedCategories/categories';
import ServiceTypesHeader from 'pages/Collective/Components/ServiceTypesHeader/ServiceTypesHeader';
import FilterIcon from 'pages/Collective/Icons/FilterIcon';
import { ClickAwayListener } from '@material-ui/core';
import AllServicesFilter from 'pages/Collective/Components/AllServicesFilter/AllServicesFilter';
import AllServicesSection from './AllServicesSection/AllServicesSection';
import FilterResponsiveIcon from 'pages/Collective/Icons/FilterResponsiveIcon';
import OneToOneSection from './OneToOneSection/OneToOneSection';
import GroupsSection from './GroupsSection/GroupsSection';
import WorkShopsSection from './WorkShopsSection/WorkShopsSection';
import Memberships from './Memberships/Memberships';
import Communities from './Communities/Communities';
import SearchBar from 'pages/Collective/Components/SearchBar/SearchBar';
// import FreeContent from './FreeContent/FreeContent';

export const ALL_TABS = {
  allServices: 'allServices',
  Workshops: 'Workshops',
  Groups: 'Groups',
  Communities: 'Communities',
  Memberships: 'Memberships',
  OneToOne: 'OneToOne',
  // Content: 'Content',
};

const AllPage = () => {
  const isMobileViewActive = useMediaQuery(theme.breakpoints.down('sm'));
  const [showExtendedCategories, setShowExtendedCategories] = useState(false);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('allServices');
  // console.log("activeTab --> ", activeTab);

  const handleFilterClick = () => {
    setFilterPanelOpen(!filterPanelOpen);
  };

  const showActiveSection = activeTab => {
    switch (activeTab) {
      case 'allServices':
        return <AllServicesSection />;
      case 'Workshops':
        return <WorkShopsSection />;
      case 'Groups':
        return <GroupsSection />;
      case 'Communities':
        return <Communities />;
      case 'Memberships':
        return <Memberships />;
      case 'OneToOne':
        return <OneToOneSection />;
      default:
        return <AllServicesSection />;
    }
  };

  /* const showTabs = {
    [ALL_TABS.allServices]: <AllServicesSection />
  } */

  return (
    <MainContainer>
      <div className={classes.allPageContainer}>
        <div className={classes.bannerSection}>
          <div className={classes.contentSection}>
            <div className={isMobileViewActive ? classes.responsiveText : classes.text}>
              Connection, Community and Transformation
            </div>
            <div className={classes.shareLink}>Share your gifts</div>
            <SearchBar placeholder="Search for services and coaches" onChange={() => {}} />
          </div>
          <div className={classes.categoriesSection}>
            {showExtendedCategories ? (
              <ExtendedCategories
                setShowExtendedCategories={setShowExtendedCategories}
                extendedCategories={extendedCategories}
              />
            ) : (
              <CollapsedCategories
                setShowExtendedCategories={setShowExtendedCategories}
                collapsedCategories={collapsedCategories}
              />
            )}
          </div>
        </div>

        <div className={classes.allServiceCards}>
          <div className={classes.serviceCardsHeader}>
            <ServiceTypesHeader setActiveTab={setActiveTab} activeTab={activeTab} />
            {isMobileViewActive ? (
              <div className={classes.filterResponsiveIcon}>
                <FilterResponsiveIcon fill="#2B2B2B" />
              </div>
            ) : (
              <ClickAwayListener
                onClickAway={() => {
                  setFilterPanelOpen(false);
                }}
              >
                <div className={classes.filterContainer}>
                  <div className={classes.filterBtn} onClick={handleFilterClick}>
                    <FilterIcon fill="#2b2b2b" />
                    <div className={classes.text}>Filter</div>
                  </div>
                  {filterPanelOpen && (
                    <AllServicesFilter
                      value={''}
                      onCancelClick={() => {
                        setFilterPanelOpen(false);
                      }}
                      onSubmitClick={''}
                      onClear={() => {
                        setFilterPanelOpen(false);
                      }}
                    />
                  )}
                </div>
              </ClickAwayListener>
            )}
          </div>
          <div className={classes.serviceCardsBody}>
            {showActiveSection(activeTab)}
            {/* {showTabs[activeTab]} */}
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default AllPage;
