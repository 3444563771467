import React from 'react';

const NotificationDot = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5" cy="5" r="3.75" fill="#EF1515" stroke="black" stroke-width="1.5" />
    </svg>
  );
};

export default NotificationDot;
