import React from 'react';

const DownArrow = ({ width = '10', height = '5', onClick }) => {
  return (
    <div onClick={onClick}>
      <svg width={width} height={height} viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M-0.00195312 0L4.99805 5L9.99805 0H-0.00195312Z" fill="white" />
      </svg>
    </div>
  );
};

export default DownArrow;
