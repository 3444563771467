import React, { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { theme } from 'styles/theme';
import Button from 'components/FormUI/Button';
import classes from './AllServices.module.scss';
import ContributionCard from '../ContributionCard/ContributionCard';
import CoachCard from '../CoachCard/CoachCard';
import ScrollRightIcon from 'pages/Collective/Icons/ScrollRightIcon';
import SessionsCard from '../SessionsCard/SessionsCard';

const AllServices = ({ heading, contributionCard, coachCard, sessionCards, seeAll }) => {
  // console.log('contributionCard --> ', contributionCard);
  // console.log('coachCard --> ', coachCard);
  const [showMoreCoachCards, setShowMoreCoachCards] = useState(false);
  const slicedCoachCards = coachCard?.slice(0, 4);
  const remianingCoachCards = coachCard?.slice(4, 8);

  const isMobileViewActive = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className={classes.allServices}>
      <div className={classes.allServicesHeader}>
        <div className={classes.heading}>{heading}</div>
        {!isMobileViewActive && <div className={classes.seeAllLink}>{seeAll}</div>}
      </div>
      <div className={classes.allServicesCards}>
        {contributionCard?.length > 0 && contributionCard?.map(item => <ContributionCard item={item} />)}
        {sessionCards?.length > 0 && sessionCards.map(item => <SessionsCard item={item} />)}

        {!isMobileViewActive ? (
          coachCard?.length > 0 && (
            <>
              {!showMoreCoachCards
                ? slicedCoachCards?.map(item => <CoachCard item={item} />)
                : remianingCoachCards?.map(item => <CoachCard item={item} />)}
              <div className={classes.scrollIcon} onClick={() => setShowMoreCoachCards(!showMoreCoachCards)}>
                <ScrollRightIcon width="24px" height="24px" />
              </div>
            </>
          )
        ) : (
          <>
            {coachCard?.length > 0 && (
              <>
                <div className={classes.allCoachCards}>
                  {coachCard?.map(item => (
                    <CoachCard item={item} />
                  ))}
                </div>
                <div className={classes.coachCardBoxShadow}></div>
              </>
            )}
            <Button className={classes.seeAllButton} onClick={() => {}}>
              See All
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default AllServices;
