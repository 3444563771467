import React from 'react';
import classes from './CommunitiesPage.module.scss';
import MainContainer from 'pages/Collective/Components/MainContainer/MainContainer';
// import { Box, useMediaQuery } from '@mui/material';
// import { theme } from 'styles/theme';

const CommunitiesPage = () => {
  //   const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MainContainer>
      <div className={classes.CommunitiesPage}>Communities Page</div>
    </MainContainer>
  );
};

export default CommunitiesPage;
