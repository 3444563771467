import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { checkToken } from 'utils/request';
import { checkRoles } from 'utils/routing';
import useRouter from 'hooks/useRouter';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import { cloneDeep, isEmpty, isObject } from 'lodash';

const ProtectedRoute = ({
  component: Component,
  authorized,
  onboardingStatus,
  children,
  roles: permittedRoles,
  userRoles,
  account,
  signupType,
  leadUser,
  ...rest
}) => {
  const {
    location,
    domain,
    subdomain,
    query: { chatId = null },
    pathname,
  } = useRouter();

  const isSignup = chatId;
  const isLeadMagnetPage = pathname.includes('/lead-magnet-view/');
  return (
    <Route
      {...rest}
      render={p => {
        if (!authorized) {
          if (isLeadMagnetPage) {
            if (isEmpty(leadUser) || !isObject(leadUser)) {
              const landingPagePath = pathname?.replace('clientpreview', '')?.replace('thankyoupage', 'landingpage');
              return redirectToAppIfIsCoachDomain(domain, landingPagePath);
            }
            return <Component {...p} />;
          }
          if (subdomain) {
            return redirectToAppIfIsCoachDomain(
              domain,
              location.pathname.concat(location.search).concat(location.hash),
            );
          }
          return (
            <Redirect
              to={{
                pathname: isSignup ? '/auth/signup/client' : '/auth/signin',
                state: { from: p.location },
              }}
            />
          );
        }

        if (onboardingStatus === 'Registered') {
          return rest.path === '/auth/signup/continue' ||
            rest.path === '/auth/signup/client/continue' ||
            rest.path === '/auth/coheresignupa/continue' ||
            rest.path === '/auth/coheresignupb/continue' ? (
            <Component {...p} />
          ) : (
            <Redirect
              to={{
                pathname: '/auth/signup/client/continue',
                state: { from: p.location },
              }}
            />
          );
        }

        if (Boolean(permittedRoles.length) && !checkRoles(userRoles, permittedRoles)) {
          return <Redirect to="/dashboard" />;
        }

        if (onboardingStatus === 'InfoAdded') {
          if (rest.path === '/auth/signup/continue') {
            return <Redirect to="/account/billing/coach" />;
          }
          if (rest.path === '/auth/coheresignupa/continue') {
            return <Redirect to="/account/billing/coach" />;
          }
          if (rest.path === '/auth/coheresignupb/continue') {
            return <Redirect to="/account/billing/coach/extended" />;
          }
          if (rest.path === '/auth/coheresignupb/continue') {
            return <Redirect to="/account/billing/six-month" />;
          }
          const { from } = p.location.state || {
            from: { pathname: '/dashboard' },
          };
          return rest.path === '/auth/signup/continue' || rest.path === '/auth/signup/client/continue' ? (
            <Redirect to={from} />
          ) : (
            <Component {...p} />
          );
        }
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.node, PropTypes.elementType]),
  authorized: PropTypes.bool.isRequired,
  onboardingStatus: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ProtectedRoute.defaultProps = {
  children: null,
  component: null,
  roles: [],
};

const mapStateToProps = ({ account, leadMagnets: { leadUser } }) => ({
  authorized: checkToken(account?.token),
  onboardingStatus: (account.user && account.user.onboardingStatus) || '',
  userRoles: account?.roles,
  account: account,
  leadUser,
});

export default connect(mapStateToProps)(ProtectedRoute);
