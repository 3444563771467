import React, { memo, useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Button from 'components/FormUI/Button';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import classes from './Navbar.module.scss';
import { useDispatch } from 'react-redux';
import * as pageActions from 'actions/page';
import { theme } from 'styles/theme';

const Navbar = memo(({ navLinks }) => {
  const dispatch = useDispatch();
  const isMobileSidebarNeeded = useMediaQuery(theme.breakpoints.down('sm'));
  const { pathname } = useLocation();
  // console.log("pathname --> ", pathname);
  const history = useHistory();
  // console.log("history --> ", history);
  // console.log('navLinks  -->', navLinks);

  useEffect(() => {
    console.log('Navbar UseEffect Called');
  }, [navLinks]);

  const toggleMobileSidebar = () => {
    dispatch(pageActions.toggleMobileSidebar());
  };

  return (
    <Box className={isMobileSidebarNeeded ? classes.responsiveBoxContainer : classes.boxContainer}>
      {navLinks?.map((item, index) => {
        const isActive = pathname.includes(item.toLocaleLowerCase());
        return (
          <div
            className={
              isMobileSidebarNeeded ? classes.responsiveBtnWrapper : isActive ? classes.active : classes.inactive
            }
            key={index}
          >
            <Button
              variant="contained"
              value={item}
              className={
                isMobileSidebarNeeded
                  ? isActive
                    ? classes.responsiveActiveBtn
                    : classes.responsiveBtn
                  : isActive
                  ? classes.activeBtn
                  : classes.inactiveBtn
              }
              onClick={() => {
                history.push(`${item.toLocaleLowerCase()}`);
                if (isMobileSidebarNeeded) {
                  toggleMobileSidebar();
                }
              }}
            >
              {item}
              {item === 'Academy' && <Button className={classes.plusBtn}>Plus</Button>}
              {isMobileSidebarNeeded ? item === 'Cohere Plus' && <div className={classes.text}>Platform</div> : null}
            </Button>
          </div>
        );
      })}
    </Box>
  );
});

export default Navbar;
