import React from 'react';
import classes from './AllServicesSection.module.scss';
import TrendingImg from '../../../../../assets/TrendingImg.png';
import TrandingImg2 from '../../../../../assets/TrandingImg2.png';
import GroupsIcon from 'pages/Collective/Icons/GroupsIcon';
import JasonStatham1 from '../../../../../assets/JasonStatham1.png';
import JasonStatham2 from '../../../../../assets/JasonStatham2.png';
import CalenderIcon from 'pages/Collective/Icons/CalenderIcon';
import AllServices from 'pages/Collective/Components/Cards/Component/AllServices';
import CoachesSection from '../CoachesSection/CoachesSection';
const ContributionCardData = [
  {
    id: 1,
    image_url: TrandingImg2,
    title: 'The Perfect Business Model - Online Marketing',
    category: 'Group',
    categoryIcon: <GroupsIcon />,
    paymentOption: 'Free',
    coachProfileImg: JasonStatham1,
    coachName: 'Jason Statham',
    calenderIcon: <CalenderIcon />,
    date: 'Jan 23rd 2023  1:40 am PST',
  },
  {
    id: 2,
    image_url: TrandingImg2,
    title: 'Real Estate Investment',
    category: 'Group',
    categoryIcon: <GroupsIcon />,
    paymentOption: 'Free',
    coachProfileImg: JasonStatham2,
    coachName: 'Jason Statham',
    calenderIcon: <CalenderIcon />,
    date: 'Jan 23rd 2023  1:40 am PST',
  },
  {
    id: 3,
    image_url: TrandingImg2,
    title: 'The Perfect Business Model - Online Marketing',
    category: 'Group',
    categoryIcon: <GroupsIcon />,
    paymentOption: 'Free',
    coachProfileImg: JasonStatham1,
    coachName: 'Jason Statham',
    calenderIcon: <CalenderIcon />,
    date: 'Jan 23rd 2023  1:40 am PST',
  },
  {
    id: 4,
    image_url: TrandingImg2,
    title: 'Real Estate Investment',
    category: 'Group',
    categoryIcon: <GroupsIcon />,
    paymentOption: 'Free',
    coachProfileImg: JasonStatham2,
    coachName: 'Jason Statham',
    calenderIcon: <CalenderIcon />,
    date: 'Jan 23rd 2023  1:40 am PST',
  },
];

const AllServicesSection = () => {
  return (
    <>
      <div className={classes.AllServicesSection}>
        <AllServices heading="Trending Services" contributionCard={ContributionCardData} seeAll="See All Services" />
        <AllServices heading="For You" contributionCard={ContributionCardData} seeAll="See All Services" />
        <AllServices heading="Live This Week" contributionCard={ContributionCardData} seeAll="See All Services" />
        <AllServices heading="Trending Workshops" contributionCard={ContributionCardData} seeAll="See All Services" />
        <AllServices
          heading="Trending Group Courses"
          contributionCard={ContributionCardData}
          seeAll="See All Services"
        />
        <AllServices heading="Trending Communities" contributionCard={ContributionCardData} seeAll="See All Services" />
      </div>
      <CoachesSection />
    </>
  );
};

export default AllServicesSection;
