import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import { useHttp, useAccount } from 'hooks';

import useContribution from 'pages/ContributionView/hooks/useContribution';
import { determineDarkThemedColorToUse } from 'services/contributions.service';
import TextField from 'components/UI/TextField';
import UploadAttachment from './UploadAttachment';
import './MessageInput.scss';
import InputEmoji from 'react-input-emoji';

const MessageInput = ({
  onSendTextMessage,
  onSendMediaMessage,
  onTyping,
  contribution,
  members,
  channel,
  applyTheming,
}) => {
  const [messageText, setMessageText] = useState('');
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isUploadingAttachment, setIsUploadingAttachment] = useState(false);
  const { request } = useHttp();
  const { user } = useAccount();
  const sendMsgNotification = (msgId, msg) => {
    let tempThread = channel;
    let mems = [],
      isGroup = tempThread?.attributes?.ContributionId ? true : false;
    for (let m of members) {
      if (m.identity != user.email) {
        mems.push(`${m.identity}`);
      }
    }
    let params = {
      isGroupChat: isGroup,
      memberEmails: mems,
      channelSid: tempThread.sid,
      messageId: msgId,
      message: msg,
    };
    if (isGroup) {
      params['contributionId'] = tempThread?.attributes?.ContributionId;
    }

    request('api/Notification/SendChatPushNotification', 'POST', params)
      .then(token => {})
      .catch(() => {});
  };

  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);

  const sendTextMessageAsync = async () => {
    if (!messageText || isSendingMessage) {
      return;
    }

    setIsSendingMessage(true);
    const messageSent = await onSendTextMessage(messageText);
    if (messageSent) {
      if (messageText.length > 150) {
        let newString = messageText.match(/.{1,150}/g);
        sendMsgNotification('', `${newString[0]}...`);
      } else {
        sendMsgNotification('', messageText);
      }
      setMessageText('');
    }
    setIsSendingMessage(false);
  };

  const sendMediaMessageAsync = async file => {
    if (isUploadingAttachment) {
      return;
    }

    setIsUploadingAttachment(true);
    await onSendMediaMessage(file);
    sendMsgNotification('', 'Media');
    setIsUploadingAttachment(false);
  };
  return (
    <div className={isDarkThemeEnabled ? 'chat-message-input-dark-themed' : 'chat-message-input'}>
      <InputEmoji
        placeholder="Type a message"
        multiline
        fullWidth
        applyTheming={isDarkThemeEnabled}
        rowsMax={6}
        onEnter={sendTextMessageAsync}
        value={messageText}
        onChange={e => setMessageText(e)}
      />
      <UploadAttachment
        className="chat-message-input__upload-attachment"
        isUploadingAttachment={isUploadingAttachment}
        onUploadAttachment={sendMediaMessageAsync}
      />
      <IconButton
        className="chat-message-input__send"
        color="inherit"
        onClick={sendTextMessageAsync}
        disabled={!messageText || isSendingMessage}
      >
        <SendIcon />
      </IconButton>
    </div>
  );
};

MessageInput.propTypes = {
  onSendTextMessage: PropTypes.func.isRequired,
  onSendMediaMessage: PropTypes.func.isRequired,
  onTyping: PropTypes.func.isRequired,
  applyTheming: PropTypes.bool,
};
MessageInput.defaultProps = {
  applyTheming: false,
};

export default MessageInput;
