import categoryDummyImg from '../../../../assets/categoryDummyImg.png';

export const collapsedCategories = [
  {
    id: 1,
    imageSrc: categoryDummyImg,
    title: 'Accountability',
  },
  {
    id: 2,
    imageSrc: categoryDummyImg,
    title: 'Breathwork',
  },
  {
    id: 3,
    imageSrc: categoryDummyImg,
    title: 'Business Coaching',
  },
  {
    id: 4,
    imageSrc: categoryDummyImg,
    title: 'Business Mentorship',
  },
  {
    id: 5,
    imageSrc: categoryDummyImg,
    title: 'Consulting',
  },
  {
    id: 6,
    imageSrc: categoryDummyImg,
    title: 'Educator',
  },
  {
    id: 7,
    imageSrc: categoryDummyImg,
    title: 'Educator',
  },
  {
    id: 8,
    imageSrc: categoryDummyImg,
    title: 'Emotional Wellbeing',
  },
];
