import React from 'react';

const GroupsIcon = ({ width = '20px', height = '21px', fill = '#4A4A4A' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1377_4520)">
        <path
          d="M4.66667 11.0553C5.4 11.0553 6 10.4553 6 9.72201C6 8.98867 5.4 8.38867 4.66667 8.38867C3.93333 8.38867 3.33333 8.98867 3.33333 9.72201C3.33333 10.4553 3.93333 11.0553 4.66667 11.0553ZM5.42 11.7887C5.17333 11.7487 4.92667 11.722 4.66667 11.722C4.00667 11.722 3.38 11.862 2.81333 12.1087C2.32 12.322 2 12.802 2 13.342V14.3887H5V13.3153C5 12.762 5.15333 12.242 5.42 11.7887ZM15.3333 11.0553C16.0667 11.0553 16.6667 10.4553 16.6667 9.72201C16.6667 8.98867 16.0667 8.38867 15.3333 8.38867C14.6 8.38867 14 8.98867 14 9.72201C14 10.4553 14.6 11.0553 15.3333 11.0553ZM18 13.342C18 12.802 17.68 12.322 17.1867 12.1087C16.62 11.862 15.9933 11.722 15.3333 11.722C15.0733 11.722 14.8267 11.7487 14.58 11.7887C14.8467 12.242 15 12.762 15 13.3153V14.3887H18V13.342ZM12.8267 11.4887C12.0467 11.142 11.0867 10.8887 10 10.8887C8.91333 10.8887 7.95333 11.1487 7.17333 11.4887C6.45333 11.8087 6 12.5287 6 13.3153V14.3887H14V13.3153C14 12.5287 13.5467 11.8087 12.8267 11.4887ZM7.38 13.0553C7.44 12.902 7.46667 12.7953 7.98667 12.5953C8.63333 12.342 9.31333 12.222 10 12.222C10.6867 12.222 11.3667 12.342 12.0133 12.5953C12.5267 12.7953 12.5533 12.902 12.62 13.0553H7.38ZM10 7.72201C10.3667 7.72201 10.6667 8.02201 10.6667 8.38867C10.6667 8.75534 10.3667 9.05534 10 9.05534C9.63333 9.05534 9.33333 8.75534 9.33333 8.38867C9.33333 8.02201 9.63333 7.72201 10 7.72201ZM10 6.38867C8.89333 6.38867 8 7.28201 8 8.38867C8 9.49534 8.89333 10.3887 10 10.3887C11.1067 10.3887 12 9.49534 12 8.38867C12 7.28201 11.1067 6.38867 10 6.38867Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1377_4520">
          <rect width="20" height="20" fill="white" transform="translate(0 0.388672)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GroupsIcon;
