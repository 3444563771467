import React from 'react';
import PropTypes from 'prop-types';
import PopupComp from 'components/Popup/Popup';

export const RemoveCalendarModal = ({ isOpen, onCancel, onSubmit }) => {
  return (
    <PopupComp
      title="Remove calendar"
      text="Do you want to remove this calendar?"
      open={isOpen}
      onCloseText="No"
      onClose={onCancel}
      onSubmit={onSubmit}
    />
  );
};

export const DefaultCalendarModal = ({ isOpen, onCancel, onSubmit }) => {
  return (
    <PopupComp
      title="Default Calendar for 1:1 Services"
      text="By making this your default account for 1:1 services, calendar invites will come from this account"
      open={isOpen}
      onCloseText="No"
      onClose={onCancel}
      onSubmit={onSubmit}
    />
  );
};

RemoveCalendarModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
