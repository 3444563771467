import React, { memo, useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Button from 'components/FormUI/Button';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import classes from './BottomNavigation.module.scss';
import { theme } from 'styles/theme';

const BottomNavigation = memo(({ bottomNavLinks }) => {
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { pathname } = useLocation();
  // console.log("pathname --> ", pathname);
  const history = useHistory();
  // console.log("history --> ", history);
  // console.log('navLinks  -->', navLinks);

  useEffect(() => {
    console.log('Navbar UseEffect Called');
  }, [bottomNavLinks]);
  return (
    <Box className={classes.boxContainer}>
      {bottomNavLinks?.map((item, index) => {
        const isActive = pathname.includes(item?.name?.toLocaleLowerCase());
        return (
          <div
            className={isActive ? classes.active : classes.inactive}
            key={index}
            onClick={() => {
              history.push(`${item?.name?.toLocaleLowerCase()}`);
            }}
          >
            <div className={classes.icon}>{item.icon}</div>
            <div className={classes.name}>{item.name}</div>
          </div>
        );
      })}
    </Box>
  );
});

export default BottomNavigation;
