import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from 'utils/styles';
import Portal from './Portal';

const rotate = keyframes`
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(315deg);
  }
`;

const rotate2 = keyframes`
  to {
    transform: rotate(-360deg);
  }
`;

const rotate3 = keyframes`
  from {
    transform: rotate(-70deg);
  }
  to {
    transform: rotate(290deg);
  }
`;

const StyledOverlay = styled.div`
  // background-color: rgba(255, 255, 255, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 1000;
`;

const StyledOverlayRelative = styled(StyledOverlay)`
  position: ${({ ismodal }) => (ismodal ? `absolute` : `relative`)};
  ${({ withoutTopOffset }) => !withoutTopOffset && `top: 210px;`}
  ${({ flex }) => flex && `flex: ${flex}`}
  ${({ scale }) => scale && `transform: scale(${scale});`},
`;

const StyledCircle = styled.div`
  position: absolute;
  border-radius: 50%;
  border: 3px solid transparent;
`;

const StyledCircleOne = styled(StyledCircle)`
  width: 80px;
  height: 80px;
  border-top-color: ${colors.lightBrown};
  border-bottom-color: ${colors.lightBrown};
  animation: ${rotate} 3s linear infinite;
`;

const StyledCircleTwo = styled(StyledCircle)`
  width: 60px;
  height: 60px;
  border-left-color: ${colors.lightBrown};
  border-top-color: ${colors.lightBrown};
  border-bottom-color: ${colors.lightBrown};
  animation: ${rotate2} 2s linear infinite;
`;

const StyledCircleThree = styled(StyledCircle)`
  width: 40px;
  height: 40px;
  border-top-color: ${colors.lightBrown};
  border-left-color: ${colors.lightBrown};
  animation: ${rotate3} 2.5s linear infinite;
`;

const Loader = React.memo(({ relative, withoutTopOffset, ismodal = false, flex, scale }) => {
  const OverLay = relative ? StyledOverlayRelative : StyledOverlay;
  const Cover = relative ? React.Fragment : Portal;
  return (
    <Cover>
      <OverLay withoutTopOffset flex={flex} scale={scale} ismodal={ismodal}>
        <StyledCircleOne />
        <StyledCircleTwo />
        <StyledCircleThree />
      </OverLay>
    </Cover>
  );
});

Loader.propTypes = {
  relative: PropTypes.bool,
  scale: PropTypes.number,
  withoutTopOffset: PropTypes.bool,
};

Loader.defaultProps = {
  relative: false,
  scale: null,
  withoutTopOffset: false,
};

export default Loader;
