import React from 'react';
import classes from './AllServicesCards.module.scss';
import ServiceCard from '../ServiceCard/ServiceCard';

const AllServicesCards = ({ heading, cardData }) => {
  return (
    <div className={classes.allServiceCrads}>
      <div className={classes.heading}>{heading}</div>
      <div className={classes.cardsContainer}>
        {cardData?.map(item => (
          <ServiceCard item={item} />
        ))}
      </div>
    </div>
  );
};

export default AllServicesCards;
