import React from 'react';
import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import CalendarSvg from '../icons/CalendarIcon';
import TimeSvg from '../icons/TimeIcon';

const BookingSummary = ({
  coachAvatarUrl,
  serviceProviderName,
  duration,
  contributionTitle,
  purpose,
  accentColorCode,
  viewPurpose,
  primaryColorCode,
}) => {
  const easyBookingData = useSelector(state => state?.easyBooking);
  const { timeZones } = useSelector(state => state?.timeZone);
  function getShortNameByCountryName(countryName) {
    const timezone = timeZones.find(tz => tz.countryName === countryName);
    return timezone ? timezone.shortName : 'PST';
  }

  return (
    <div className="user-details-side">
      <div className="user-details">
        <Avatar src={coachAvatarUrl} /> <p style={{ color: accentColorCode }}>{`${serviceProviderName}`}</p>
      </div>
      <div className="contribution-title">{contributionTitle}</div>
      <div className="duration-details">{`${duration} Minutes Session`}</div>
      <div className="selected-date-time">
        <CalendarSvg color={primaryColorCode} />
        <p className="selected-date">{moment(easyBookingData?.selectedDate).format('MMM D')}</p>
        {easyBookingData?.selectedSlot && (
          <>
            <TimeSvg color={primaryColorCode} />
            <p>{moment(easyBookingData?.selectedSlot?.start).format('h:mm a')}</p>
            {easyBookingData?.timeZone && (
              <p
                style={{
                  fontFamily: 'Poppins',
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '19px',
                  letterSpacing: '0px',
                  textAlign: 'center',
                  backgroundColor: '#AAABAD33',
                  color: '#7B8180',
                  padding: '0 2px',
                  borderRadius: '5px',
                }}
              >
                {getShortNameByCountryName(easyBookingData?.timeZone)}
              </p>
            )}
          </>
        )}
      </div>
      {viewPurpose === false && purpose?.length > 0 && (
        <div className="easy-booking-purpose" dangerouslySetInnerHTML={{ __html: purpose }}></div>
      )}
    </div>
  );
};

BookingSummary.propTypes = {
  serviceProviderName: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  coachAvatarUrl: PropTypes.func.isRequired,
  contributionTitle: PropTypes.string.isRequired,
  purpose: PropTypes.string.isRequired,
  accentColorCode: PropTypes.string.isRequired,
  primaryColorCode: PropTypes.string.isRequired,
};

export default BookingSummary;
