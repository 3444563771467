import MainContainer from 'pages/Collective/Components/MainContainer/MainContainer';
import React from 'react';
import classes from './MyJourneyPage.module.scss';
import { Box, useMediaQuery } from '@mui/material';
import { theme } from 'styles/theme';

const MyJourneyPage = () => {
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <MainContainer>
      <div className={classes.myJourneyContainer}>Journey Page</div>
    </MainContainer>
  );
};

export default MyJourneyPage;
