import React from 'react';
import classes from './Footer.module.scss';
import CohereLogo from 'pages/Collective/Icons/CohereLogo';
import FacebookIcon from 'pages/Collective/Icons/FacebookIcon';
import TwitterIcon from 'pages/Collective/Icons/TwitterIcon';
import YoutubeIcon from 'pages/Collective/Icons/YoutubeIcon';
import LinkedInIcon from 'pages/Collective/Icons/LinkedInIcon';

const Footer = () => {
  return (
    <div className={classes.footerContainer}>
      <div className={classes.footerTop}>
        <div className={classes.leftCol}>
          <CohereLogo width="207px" height="70px" />
        </div>
        <div className={classes.centerCol}>
          <div className={classes.centerColLink}>Become a coach</div>
          <div className={classes.centerColLink}>Cohere platform</div>
          <div className={classes.centerColLink}>Privacy policy</div>
        </div>
        <div className={classes.rightCol}>
          <div className={classes.socialIconLink}>
            <FacebookIcon />
          </div>
          <div className={classes.socialIconLink}>
            {' '}
            <TwitterIcon />
          </div>
          <div className={classes.socialIconLink}>
            <YoutubeIcon />
          </div>
          <div className={classes.socialIconLink}>
            <LinkedInIcon />
          </div>
        </div>
      </div>
      <div className={classes.footerBottom}>Copyright @ 2023 Cohere All rights reserved.</div>
    </div>
  );
};

export default Footer;
