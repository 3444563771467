import React from 'react';
import classes from './ExtendedCategories.module.scss';
import ep_more_filled from '../../../../assets/ep_more-filled.png';

const RenderCategory = ({ type, items }) => {
  return (
    <div className={classes.categoryWrapper}>
      <div className={classes.heading}>{type}</div>
      <div className={classes.content}>
        {items?.map((category, index) => (
          <div className={classes.category} key={category.id}>
            <img className={classes.img} src={category.imageSrc} />
            <div className={classes.title}>{category.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
const ExtendedCategories = ({ setShowExtendedCategories, extendedCategories }) => {
  return (
    <>
      <RenderCategory
        type="Coaching"
        items={extendedCategories.filter((category, index) => category.categoryName === 'Coaching')}
      />
      <RenderCategory
        type="Healing"
        items={extendedCategories.filter((category, index) => category.categoryName === 'Healing')}
      />
      <RenderCategory
        type="Consulting"
        items={extendedCategories.filter((category, index) => category.categoryName === 'Consulting')}
      />
      <RenderCategory
        type="Spiritual"
        items={extendedCategories.filter((category, index) => category.categoryName === 'Spiritual')}
      />
      <div
        className={classes.seeLess}
        onClick={() => {
          setShowExtendedCategories(false);
        }}
      >
        <img className={classes.img} src={ep_more_filled} alt="ep_more_filled" />
        <div className={classes.text}>Less</div>
      </div>
    </>
  );
};

export default ExtendedCategories;
