import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Button from 'components/FormUI/Button';
import classes from './ServiceTypesHeader.module.scss';
import { theme } from 'styles/theme';

const headerTabs = [
  { name: 'All', id: 'allServices' },
  { name: 'Workshops', id: 'Workshops' },
  { name: 'Groups', id: 'Groups' },
  { name: 'Communities', id: 'Communities' },
  { name: 'Memberships', id: 'Memberships' },
  { name: '1:1', id: 'OneToOne' },
  // { name: 'Free Content', id: 'Content' },
];

const ServiceTypesHeader = ({ setActiveTab, activeTab }) => {
  // console.log("activeTab --> ", activeTab);
  const isMobileViewActive = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className={isMobileViewActive ? classes.responsiveBoxContainer : classes.boxContainer}>
      {headerTabs?.map((item, index) => {
        const isActive = activeTab === item?.id;
        return (
          <div className={isActive ? classes.active : classes.inactive}>
            <Button
              variant="contained"
              value={item?.name}
              className={
                isMobileViewActive
                  ? isActive
                    ? classes.responsiveActiveLink
                    : classes.responsiveInactiveLink
                  : isActive
                  ? classes.activeLink
                  : classes.inactiveLink
              }
              onClick={() => {
                setActiveTab(item?.id);
              }}
            >
              {item?.name}
            </Button>
          </div>
        );
      })}
    </Box>
  );
};

export default ServiceTypesHeader;
