import React from 'react';

const FavoriteEmptyIcon = () => {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.948 0C14.034 0 12.197 0.891 10.998 2.299C9.79905 0.891 7.96205 0 6.04805 0C2.66005 0 -0.00195312 2.662 -0.00195312 6.05C-0.00195312 10.208 3.73805 13.596 9.40305 18.744L10.998 20.185L12.593 18.733C18.258 13.596 21.998 10.208 21.998 6.05C21.998 2.662 19.336 0 15.948 0ZM11.108 17.105L10.998 17.215L10.888 17.105C5.65205 12.364 2.19805 9.229 2.19805 6.05C2.19805 3.85 3.84805 2.2 6.04805 2.2C7.74205 2.2 9.39205 3.289 9.97505 4.796H12.032C12.604 3.289 14.254 2.2 15.948 2.2C18.148 2.2 19.798 3.85 19.798 6.05C19.798 9.229 16.344 12.364 11.108 17.105Z"
        fill="white"
      />
    </svg>
  );
};

export default FavoriteEmptyIcon;
