import React from 'react';
import classes from './Services.module.scss';
import MainContainer from 'pages/Collective/Components/MainContainer/MainContainer';
import AllServicesCards from './components/AllServicesCards/AllServicesCards';
import ServiceImage from '../../../../assets/ServicesCardImg.png';

const CardData = [
  {
    imageSrc: ServiceImage,
    title: 'Business Coaching',
    noOfServices: '12 Services',
  },
  {
    imageSrc: ServiceImage,
    title: 'Business Coaching',
    noOfServices: '12 Services',
  },
  {
    imageSrc: ServiceImage,
    title: 'Business Coaching',
    noOfServices: '12 Services',
  },
  {
    imageSrc: ServiceImage,
    title: 'Business Coaching',
    noOfServices: '12 Services',
  },
  {
    imageSrc: ServiceImage,
    title: 'Business Coaching',
    noOfServices: '12 Services',
  },
  {
    imageSrc: ServiceImage,
    title: 'Business Coaching',
    noOfServices: '12 Services',
  },
];

const Services = () => {
  return (
    <MainContainer>
      <div className={classes.servicesContainer}>
        <AllServicesCards heading="Coaching" cardData={CardData} />
        <AllServicesCards heading="Healing" cardData={CardData} />
        <AllServicesCards heading="Consulting" cardData={CardData} />
        <AllServicesCards heading="Spiritual" cardData={CardData} />
      </div>
    </MainContainer>
  );
};

export default Services;
