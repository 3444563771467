import React from 'react';

const LinkedInIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.28 0H0.72C0.32175 0 0 0.32175 0 0.72V17.28C0 17.6783 0.32175 18 0.72 18H17.28C17.6783 18 18 17.6783 18 17.28V0.72C18 0.32175 17.6783 0 17.28 0ZM5.33925 15.3383H2.6685V6.74775H5.33925V15.3383ZM4.005 5.57325C3.15 5.57325 2.457 4.88025 2.457 4.02525C2.457 3.17025 3.15 2.47725 4.005 2.47725C4.85775 2.47725 5.553 3.17025 5.553 4.02525C5.55075 4.88025 4.85775 5.57325 4.005 5.57325ZM15.3383 15.3383H12.6698V11.16C12.6698 10.1632 12.6518 8.883 11.2815 8.883C9.89325 8.883 9.6795 9.9675 9.6795 11.088V15.3383H7.01325V6.74775H9.57375V7.92225H9.60975C9.96525 7.24725 10.836 6.534 12.1365 6.534C14.841 6.534 15.3383 8.31375 15.3383 10.6267V15.3383Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkedInIcon;
