import React from 'react';

const TwitterIcon = () => {
  return (
    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.7171 2.13817C21.9023 2.48965 21.0156 2.7426 20.1023 2.84112C21.0449 2.27929 21.7611 1.3926 22.0993 0.338166C21.2286 0.857396 20.2434 1.23817 19.2182 1.43521C18.3875 0.548521 17.2079 0 15.9005 0C13.3842 0 11.3605 2.03965 11.3605 4.5426C11.3605 4.89408 11.4032 5.24556 11.4724 5.58373C7.70463 5.38669 4.34427 3.58669 2.11025 0.830769C1.71617 1.5071 1.4925 2.27929 1.4925 3.12337C1.4925 4.6997 2.29398 6.08964 3.51617 6.9071C2.77061 6.87781 2.06765 6.66746 1.46321 6.32929V6.38521C1.46321 8.5926 3.02357 10.4219 5.10315 10.8426C4.72238 10.9411 4.31499 10.997 3.90759 10.997C3.61203 10.997 3.33244 10.9678 3.05019 10.9278C3.62534 12.7278 5.30019 14.0352 7.29457 14.0778C5.73422 15.3 3.77978 16.0189 1.65759 16.0189C1.27682 16.0189 0.92534 16.0056 0.560547 15.963C2.57356 17.2544 4.96203 18 7.53422 18C15.8845 18 20.4537 11.0822 20.4537 5.07781C20.4537 4.88077 20.4537 4.68373 20.4404 4.48669C21.3245 3.83964 22.0993 3.03817 22.7171 2.13817Z"
        fill="white"
      />
    </svg>
  );
};

export default TwitterIcon;
