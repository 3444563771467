import React from 'react';

const ForwardSvg = () => {
  return (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.849474 1.29286L6.14551 6.00062L0.849474 10.7084"
        stroke="#666666"
        stroke-width="1.35002"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ForwardSvg;
