import React from 'react';

const AllPageSvgIcon = ({ width = '18', height = '18' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 18C7.755 18 6.585 17.7638 5.49 17.2912C4.395 16.8187 3.4425 16.1775 2.6325 15.3675C1.8225 14.5575 1.18125 13.605 0.70875 12.51C0.23625 11.415 0 10.245 0 9C0 7.755 0.23625 6.585 0.70875 5.49C1.18125 4.395 1.8225 3.4425 2.6325 2.6325C3.4425 1.8225 4.395 1.18125 5.49 0.70875C6.585 0.23625 7.755 0 9 0C10.245 0 11.415 0.23625 12.51 0.70875C13.605 1.18125 14.5575 1.8225 15.3675 2.6325C16.1775 3.4425 16.8187 4.395 17.2912 5.49C17.7638 6.585 18 7.755 18 9C18 10.245 17.7638 11.415 17.2912 12.51C16.8187 13.605 16.1775 14.5575 15.3675 15.3675C14.5575 16.1775 13.605 16.8187 12.51 17.2912C11.415 17.7638 10.245 18 9 18ZM9 16.2C11.01 16.2 12.7125 15.5025 14.1075 14.1075C15.5025 12.7125 16.2 11.01 16.2 9C16.2 8.895 16.1963 8.78625 16.1888 8.67375C16.1813 8.56125 16.1775 8.4675 16.1775 8.3925C16.1025 8.8275 15.9 9.1875 15.57 9.4725C15.24 9.7575 14.85 9.9 14.4 9.9H12.6C12.105 9.9 11.6813 9.72375 11.3288 9.37125C10.9763 9.01875 10.8 8.595 10.8 8.1V7.2H7.2V5.4C7.2 4.905 7.37625 4.48125 7.72875 4.12875C8.08125 3.77625 8.505 3.6 9 3.6H9.9C9.9 3.255 9.99375 2.95125 10.1812 2.68875C10.3687 2.42625 10.5975 2.2125 10.8675 2.0475C10.5675 1.9725 10.2638 1.9125 9.95625 1.8675C9.64875 1.8225 9.33 1.8 9 1.8C6.99 1.8 5.2875 2.4975 3.8925 3.8925C2.4975 5.2875 1.8 6.99 1.8 9H6.3C7.29 9 8.1375 9.3525 8.8425 10.0575C9.5475 10.7625 9.9 11.61 9.9 12.6V13.5H7.2V15.975C7.5 16.05 7.79625 16.1062 8.08875 16.1437C8.38125 16.1812 8.685 16.2 9 16.2Z"
        fill="white"
      />
    </svg>
  );
};

export default AllPageSvgIcon;
