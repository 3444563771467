import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ChipInput from 'material-ui-chip-input';
import { colors } from '../../utils/styles';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';

const StyledInput = styled(ChipInput)`
  display: inline-block;
  margin-left: 10px;
  width: 100%;
  box-sizing: border-box;
  vertical-align: middle !important;

  input {
    color: ${props => props.color};
    &::placeholder {
      color: ${props => props.color};
    }
  }
`;

const StyledError = styled.p`
  margin: 10px 0 0 0;
  text-align: right;
  font-family: Avenir;
  font-size: 12px;
  font-weight: normal;
  color: tomato;
  max-width: 250px;
  overflow-wrap: anywhere;
`;

const StyledInstuction = styled.p`
  margin: 10px 0 0 0;
  text-align: right;
  font-size: 90%;
  font-family: Avenir;
  font-size: 12px;
  font-weight: normal;
  color: ${colors.black};
`;

const StyledEmailInputWrapper = styled.div`
  flex-grow: 1;
`;

const InputEmailsComponent = ({
  emails,
  handleSetEmails,
  emailsError,
  setEmailsError,
  disabled = false,
  applyTheming = false,
}) => {
  const [error, setError] = useState(null);

  const contribution = useContribution();
  const { themedColor } = getThemedColors(contribution);

  const isEmail = useCallback(email => /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email), []);

  const isInList = useCallback(email => emails.includes(email), [emails]);

  const isValid = useCallback(
    email => {
      let errorEmail = null;

      if (!isEmail(email)) {
        errorEmail = `${email} is not a valid email address.`;
      }

      if (isInList(email)) {
        errorEmail = `${email} has already been added.`;
      }

      if (errorEmail) {
        setError(errorEmail);
        setEmailsError({ isError: true, errorMessage: errorEmail });
        return false;
      }

      return true;
    },
    [isEmail, isInList, setEmailsError],
  );

  const handleChange = useCallback(() => {
    if (error) {
      setEmailsError({ isError: false, errorMessage: null });
    }
    setError(null);
  }, [error, setEmailsError]);

  const handleKeyDown = useCallback(
    email => {
      if (email && isValid(email)) {
        handleSetEmails([...emails, email]);
      }
    },
    [emails, handleSetEmails, isValid],
  );

  const handleDelete = useCallback(
    toBeRemoved => {
      handleSetEmails(emails.filter(email => email !== toBeRemoved));
    },
    [emails, handleSetEmails],
  );

  return (
    <StyledEmailInputWrapper>
      <StyledInput
        fullWidthInput
        value={emails}
        color={applyTheming ? themedColor : ''}
        disabled={disabled}
        newChipKeys={[',']}
        onAdd={chip => handleKeyDown(chip)}
        onDelete={(chip, index) => handleDelete(chip, index)}
        onChange={handleChange}
        onUpdateInput={handleChange}
        blurBehavior="add"
        error={error}
        placeholder="example@cohere.com"
        label={error && 'Error'}
        className="emailInput"
      />

      {/* {error ? <StyledError>{error}</StyledError> : <StyledInstuction>Separate emails with a comma.</StyledInstuction>} */}
      {error && <StyledError>{error}</StyledError>}
      {emailsError.isError && !error && (
        <StyledError>{emailsError.errorMessage || 'Something wrong. Please try again.'}</StyledError>
      )}
    </StyledEmailInputWrapper>
  );
};

InputEmailsComponent.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSetEmails: PropTypes.func.isRequired,
  emailsError: PropTypes.shape({
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
  }).isRequired,
  setEmailsError: PropTypes.func.isRequired,
  applyTheming: PropTypes.bool.isRequired,
};

export default InputEmailsComponent;
