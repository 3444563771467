import React, { useState } from 'react';
import classes from './AcademyCalendar.module.scss';
import CalenderIconFilled from 'pages/Collective/Icons/CalenderIconFilled';
import { useMediaQuery } from '@mui/material';
import { theme } from 'styles/theme';
import CalenderMobileIcon from 'pages/Collective/Icons/CalenderMobileIcon';
import Modal from 'components/UI/Modal';
import CustomBigCalendar from './CustomBigCalendar/CustomBigCalendar';

const AcademyCalendar = () => {
  const [showCalenderModal, setShowCalenderModal] = useState(false);
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <div className={classes.calenderBox} onClick={() => setShowCalenderModal(true)}>
        {isMobileView ? (
          <CalenderMobileIcon fill="#2B2B2B" />
        ) : (
          <>
            <CalenderIconFilled fill="#2B2B2B" />
            <div className={classes.text}>Calender</div>
          </>
        )}
      </div>

      {showCalenderModal && (
        <Modal
          isOpen={showCalenderModal}
          title="Filter by Date"
          onCancel={() => setShowCalenderModal(false)}
          minWidth={isMobileView ? '360px' : '444px'}
          maxWidth={isMobileView ? '360px' : '444px'}
          disableFooter={true}
        >
          <CustomBigCalendar />
        </Modal>
      )}
    </>
  );
};

export default AcademyCalendar;
