import React from 'react';
import classes from './ContributionCard.module.scss';
import ShareIcon from 'pages/Collective/Icons/ShareIcon';
import Favorite from 'pages/Collective/Icons/Favorite';

const ContributionCard = ({ item }) => {
  return (
    <div className={classes.card}>
      <div className={classes.hoverIcons}>
        <ShareIcon />
        <Favorite />
      </div>
      <img className={classes.image} src={item.image_url} />
      <div className={classes.description}>
        <div className={classes.title}>{item.title}</div>
        <div className={classes.coachProfile}>
          <img className={classes.coachImage} src={item.coachProfileImg} />
          <div className={classes.coachName}>{item.coachName} </div>
        </div>
        <div className={classes.categoryPayment}>
          <div className={classes.category}>
            {item.categoryIcon}
            <div className={classes.categoryName}> {item.category} </div>
          </div>
          <div className={classes.paymentOption}>{item.paymentOption}</div>
        </div>
        <div className={classes.calender}>
          {item.calenderIcon}
          <div className={classes.date}> {item.date}</div>
        </div>
      </div>
    </div>
  );
};

export default ContributionCard;
