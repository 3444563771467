import React from 'react';

const ShareIcon = () => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.829102" width="28" height="28" rx="14" fill="white" />
      <g clip-path="url(#clip0_209_22820)" transform="scale(0.8)" transform-origin="center">
        <path
          d="M18.667 18.9091C17.907 18.9091 17.227 19.2091 16.707 19.6791L9.57699 15.5291C9.62699 15.2991 9.66699 15.0691 9.66699 14.8291C9.66699 14.5891 9.62699 14.3591 9.57699 14.1291L16.627 10.0191C17.167 10.5191 17.877 10.8291 18.667 10.8291C20.327 10.8291 21.667 9.4891 21.667 7.8291C21.667 6.1691 20.327 4.8291 18.667 4.8291C17.007 4.8291 15.667 6.1691 15.667 7.8291C15.667 8.0691 15.707 8.2991 15.757 8.5291L8.70699 12.6391C8.16699 12.1391 7.45699 11.8291 6.66699 11.8291C5.00699 11.8291 3.66699 13.1691 3.66699 14.8291C3.66699 16.4891 5.00699 17.8291 6.66699 17.8291C7.45699 17.8291 8.16699 17.5191 8.70699 17.0191L15.827 21.1791C15.777 21.3891 15.747 21.6091 15.747 21.8291C15.747 23.4391 17.057 24.7491 18.667 24.7491C20.277 24.7491 21.587 23.4391 21.587 21.8291C21.587 20.2191 20.277 18.9091 18.667 18.9091ZM18.667 6.8291C19.217 6.8291 19.667 7.2791 19.667 7.8291C19.667 8.3791 19.217 8.8291 18.667 8.8291C18.117 8.8291 17.667 8.3791 17.667 7.8291C17.667 7.2791 18.117 6.8291 18.667 6.8291ZM6.66699 15.8291C6.11699 15.8291 5.66699 15.3791 5.66699 14.8291C5.66699 14.2791 6.11699 13.8291 6.66699 13.8291C7.21699 13.8291 7.66699 14.2791 7.66699 14.8291C7.66699 15.3791 7.21699 15.8291 6.66699 15.8291ZM18.667 22.8491C18.117 22.8491 17.667 22.3991 17.667 21.8491C17.667 21.2991 18.117 20.8491 18.667 20.8491C19.217 20.8491 19.667 21.2991 19.667 21.8491C19.667 22.3991 19.217 22.8491 18.667 22.8491Z"
          fill="#E1BB62"
        />
      </g>
      <defs>
        <clipPath id="clip0_209_22820">
          <rect width="24" height="24" fill="white" transform="translate(2 2.8291)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShareIcon;
