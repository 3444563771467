import AllServices from 'pages/Collective/Components/Cards/Component/AllServices';
import React from 'react';
import coachProfile from '../../../../../assets/coachProfile.png';
import coachProfile2 from '../../../../../assets/coachprofile2.png';
import classes from './CoachesSection.module.scss';

const CoachCardData = [
  {
    id: 1,
    img_url: coachProfile,
    coachName: 'Annie Schultz',
    numberOfClients: 1393,
    numberOfServices: 7,
    tags: ['Self Development', 'Life Coaching', 'Business Coaching', '+3'],
  },
  {
    id: 2,
    img_url: coachProfile,
    coachName: 'Annie Schultz',
    numberOfClients: 1393,
    numberOfServices: 7,
    tags: ['Self Development', 'Life Coaching', 'Business Coaching', '+3'],
  },
  {
    id: 3,
    img_url: coachProfile,
    coachName: 'Annie Schultz',
    numberOfClients: 1393,
    numberOfServices: 7,
    tags: ['Self Development', 'Life Coaching', 'Business Coaching', '+3'],
  },
  {
    id: 4,
    img_url: coachProfile,
    coachName: 'Annie Schultz',
    numberOfClients: 1393,
    numberOfServices: 7,
    tags: ['Self Development', 'Life Coaching', 'Business Coaching', '+3'],
  },
  {
    id: 5,
    img_url: coachProfile2,
    coachName: 'Annie Schultz',
    numberOfClients: 1393,
    numberOfServices: 7,
    tags: ['Self Development', 'Life Coaching', 'Business Coaching', '+3'],
  },
  {
    id: 6,
    img_url: coachProfile2,
    coachName: 'Annie Schultz',
    numberOfClients: 1393,
    numberOfServices: 7,
    tags: ['Self Development', 'Life Coaching', 'Business Coaching', '+3'],
  },
  {
    id: 7,
    img_url: coachProfile2,
    coachName: 'Annie Schultz',
    numberOfClients: 1393,
    numberOfServices: 7,
    tags: ['Self Development', 'Life Coaching', 'Business Coaching', '+3'],
  },
  {
    id: 8,
    img_url: coachProfile2,
    coachName: 'Annie Schultz',
    numberOfClients: 1393,
    numberOfServices: 7,
    tags: ['Self Development', 'Life Coaching', 'Business Coaching', '+3'],
  },
  {
    id: 9,
    img_url: coachProfile2,
    coachName: 'Annie Schultz',
    numberOfClients: 1393,
    numberOfServices: 7,
    tags: ['Self Development', 'Life Coaching', 'Business Coaching', '+3'],
  },
];
const CoachesSection = () => {
  return (
    <div className={classes.CoachesSection}>
      <AllServices heading="Trending Coaches" coachCard={CoachCardData} seeAll={'See All Coaches'} />
      <AllServices heading="Trending Healers" coachCard={CoachCardData} seeAll={'See All Coaches'} />
    </div>
  );
};

export default CoachesSection;
