import React, { useEffect, useState, useCallback } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarContributionView.scss';
import {
  Grid,
  TextField,
  Typography,
  Tooltip,
  CircularProgress,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Checkbox,
} from '@material-ui/core';
import * as S from '../../../ContributionView/components/ShareModal/Content/styled';
// import Select from 'components/UI/Select';
import UserSelectionInputFields from './UserSelectionInputFields';
import Loader from 'components/UI/Loader';
import { useSelector } from 'react-redux';
import {
  createAccessCode,
  createContract,
  determineDarkThemedColorToUse,
  getAllContracts,
  getSingleContract,
  saveOneToOneSelfBookClient,
  updateContract,
  updateContribution,
} from 'services/contributions.service';
import copyContributionLinkToClipboard from 'utils/copyContributionLinkToClipboard';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import useRouter from 'hooks/useRouter';
import { TIMEZONES } from 'constants.js';
import useAccount from 'hooks/useAccount';
import moment from 'moment';
import { StyleLabelSignature } from 'pages/CreateContribution/style';
import CreateCustomWaiver from 'pages/CreateContribution/components/CreateCustomWaiver';
import Modal from 'components/UI/Modal';
import { useSaveContribution } from 'hooks/useSaveContribution';
import { saveContribution, saveContributionToLS } from 'actions/contributions';
import { lightOrDark } from 'utils/utils';
const SESSION_TITLE = 'Send a 1:1 Calendar Invite';
const BUTTON_TITLE = 'Share a link';
const TOOLTIP_TITLE = 'Copied to clipboard';

const customStyles = {
  popper: {
    marginTop: '5px', // Add a 5px margin-top to the select's popup
  },
};

const initialWaiverData = {
  formName: '',
  clientName: '',
  clientSignature: '',
  description: '',
  completeDescription: '',
  templateId: null,
  saveTemplate: false,
  templatesList: [],
};

function InvitationForm({
  formik,
  meetingForm,
  closeDropDown,
  handleCopyToClipboard,
  colorToUse,
  closeModal,
  isLoading,
  selectOptions,
  isExistingClient,
  setIsExistingClient,
  themedColor,
  selectClientOptions,
  emailsList,
  setEmailsList,
  onChangeClient,
  selectedClient,
  filterClient,
  setMeetingForm,
  tempTimeSlots,
  paymentInfo,
  onOptionSelect,
  setDisableContributionSelection,
  isMasterCalendar,
  clientData,
}) {
  const [fetchedId, setfetchedId] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [loader, setLoader] = useState(false);
  const onCloseTooltip = useCallback(() => setTooltipOpen(false), [tooltipOpen]);
  const [showShareAlertModal, setShowShareAlertModal] = useState(false);
  const [allContractsFetched, setallContractsFetched] = useState([]);
  const { domain, query } = useRouter();
  const { user } = useAccount();
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const [isElectronicSignatureActive, setIsElectronicSignatureActive] = useState(activeContribution?.customWaiverId);
  const [isCustomWaiverAcitve, setIsCustomWaiverAcitve] = useState(activeContribution?.customWaiverId);
  const [openCustomWaiver, setOpenCustomWaiver] = useState(false);
  const [customWaiverId, setCustomWaiverId] = useState(activeContribution.customWaiverId);
  const isEditing = !!query.id;
  const { onSave: onSaveContribution } = useSaveContribution(isEditing);
  const [loading, setLoading] = useState(false);
  const [waiverData, setWaiverData] = useState(initialWaiverData);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [templateChanged, settemplateChanged] = useState(false);
  const [tempData, setTempData] = useState({});
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const isDarkThemeEnabled = isMasterCalendar ? false : determineDarkThemedColorToUse(activeContribution);
  const footerText = () => {
    let text = `${moment(meetingForm?.date).format('MMMM Do YYYY')} ${meetingForm?.timeSlot}`;
    text = text.replace('-', ' - ');
    return text;
    // if (!!meetingForm?.clientName?.length) {
    //   text += ` - ${meetingForm?.clientName}`;
    //   return text;
    // }
    // return text + ` - ${meetingForm?.name} `;
  };
  const [isSignRequired, setIsSignRequired] = useState(false);
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const getSelectedTimeSlot = () => {
    const selectedTimeSlot = tempTimeSlots.find(slot => slot.isSelected);
    return selectedTimeSlot;
  };
  useEffect(() => {
    filterClient();
    setErrorState(false);
  }, [selectedClient]);

  useEffect(() => {
    setDisableContributionSelection(true);
  });

  const showCustomWaiver = async () => {
    setIsCustomWaiverAcitve(true);
    setIsElectronicSignatureActive(true);
    getSingleContractApi().then(() => {
      setOpenCustomWaiver(!openCustomWaiver);
    });
    try {
      await getAllContracts({ coachId: user.id }).then(res => {
        const activeWaiver = res.find(option => option.id === activeContribution.customWaiverId);
        const tempTemplateOptions = res?.map(option => {
          return {
            title: option.formName,
            formName: option.formName,
            formDescription: option.formDescription,
            formText: option.formText,
            value: option.id,
          };
        });
        setTemplateOptions(tempTemplateOptions);
        if (activeWaiver) {
          setWaiverData({
            ...waiverData,
            formName: activeWaiver?.formName,
            description: activeWaiver?.formDescription,
            completeDescription: activeWaiver?.formText,
            templateId: activeWaiver?.id,
            id: activeWaiver?.id,
            saveTemplate: false,
            templatesList: [...tempTemplateOptions],
          });
          setTemplateOptions([...tempTemplateOptions]);
          setOpenCustomWaiver(true);
        } else {
          setWaiverData({
            ...waiverData,
            templatesList: [...templateOptions, ...tempTemplateOptions],
          });
          setallContractsFetched(tempTemplateOptions);
          setOpenCustomWaiver(true);
        }
      });
    } catch (e) {
      setOpenCustomWaiver(true);
    }
  };

  const onSubmitWaiverForm = values => {
    let tempWaiverData = waiverData;
    const finalValues = {
      templateId: values.id,
      formName: values.formName,
      formDescription: values.description,
      formText: values.completeDescription,
      coachId: user.id,
      isTemplate: values.saveTemplate,
    };
    const isMatched = isFormMatched(values);
    if (isMatched !== -1) {
      if (
        `${values?.formName?.toLowerCase()}` !== `${tempWaiverData?.templatesList[isMatched]?.formName?.toLowerCase()}`
      ) {
        addNewFormTemplate(finalValues);
      } else {
        setTempData(finalValues);
        setConfirmationPopup(true);
      }
    }
    if (isMatched === -1) {
      addNewFormTemplate(finalValues);
    }
    setTempData(finalValues);
    setIsCustomWaiverAcitve(true);
    setIsElectronicSignatureActive(true);
  };

  const isFormMatched = value => {
    const index = waiverData.templatesList.findIndex(template => template.value === value.templateId);
    return index;
  };

  const addNewFormTemplate = async finalValues => {
    finalValues = {
      ...finalValues,
      isSelfBooked: true,
      clientEmail:
        emailsList.length === 0 ? meetingForm?.client?.clientEmail || selectedClient?.clientEmail : emailsList,
      contributionId: activeContribution.id,
    };
    let tempWaiverData = { ...waiverData };
    let contribData = { ...activeContribution };
    createContract(finalValues).then(data => {
      tempWaiverData = {
        ...tempWaiverData,
        formName: data?.formName,
        formDescription: data?.formDescription,
        formText: data?.formText,
        description: data?.formDescription,
        templateId: data.id,
        id: data.id,
        completeDescription: data?.formText,
      };
      // tempWaiverData.templatesList.push({
      //   id: data.id,
      //   coachId: user.id,
      //   formName: data?.formName,
      //   formDescription: data?.formDescription,
      //   formText: data?.formText,
      // });
      templateOptions.push({
        title: data.formName,
        value: data.id,
      });
      // setWaiverData({ ...initialWaiverData });
      contribData = {
        ...contribData,
        customWaiverId: tempWaiverData.templateId,
      };
    });
    // await onSaveContribution(contribData);
    setOpenCustomWaiver(false);
  };

  const updateFormTemplate = async () => {
    let tempWaiverData = { ...waiverData };
    const finalValues = {
      id: tempData.templateId,
      coachId: tempData.coachId,
      formDescription: tempData.formDescription,
      formName: tempData.formName,
      formText: tempData.formText,
      isTemplate: tempData.isTemplate,
      clientEmail: !emailsList.length ? meetingForm.client.clientEmail : emailsList,
      isSelfBooked: true,
      contributionId: activeContribution.id,
    };
    updateContract(finalValues).then(data => {
      if (data) {
        tempWaiverData = {
          ...tempWaiverData,
          formName: data?.formName,
          description: data?.formDescription,
          completeDescription: data?.formText,
        };
        const updatedIndex = waiverData.templatesList.findIndex(form => form.id === data.id);
        if (updatedIndex !== -1) {
          tempWaiverData.templatesList[updatedIndex].formName = tempWaiverData?.formName;
          tempWaiverData.templatesList[updatedIndex].formDescription = tempWaiverData?.description;
          tempWaiverData.templatesList[updatedIndex].formText = tempWaiverData?.formText;
        }
        setWaiverData({ ...initialWaiverData });
        setConfirmationPopup(false);
      }
    });
    const contribData = {
      ...activeContribution,
      customWaiverId: finalValues.id,
    };
    await onSaveContribution(contribData);
    setOpenCustomWaiver(!openCustomWaiver);
  };
  const copyToClipboard = async text => {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };
  const onSubmitWaiverConfirmation = () => {
    updateFormTemplate();
    setConfirmationPopup(false);
  };

  const onSubmitSentInviteForm = ({ link = false }) => {
    let accessCode;
    let tempValues;
    if (emailsList.length > 0 && isExistingClient === false) {
      tempValues = {
        availabilityTimeId: getSelectedTimeSlot().id,
        offset: getSelectedTimeSlot().offset,
        selectedSlotEndDateTime: getSelectedTimeSlot().end.format('YYYY-MM-DDTHH:mm:00'),
        selectedSlotStartDateTime: getSelectedTimeSlot().start.format('YYYY-MM-DDTHH:mm:00'),
        email: emailsList,
        priceOption: formik.values.price?.value.toString(),
        clientName: meetingForm.name,
        contributionId: activeContribution.id,
        sendViaEmail: true,
        sharedViaLink: link,
        accessCode: accessCode,
        sessionName: formik.values.coachSessionTitle,
      };
    } else if (isExistingClient === true) {
      tempValues = {
        clientName: formik.values.client.clientName.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)
          ? formik.values.client.clientName?.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)[1]
          : formik.values.client.clientName,
        availabilityTimeId: getSelectedTimeSlot().id,
        offset: getSelectedTimeSlot().offset,
        selectedSlotEndDateTime: getSelectedTimeSlot().end,
        selectedSlotStartDateTime: getSelectedTimeSlot().start,
        clientId: formik.values.client.value,
        email: null,
        priceOption: formik.values.price?.value.toString(),
        contributionId: activeContribution.id,
        sendViaEmail: false,
        sharedViaLink: link,
        accessCode: accessCode,
        sessionName: formik.values.coachSessionTitle,
      };
    }

    if (formik.values.price.value === 'Free') {
      setLoader(true);
      createAccessCode(activeContribution.id).then(data => {
        tempValues = {
          ...tempValues,
          priceOption: formik.values.price?.value.toString(),
          accessCode: data.code,
        };
        saveOneToOneSelfBookClient(tempValues)
          .then(response => {
            copyToClipboard(`${window.location.origin}contribution-view/${activeContribution.id}/${response.id}/about`);
            copyContributionLinkToClipboard({ contributionId: activeContribution.id, code: response.id });
            setTooltipOpen(true);
            setErrorState(false);
          })
          .catch(() => {
            setErrorState(true);
            setLoader(false);
          });
      });
    } else if (formik.values.price.value != 'Free') {
      setLoader(true);
      tempValues = {
        ...tempValues,
        accessCode: null,
        priceOption: formik.values.price?.value.toString(),
      };
      saveOneToOneSelfBookClient(tempValues)
        .then(response => {
          copyToClipboard(`${window.location.origin}contribution-view/${activeContribution.id}/${response.id}/about`);
          copyContributionLinkToClipboard({ contributionId: activeContribution.id, code: response.id });
          setTooltipOpen(true);
          setErrorState(false);
        })
        .catch(() => {
          setLoader(false);
          setErrorState(true);
        });
    }
    setShowShareAlertModal(false);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
    setTimeout(() => {
      closeModal();
    }, 4000);

    return true;
  };

  // useEffect(() => {
  //   if (activeContribution.customWaiverId != null) {
  //     getSingleContractApi();
  //   }
  // }, [
  //   updateState
  //   //  onChangeClient
  //   //  onOptionSelect
  // ]);

  const getSingleContractApi = async () => {
    setLoading(true);
    try {
      getSingleContract(activeContribution?.id, !emailsList.length ? selectedClient.clientEmail : emailsList).then(
        data => {
          setTimeout(() => {
            setWaiverData({
              ...waiverData,
              formName: data?.formName,
              formDescription: data?.formDescription,
              formText: data?.formText,
              description: data?.formDescription,
              templateId: data.id,
              id: data.id,
              completeDescription: data?.formText,
            });
          }, 2000);
        },
      );
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <Grid container>
        <Grid item xs={12} md={12} style={{ padding: 'auto 4px' }}>
          <TextField
            value={formik.values.coachSessionTitle}
            onChange={e => {
              formik.setFieldValue('coachSessionTitle', e.target.value);
              formik.setTouched({ price: false });
            }}
            id="standard-full-width"
            label={<span style={{ color: isDarkThemeEnabled ? '#fff' : 'black' }}>Title (Optional)</span>}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Example: Lifestyle training"
            fullWidth
            style={{ marginTop: '0px', marginBottom: '8px' }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl
            fullWidth
            className={isDarkThemeEnabled ? 'price-select-popup-dark' : 'price-select-popup'}
            style={{ position: 'relative' }}
          >
            <div>
              <span style={{ fontSize: 12 }}>Price</span>
              {formik.values.price === '' && (
                <InputLabel
                  style={{ position: 'absolute', top: 0, left: 0, marginTop: 10, marginBottom: 0, color: '#a2a2a2' }}
                  shrink={formik.values.price !== ''}
                  htmlFor="my-select"
                >
                  Select Price
                </InputLabel>
              )}
            </div>
            <Select
              style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}
              value={formik.values.price?.title || ''}
              error={formik.errors.price?.title && true}
              classes={{
                paper: isDarkThemeEnabled ? 'is-dark-theme' : '',
              }}
              id="standard-full-width"
              placeholder="Select Price"
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: 10,
                    background: isDarkThemeEnabled ? '#696969' : 'white',
                    color: isDarkThemeEnabled ? 'white' : 'black',
                  },
                },
              }}
              inputProps={{
                placeholder: 'Select Price',
                name: 'price',
                id: 'price-select',
                style: customStyles.popper,
              }}
              renderValue={value => (value === '' ? '' : value)}
              onChange={e => {
                formik.setFieldValue('price', e.target.value);
              }}
            >
              {selectOptions?.map(option => (
                <MenuItem key={option.value} value={option}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
            {formik?.touched?.price && formik?.errors?.price && (
              <CommonErrorMessage message={'Price is required'} align="left" color="red" />
            )}{' '}
          </FormControl>
        </Grid>
      </Grid>
      <UserSelectionInputFields
        formik={formik}
        setEmailsList={setEmailsList}
        selectOptions={selectClientOptions}
        themedColor={themedColor}
        emailsList={emailsList}
        onChangeClient={onChangeClient}
        selectedClient={selectedClient}
        setIsExistingClient={setIsExistingClient}
        isExistingClient={isExistingClient}
        filterClient={filterClient}
        paymentInfo={paymentInfo}
        onOptionSelect={onOptionSelect}
        closeDropDown={closeDropDown}
        setMeetingForm={setMeetingForm}
        meetingForm={meetingForm}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: activeContribution?.customWaiverId != null ? '15px' : '',
        }}
      >
        {formik.values.price.length > 0 || formik.values.price === null ? (
          <div>
            <Tooltip className="cursor-pointer" title={TOOLTIP_TITLE} open={tooltipOpen} onClose={onCloseTooltip} arrow>
              {loader ? (
                <CircularProgress style={{ marginLeft: '10px' }} size={20} />
              ) : (
                <S.CopyToClipboardBlockDefault
                  className="share-btn"
                  style={{
                    padding: '5px 10px',
                    borderRadius: '0px',
                    background: isDarkThemeEnabled ? '#CDBA8F' : '#f4f4f4',
                    color: isDarkThemeEnabled ? 'white' : 'black',
                  }}
                  onClick={() => {
                    if (formik.values.price.length > 0 || formik.values.price > 0) {
                      setShowShareAlertModal(true);
                    } else {
                      formik.setTouched({ price: true, coachSessionTitle: false });
                      formik.setErrors({ price: 'Price is required' });
                    }
                  }}
                >
                  <S.ButtonIcon
                    aria-label="close modal"
                    style={{ background: isDarkThemeEnabled ? 'white' : '#f4f4f4' }}
                    component="span"
                  >
                    <S.ShareIcon color={isDarkThemeEnabled ? 'white' : 'black'} />
                  </S.ButtonIcon>
                  {BUTTON_TITLE}
                </S.CopyToClipboardBlockDefault>
              )}
            </Tooltip>
          </div>
        ) : (
          <Tooltip className="cursor-pointer" title={TOOLTIP_TITLE} open={tooltipOpen} onClose={onCloseTooltip} arrow>
            {loader ? (
              <CircularProgress style={{ marginLeft: '10px' }} size={20} />
            ) : (
              <S.CopyToClipboardBlockDefault
                className="share-btn"
                style={{
                  padding: '5px 10px',
                  borderRadius: '0px',
                  background: isMasterCalendar ? '#CDBA8F' : colorToUse.PrimaryColorCode,
                  color: isMasterCalendar ? 'white' : textColor,
                }}
                onClick={() => {
                  if (
                    formik.values?.price &&
                    (formik.values?.price?.value.length > 0 || formik.values?.price?.value > 0)
                  ) {
                    setShowShareAlertModal(true);
                  } else {
                    formik.setTouched({ price: true, coachSessionTitle: false });
                    formik.setErrors({ price: 'Price is required' });
                  }
                }}
              >
                <S.ButtonIcon
                  aria-label="close modal"
                  component="span"
                  style={isMasterCalendar ? {} : { background: colorToUse.PrimaryColorCode }}
                >
                  <S.ShareIcon color={isMasterCalendar ? 'white' : textColor} />
                </S.ButtonIcon>
                {BUTTON_TITLE}
              </S.CopyToClipboardBlockDefault>
            )}
          </Tooltip>
        )}
      </div>
      {activeContribution?.customWaiverId != null && (
        <Grid container style={{ marginTop: 30, marginLeft: '-8px' }}>
          {/* <StyleLabelSignature>
            <Checkbox
              style={{ padding: '4px' }}
              id="create-contribution-agreement"
              color="primary"
              checked={isElectronicSignatureActive}
              onChange={() => {
                setIsCustomWaiverAcitve(!isCustomWaiverAcitve);
                setIsElectronicSignatureActive(!isElectronicSignatureActive);
              }}
            />
            Require electronic signature from your clients
          </StyleLabelSignature> */}
          <StyleLabelSignature>
            <Checkbox
              style={{ padding: '4px' }}
              id="create-contribution-agreement"
              color="primary"
              checked={isCustomWaiverAcitve}
              onChange={e => {
                setIsCustomWaiverAcitve(!isCustomWaiverAcitve);
                setIsElectronicSignatureActive(!isElectronicSignatureActive);
                showCustomWaiver();
              }}
            />
            <p
              style={{ display: 'inline', margin: '0px' }}
              onClick={() => {
                showCustomWaiver();
              }}
            >
              Use custom contract - ({' '}
              <p style={{ display: 'inline', textDecoration: 'underline', cursor: 'pointer' }}>edit contract</p> )
            </p>
          </StyleLabelSignature>
        </Grid>
      )}
      <Grid container style={{ margin: '15px 0px' }}>
        <Typography style={{ color: isDarkThemeEnabled ? '#fff' : 'black' }}>
          {footerText()} {TIMEZONES[user?.timeZoneId]}
          <span
            onClick={() => {
              redirectToAppIfIsCoachDomain(domain, '/account/profile');
            }}
            style={{ color: isMasterCalendar ? '#CDBA8F' : colorToUse.PrimaryColorCode, cursor: 'pointer' }}
          >
            {' '}
            Update My Timezone
          </span>
        </Typography>
        <Typography style={{ color: isDarkThemeEnabled ? '#fff' : '#00000099' }}>
          Please note, this session time will be blocked off from your calendar until your clients confirm.
        </Typography>
      </Grid>
      {openCustomWaiver && (
        <CreateCustomWaiver
          isOpen={openCustomWaiver}
          onSubmit={values => onSubmitWaiverForm(values)}
          contribution={activeContribution}
          waiverData={waiverData}
          isDarkModeAllowed={true}
          setCustomWaiverId={setCustomWaiverId}
          settemplateChanged={settemplateChanged}
          onCancel={() => setOpenCustomWaiver(false)}
          email={!emailsList.length ? selectedClient.clientEmail : emailsList}
          hideSaveButton={true}
          allContractsFetched={allContractsFetched}
          isCreateContribution={false}
          isCoachorPartner={true}
        />
      )}
      {confirmationPopup && (
        <Modal
          isOpen={confirmationPopup}
          onCancel={() => {
            setConfirmationPopup(false);
          }}
          isBackButton={true}
          onBack={() => {
            setConfirmationPopup(false);
            setTimeout(() => {
              closeModal();
            }, 0);
          }}
          onSubmit={() => {
            onSubmitWaiverConfirmation();
          }}
          title="Confirmation"
          submitTitle="Yes"
          cancelTitle="No"
        >
          Do you really want to make changes to {tempData.formName}?
        </Modal>
      )}
      {showShareAlertModal && (
        <Modal
          isOpen={showShareAlertModal}
          onCancel={() => {
            setShowShareAlertModal(false);
          }}
          onSubmit={() => {
            onSubmitSentInviteForm({ link: true });
          }}
          isCreateContribution={isMasterCalendar}
          brandingColor={isMasterCalendar ? false : true}
          title="Confirmation"
          submitTitle="Yes"
          cancelTitle="No"
          reduceCancelButton={true}
          zIndex="1300"
        >
          <div style={{ color: isMasterCalendar ? 'black' : isDarkThemeEnabled ? 'white' : 'black' }}>
            Are you sure you want to book the selected slot?
          </div>
        </Modal>
      )}
      {errorState && <CommonErrorMessage message={'Session time not available'} align="left" color="red" />}
    </>
  );
}

export default InvitationForm;
