import React from 'react';

const LockedServiceIcon = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="42" height="42" rx="21" fill="#E1BB62" />
      <path
        d="M27.07 19H26V16C26 16 26 11 21 11C16 11 16 16 16 16V19H14.93C14.4181 19 13.9272 19.2033 13.5653 19.5653C13.2033 19.9272 13 20.4181 13 20.93V29.08C13.0026 29.5901 13.2071 30.0785 13.5688 30.4383C13.9305 30.798 14.4199 31 14.93 31H27.07C27.5819 31 28.0728 30.7967 28.4347 30.4347C28.7967 30.0728 29 29.5819 29 29.07V20.93C29 20.4181 28.7967 19.9272 28.4347 19.5653C28.0728 19.2033 27.5819 19 27.07 19ZM21 27C20.6044 27 20.2178 26.8827 19.8889 26.6629C19.56 26.4432 19.3036 26.1308 19.1522 25.7654C19.0009 25.3999 18.9613 24.9978 19.0384 24.6098C19.1156 24.2219 19.3061 23.8655 19.5858 23.5858C19.8655 23.3061 20.2219 23.1156 20.6098 23.0384C20.9978 22.9613 21.3999 23.0009 21.7654 23.1522C22.1308 23.3036 22.4432 23.56 22.6629 23.8889C22.8827 24.2178 23 24.6044 23 25C23 25.5304 22.7893 26.0391 22.4142 26.4142C22.0391 26.7893 21.5304 27 21 27ZM24 19H18V16.5C18 15 18 13 21 13C24 13 24 15 24 16.5V19Z"
        fill="white"
      />
    </svg>
  );
};

export default LockedServiceIcon;
