import React from 'react';

const CalenderIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1388_11673)">
        <path
          d="M13.333 2.56087H12.6663V1.22754H11.333V2.56087H4.66634V1.22754H3.33301V2.56087H2.66634C1.93301 2.56087 1.33301 3.16087 1.33301 3.89421V14.5609C1.33301 15.2942 1.93301 15.8942 2.66634 15.8942H13.333C14.0663 15.8942 14.6663 15.2942 14.6663 14.5609V3.89421C14.6663 3.16087 14.0663 2.56087 13.333 2.56087ZM13.333 14.5609H2.66634V5.89421H13.333V14.5609Z"
          fill="#4A4A4A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1388_11673">
          <rect width="16" height="16" fill="white" transform="translate(0 0.560547)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalenderIcon;
