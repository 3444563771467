import ExtendedDummyImg from '../../../../assets/ExtendedDummyImg.png';
import coachingDummy from '../../../../assets/coaching.png';
import healingDummy from '../../../../assets/healing.png';
import consultingDummy from '../../../../assets/consulting.png';
import spiritualDummy from '../../../../assets/spiritual.png';

export const extendedCategories = [
  {
    id: 1,
    imageSrc: ExtendedDummyImg,
    title: 'Self Development',
    categoryName: 'Coaching',
  },
  {
    id: 2,
    imageSrc: ExtendedDummyImg,
    title: 'Self Development',
    categoryName: 'Coaching',
  },
  {
    id: 3,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Coaching',
  },
  {
    id: 4,
    imageSrc: ExtendedDummyImg,
    title: 'Wellness Coaching',
    categoryName: 'Coaching',
  },
  {
    id: 5,
    imageSrc: ExtendedDummyImg,
    title: 'Professional Coaching',
    categoryName: 'Coaching',
  },
  {
    id: 6,
    imageSrc: ExtendedDummyImg,
    title: 'Educator',
    categoryName: 'Coaching',
  },
  {
    id: 7,
    imageSrc: ExtendedDummyImg,
    title: 'Educator',
    categoryName: 'Coaching',
  },
  {
    id: 8,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Coaching',
  },
  {
    id: 9,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Coaching',
  },
  {
    id: 10,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Healing',
  },
  {
    id: 11,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Healing',
  },
  {
    id: 12,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Healing',
  },
  {
    id: 13,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Healing',
  },
  {
    id: 14,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Healing',
  },
  {
    id: 15,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Healing',
  },
  {
    id: 16,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Healing',
  },
  {
    id: 17,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Healing',
  },
  {
    id: 18,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Healing',
  },
  {
    id: 19,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Consulting',
  },
  {
    id: 20,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Consulting',
  },
  {
    id: 21,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Consulting',
  },
  {
    id: 22,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Consulting',
  },
  {
    id: 23,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Consulting',
  },
  {
    id: 24,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Consulting',
  },
  {
    id: 25,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Consulting',
  },
  {
    id: 26,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Consulting',
  },
  {
    id: 27,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Consulting',
  },
  {
    id: 28,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Spiritual',
  },
  {
    id: 29,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Spiritual',
  },
  {
    id: 30,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Spiritual',
  },
  {
    id: 31,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Spiritual',
  },
  {
    id: 32,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Spiritual',
  },
  {
    id: 33,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Spiritual',
  },
  {
    id: 34,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Spiritual',
  },
  {
    id: 35,
    imageSrc: ExtendedDummyImg,
    title: 'Emotional Wellbeing',
    categoryName: 'Spiritual',
  },
];
