import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field, FieldArray, Form, Formik } from 'formik';
import Modal from 'components/UI/Modal';
import Input from 'components/FormUI/Input';
import Select from 'components/FormUI/Select';
import { formatMoney } from 'utils/datesAndMoney';
import { colors, toRem } from 'utils/styles';
import { useAccount, useShallowEqualSelector } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import './LiveCourses.scss';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { ContributionStatus } from 'helpers/constants';
import useRouter from 'hooks/useRouter';
import { FORM_FIELDS } from 'pages/CreateContribution/Payment/components/Membership/constants';
import usePaidTier from 'hooks/usePaidTier';
import { PAYMENT_OPTIONS, TOOLTIP } from '../../../../constants';
import { getCurrency } from 'utils/getPaymentOptionsView';
import { isNil, isUndefined, toUpper } from 'lodash';
import CustomExpansionPanel from 'pages/CreateContribution/components/ExpansionPanel';
import {
  FormControlLabelStyled,
  StyledHeader,
  StyledSummaryBodyContainer,
  StyledText,
  StyledTooltip,
  StyledTooltipWrap,
  InputStyled,
  SelectWrapStyled,
  SelectStyled,
  BlueCheckboxForAccordianSummaryStyled,
  SummaryPricingTextContainer,
  FormControlFullWidthStyled,
} from './Membership/styledComponents';
import { useMediaQuery, useTheme } from '@material-ui/core';
import * as paidTier from 'selectors/paidTier';
import { PAID_TIER_TITLES } from '../../../../constants';
import { setCohereAcademyStatus } from 'actions/update-user';
const BlueCheckbox = withStyles({
  root: {
    padding: '9px',
    '&$checked': {
      color: colors.darkOceanBlue,
    },
  },
  checked: {},
})(Checkbox);

const MAX_SPLIT_NUMBERS = 12;

function renderSplitCost(values) {
  return formatMoney(values.cost / values.splitNumbers);
}

function splitNumbersOptions() {
  return Array.from({ length: MAX_SPLIT_NUMBERS - 1 }, (_, index) => {
    const value = index + 2;
    return { title: `${value} Payments`, value };
  });
}

const WorkShop = ({ contribution, shouldBeDisabled, saveToStorageHandler, children }) => {
  const { query } = useRouter();
  const userDetail = useSelector(state => state.account.user);
  const isFeeDisabled = !!query.id && contribution?.status !== ContributionStatus.unfinished;
  const isPurchasedWithSplitPayment = false;
  const { user } = useAccount();
  const dispatch = useDispatch();
  const serviceAgreementType = user?.serviceAgreementType;
  const isBetaUser = user?.isBetaUser;
  const showOnlyFreeOption = serviceAgreementType && isBetaUser ? true : false;
  const [supportPopup, setSupportPopup] = useState(false);
  const [invoicePopup, setInvoicePopup] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const smView = useMediaQuery(theme.breakpoints.up('sm'));
  const isEditing = !!query.id;
  let freeCheck = false;
  const {
    user: { transfersEnabled, isStandardAccount, defaultPaymentMethod, standardAccountTransfersEnabled, avatarUrl },
  } = useAccount();
  const options = [
    { value: 'Exclusive', title: 'Exclusive', disabled: !user.isStandardTaxEnabled ? true : false },
    { value: 'Inclusive', title: 'Inclusive', disabled: !user.isStandardTaxEnabled ? true : false },
    { value: 'No', title: 'No Tax' },
  ];
  let formRef = useRef();
  useEffect(() => {
    formRef.current.setFieldValue('coachPaysStripeFee', contribution?.paymentInfo?.coachPaysStripeFee);
    if (formRef?.current?.values?.paymentType === 'Simple' && contribution?.taxType === 'No') {
      formRef.current.setFieldValue('taxType', 'Exclusive');
    } else if (formRef?.current?.values?.paymentType === 'Advance' && contribution?.taxType === 'No') {
      formRef.current.setFieldValue('taxType', 'No');
    }
  }, [formRef]);

  const formatTotalPrice = ({ cost, packageSessionDiscountPercentage }) =>
    formatMoney((cost * (100 - packageSessionDiscountPercentage)) / 100);

  const handleFormKeyDown = e => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };
  // const { isLaunchPlan } = usePaidTier();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  const handleChangeCurrency = ({ target: { value } }, formProps) => {
    const symbol = contribution.availableCurrencies?.find(c => c.code === value).symbol;
    formProps.setFieldValue('defaultCurrency', value);
    formProps.setFieldValue('defaultSymbol', symbol);
  };
  const handlePaymentProcessor = ({ target: { value } }, formProps) => {
    if (value === 'Advance') {
      if (!user.isStandardTaxEnabled) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
      setSupportPopup(true);
      formProps.setFieldValue('taxType', 'No');
      if (standardAccountTransfersEnabled === false) {
        formProps.setFieldValue('multiplePriceList', []);
        formProps.setFieldValue('cost', null);
        formProps.setFieldValue('splittedCost', null);
        formProps.setFieldValue('paymentOptions', ['Free']);
      }
    }
    if (value === 'Simple') {
      setIsDisabled(false);
      formProps.setFieldValue('taxType', 'Inclusive');
    }
    formProps.setFieldValue('paymentType', value);
  };
  const handleTaxType = ({ target: { value } }, formProps) => {
    formProps.setFieldValue('taxType', value);
  };
  const handleChangeInvoice = ({ target: { value } }, formProps) => {
    formProps.setFieldValue('isInvoiced', !formProps.values.isInvoiced);
    if (formProps.values.isInvoiced === false) {
      freeCheck = true;
      setInvoicePopup(true);
    }
  };
  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={{
        cost: contribution?.paymentInfo?.cost || null,
        splittedCost: contribution?.paymentInfo?.splittedCost || null,
        paymentOptions:
          contribution?.paymentInfo?.paymentOptions &&
          !contribution?.paymentInfo?.paymentOptions.some(
            option => option === 'SessionsPackage' || option === 'PerSession',
          )
            ? contribution?.paymentInfo?.paymentOptions
            : ['EntireCourse'],
        multiplePriceList:
          contribution?.paymentInfo?.multiplePriceList?.length === 0
            ? [
                {
                  cost: contribution?.paymentInfo?.cost || 0,
                  splittedCost: renderSplitCost({
                    splitNumbers: contribution?.paymentInfo?.splitNumbers,
                    cost: contribution?.paymentInfo?.cost,
                  }),
                },
              ]
            : contribution?.paymentInfo?.multiplePriceList || [{ cost: 0, splittedCost: 0 }],
        splitNumbers: contribution?.paymentInfo?.splitNumbers || 3,
        splitPeriod: contribution?.paymentInfo?.splitPeriod || 'Monthly',
        defaultSymbol: contribution?.defaultSymbol,
        defaultCurrency: contribution?.defaultCurrency,
        paymentType: contribution?.paymentType || user?.defaultPaymentMethod,
        taxType: contribution?.taxType || 'No',
        packageDiscount: !!contribution?.paymentInfo?.packageSessionDiscountPercentage,
        packageSessionDiscountPercentage: contribution?.paymentInfo?.packageSessionDiscountPercentage
          ? contribution?.paymentInfo?.packageSessionDiscountPercentage
          : '',
        coachPaysStripeFee: contribution?.paymentInfo?.coachPaysStripeFee,
        isInvoiced: isNil(contribution?.isInvoiced) ? false : contribution?.isInvoiced,
      }}
      validationSchema={yup.object().shape({
        cost: yup.number().when('paymentOptions', {
          is: values => values.includes('SplitPayments') || values.includes('EntireCourse'),
          then: yup
            .number()
            .transform((originalValue, originalObject) => {
              const parsedValue = parseFloat(originalValue);
              return isNaN(parsedValue) ? undefined : parsedValue;
            })
            .min(1, 'Cost must be at least 1')
            .integer('Please only enter numbers')
            .required('This is a required field'),
          otherwise: yup.number().nullable(),
        }),
        splitNumbers: yup
          .number()
          .min(2)
          .max(MAX_SPLIT_NUMBERS)
          .integer('please only enter numbers')
          .required('This is a required field'),
        paymentOptions: yup
          .array()
          .of(yup.mixed().oneOf(['Free', 'EntireCourse', 'SplitPayments']))
          .min(1),
        coachPaysStripeFee: yup.bool(),
        isInvoiced: yup.bool(),
      })}
      onSubmit={saveToStorageHandler}
    >
      {formProps => {
        const renderCardBody = (
          <Form id="create-contribution-form" onKeyDown={handleFormKeyDown}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={8} style={smView ? {} : { paddingBottom: '0.5rem' }}>
                <FieldArray name="paymentOptions">
                  {({ push, remove, form }) => {
                    const changeHandler = ({ target: { value, checked } }) => {
                      if (checked) {
                        push(value);
                        if (value === 'SplitPayments') {
                          form.setFieldValue(`splittedCost`, renderSplitCost(formProps.values, formProps.values.cost));
                        }
                      } else {
                        const idx = form.values.paymentOptions.indexOf(value);
                        if (value === 'EntireCourse') {
                          form.setFieldValue('packageDiscount', false);
                          form.setFieldValue('packageSessionDiscountPercentage', '');
                          if (!formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS)) {
                            form.setFieldValue('cost', '');
                            form.setFieldValue('splittedCost', '');
                          }
                        }
                        if (
                          value === PAYMENT_OPTIONS.SPLIT_PAYMENTS &&
                          !formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE)
                        ) {
                          form.setFieldValue('cost', '');
                        }
                        remove(idx);
                      }
                    };

                    // if (showOnlyFreeOption) {
                    //   form.values.paymentOptions = [PAYMENT_OPTIONS.ENTIRE_COURSE];
                    // }
                    const hasNoSelectionError =
                      !isUndefined(formProps.errors.paymentOptions) &&
                      typeof formProps.errors.paymentOptions === 'string' &&
                      form.values.paymentOptions?.length <= 0;
                    return (
                      <Grid container>
                        <FormControlFullWidthStyled error={hasNoSelectionError} fullWidth>
                          <Grid item sm={12}>
                            {hasNoSelectionError && (
                              <FormLabel className="mb-3" component="legend">
                                Pick at least one option
                              </FormLabel>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <CustomExpansionPanel
                              bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                              headerPadding={mobileView ? '0px 2px !important' : '0px 15px !important'}
                              minHeight="30px"
                              summaryTitle={
                                <StyledTooltipWrap>
                                  <FormControlLabelStyled
                                    name={FORM_FIELDS.PAYMENT_OPTIONS}
                                    control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                    label="Free"
                                    disabled={
                                      ((isLaunchPlan || (shouldBeDisabled && contribution?.status === 'Approved')) &&
                                        !showOnlyFreeOption) ||
                                      formProps.values.isInvoiced
                                    }
                                    value={PAYMENT_OPTIONS.FREE}
                                    onChange={changeHandler}
                                    checked={form.values.paymentOptions.includes(PAYMENT_OPTIONS.FREE)}
                                    style={{
                                      fontSize: '18px',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      lineHeight: '30px',
                                      letterSpacing: '0.1px',
                                      margin: '0 1rem 0 0',
                                    }}
                                  />
                                  <StyledTooltip
                                    title="If you select this option, you will have a free link where clients can join session for free."
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                  >
                                    <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                  </StyledTooltip>
                                </StyledTooltipWrap>
                              }
                              // mobileView={mobileView}
                              // expand={false}
                              disabledExpansion
                            />
                          </Grid>

                          <Grid item sm={12}>
                            <CustomExpansionPanel
                              bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                              headerPadding={mobileView ? '0px 2px !important' : '0px 15px !important'}
                              minHeight="30px"
                              // asyncContainerPadding={mobileView}
                              summaryTitle={
                                <StyledTooltipWrap>
                                  <FormControlLabelStyled
                                    name={FORM_FIELDS.PAYMENT_OPTIONS}
                                    control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                    label="Full Payment"
                                    value="EntireCourse"
                                    onChange={changeHandler}
                                    disabled={
                                      isPurchasedWithSplitPayment ||
                                      (shouldBeDisabled && contribution?.status === 'Approved') ||
                                      (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                      (formProps.values.paymentType === 'Advance' &&
                                        standardAccountTransfersEnabled === false)
                                    }
                                    checked={form.values.paymentOptions.includes('EntireCourse')}
                                    style={{
                                      marginLeft: 0,
                                      marginBottom: 0,
                                    }}
                                  />
                                  <StyledTooltip
                                    title="Pay in full for all sessions"
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                  >
                                    <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                  </StyledTooltip>
                                </StyledTooltipWrap>
                              }
                              summarySubTitle={
                                <div style={{ paddingLeft: 9 }}>
                                  {form.values.paymentOptions.includes('EntireCourse') &&
                                    `${
                                      form?.values?.multiplePriceList?.length > 1 ? 'Multiple Prices:' : 'Price:'
                                    } ${form?.values?.multiplePriceList
                                      ?.map(k => `${form.values.defaultSymbol || '$'}${formatMoney(k.cost)}`)
                                      ?.join(', ')}`}
                                </div>
                              }
                              // mobileView={mobileView}
                              expand={form.values.paymentOptions.includes('EntireCourse')}
                              disabledExpansion={!form.values.paymentOptions.includes('EntireCourse')}
                            >
                              <Grid container>
                                <Grid item sm={6} xs={12} direction="column">
                                  {(formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) ||
                                    formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS)) && (
                                    <>
                                      <Grid item xs={12}>
                                        <StyledHeader id="lbl-total-price-input">Total Price</StyledHeader>
                                      </Grid>
                                      <Grid item sm={9} xs={12}>
                                        <InputStyled
                                          startAdornment={
                                            formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'
                                          }
                                          labelId="lbl-total-price-input"
                                          variant="outlined"
                                          margin="dense"
                                          type="text"
                                          name="cost"
                                          fullWidth
                                          onChange={({ target: { value } }) => {
                                            if (!/\D/.test(value)) {
                                              formProps.setFieldValue('cost', value);
                                              formProps.setFieldValue(
                                                `splittedCost`,
                                                renderSplitCost({
                                                  splitNumbers: formRef.current.values.splitNumbers,
                                                  cost: value,
                                                }),
                                              );
                                              formProps.setFieldValue(`multiplePriceList`, [
                                                {
                                                  cost: value,
                                                  splittedCost: renderSplitCost({
                                                    splitNumbers: formRef.current.values.splitNumbers,
                                                    cost: value,
                                                  }),
                                                },
                                              ]);
                                            }
                                          }}
                                          label=""
                                          disabled={
                                            isPurchasedWithSplitPayment ||
                                            (shouldBeDisabled && contribution?.status === 'Approved')
                                          }
                                        />
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                                {formProps.values.paymentOptions.includes('EntireCourse') && (
                                  <>
                                    <Grid item xs={12}>
                                      <Field name="packageDiscount">
                                        {() => {
                                          const changePackageDiscount = ({ target: { checked } }) => {
                                            if (checked) {
                                              form.setFieldValue('packageSessionDiscountPercentage', 10);
                                            } else {
                                              form.setFieldValue('packageSessionDiscountPercentage', '');
                                            }
                                            form.setFieldValue('packageDiscount', !form.values.packageDiscount);
                                          };

                                          return (
                                            <>
                                              <StyledTooltipWrap style={{ marginTop: '1.25rem' }}>
                                                <FormControl>
                                                  <FormControlLabelStyled
                                                    name="packageDiscount"
                                                    control={<BlueCheckbox color="primary" />}
                                                    label="Full Payment Discount"
                                                    onChange={changePackageDiscount}
                                                    checked={form.values.packageDiscount}
                                                    disabled={
                                                      isPurchasedWithSplitPayment ||
                                                      (shouldBeDisabled && contribution?.status === 'Approved') ||
                                                      (formProps.values.paymentType === 'Simple' &&
                                                        transfersEnabled === false) ||
                                                      (formProps.values.paymentType === 'Advance' &&
                                                        standardAccountTransfersEnabled === false)
                                                    }
                                                  />
                                                </FormControl>
                                                <StyledTooltip
                                                  title="An incentive to have clients pay in full"
                                                  arrow
                                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                                >
                                                  <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                                </StyledTooltip>
                                              </StyledTooltipWrap>
                                            </>
                                          );
                                        }}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                      {formProps.values.packageDiscount ? (
                                        <InputStyled
                                          startAdornment="%"
                                          variant="outlined"
                                          margin="dense"
                                          type="text"
                                          name="packageSessionDiscountPercentage"
                                          label=""
                                          onChange={({ target: { value } }) => {
                                            if (!/\D/.test(value))
                                              if (value < 100) {
                                                formProps.setFieldValue('packageSessionDiscountPercentage', value);
                                              }
                                          }}
                                          disabled={
                                            isPurchasedWithSplitPayment ||
                                            (shouldBeDisabled && contribution?.status === 'Approved') ||
                                            (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                            (formProps.values.paymentType === 'Advance' &&
                                              standardAccountTransfersEnabled === false)
                                          }
                                        />
                                      ) : null}
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </CustomExpansionPanel>
                          </Grid>

                          <Grid item sm={12}>
                            <CustomExpansionPanel
                              bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                              headerPadding={mobileView ? '0px 2px !important' : '0px 15px !important'}
                              minHeight="30px"
                              // asyncContainerPadding={mobileView}
                              summaryTitle={
                                <StyledTooltipWrap>
                                  <FormControlLabelStyled
                                    name="paymentOptions"
                                    control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                    label="Split Payments"
                                    value="SplitPayments"
                                    onChange={changeHandler}
                                    disabled={
                                      (shouldBeDisabled && contribution?.status === 'Approved') ||
                                      (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                      (formProps.values.paymentType === 'Advance' &&
                                        standardAccountTransfersEnabled === false)
                                    }
                                    checked={form.values.paymentOptions.includes('SplitPayments')}
                                    style={{
                                      marginLeft: 0,
                                      marginBottom: 0,
                                    }}
                                  />
                                  <StyledTooltip
                                    title="For automatic recurring payments that allow your clients to pay for the program, overtime (monthly, weekly or daily)"
                                    arrow
                                    className="mb-2"
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                  >
                                    <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                  </StyledTooltip>
                                </StyledTooltipWrap>
                              }
                              summarySubTitle={
                                <div style={{ paddingLeft: 9 }}>
                                  {form.values.paymentOptions.includes('SplitPayments') &&
                                    `${formProps.values?.splitNumbers} ${formProps.values?.splitPeriod} payments of ${
                                      // formatMoney(formProps?.values?.multiplePriceList[0]?.cost || 0.0)
                                      form?.values?.multiplePriceList
                                        ?.map(k => `${form.values.defaultSymbol || '$'}${formatMoney(k.cost)}`)
                                        ?.join(', ')
                                    }`}
                                </div>
                              }
                              // mobileView={mobileView}
                              expand={form.values.paymentOptions.includes('SplitPayments')}
                              disabledExpansion={!form.values.paymentOptions.includes('SplitPayments')}
                            >
                              <Grid container spacing={2} direction="row">
                                <Grid item sm={6} xs={12}>
                                  <Grid item sm={8} xs={12}>
                                    <Grid item sm={8} xs={12}>
                                      <StyledHeader id="lbl-number-of-payments">Number of Payments</StyledHeader>
                                    </Grid>
                                    <SelectWrapStyled>
                                      <SelectStyled
                                        style={{
                                          fontFamily: 'Avenir',
                                          border: '1px solid #DFE3E4',
                                          borderRadius: '4px',
                                          fontSize: '1rem',
                                          fontWeight: '400',
                                          lineHeight: '24px',
                                          letterSpacing: '0.15px',
                                        }}
                                        labelId="lbl-number-of-payments"
                                        variant="outlined"
                                        label=""
                                        name="splitNumbers"
                                        fullWidth
                                        disabled={
                                          !formProps.values.paymentOptions.includes('SplitPayments') ||
                                          isPurchasedWithSplitPayment ||
                                          (shouldBeDisabled && contribution?.status === 'Approved') ||
                                          (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                          (formProps.values.paymentType === 'Advance' &&
                                            standardAccountTransfersEnabled === false)
                                        }
                                        onChange={e => {
                                          formProps.setFieldValue('splitNumbers', e.target.value);
                                          formProps.setFieldValue(
                                            'splittedCost',
                                            renderSplitCost({
                                              splitNumbers: e.target.value,
                                              cost: formProps.values.cost,
                                            }),
                                          );
                                          if (e.target.value) {
                                            const updatedSpltPayemts = formProps.values.multiplePriceList.map(item => {
                                              return {
                                                ...item,
                                                splittedCost: renderSplitCost({
                                                  splitNumbers: e.target.value,
                                                  cost: item.cost,
                                                }),
                                              };
                                            });
                                            formProps.setFieldValue(`multiplePriceList`, updatedSpltPayemts);
                                          }
                                        }}
                                        items={splitNumbersOptions()}
                                      />
                                    </SelectWrapStyled>
                                  </Grid>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Grid item sm={8} xs={12}>
                                    <StyledHeader id="lbl-split-period">Split Period</StyledHeader>
                                    <SelectWrapStyled>
                                      <SelectStyled
                                        style={{
                                          fontFamily: 'Avenir',
                                          border: '1px solid #DFE3E4',
                                          borderRadius: '4px',
                                          fontSize: '1rem',
                                          fontWeight: '400',
                                          lineHeight: '24px',
                                          letterSpacing: '0.15px',
                                        }}
                                        labelId="lbl-split-period"
                                        label=""
                                        name="splitPeriod"
                                        fullWidth
                                        variant="outlined"
                                        disabled={
                                          !formProps.values.paymentOptions.includes('SplitPayments') ||
                                          isPurchasedWithSplitPayment ||
                                          (shouldBeDisabled && contribution?.status === 'Approved') ||
                                          (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                          (formProps.values.paymentType === 'Advance' &&
                                            standardAccountTransfersEnabled === false)
                                        }
                                        items={[
                                          { title: 'Monthly', value: 'Monthly' },
                                          { title: 'Weekly', value: 'Weekly' },
                                          { title: 'Daily', value: 'Daily' },
                                        ]}
                                      />
                                    </SelectWrapStyled>
                                  </Grid>
                                </Grid>
                                {/* <Grid item sm={6} xs={12}>
                                  <div />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Grid item sm={8} xs={12}>
                                    <StyledHeader id="lbl-total-price-calculated">Total Price</StyledHeader>
                                    <FormLabel labelId="lbl-total-price-calculated" style={{ marginTop: '1rem' }}>
                                      {formProps.values.defaultSymbol}
                                      {(formProps?.values?.multiplePriceList?.length > 0
                                        ? formProps?.values?.multiplePriceList[0]?.cost
                                        : 0) * formProps.values?.splitNumbers}
                                    </FormLabel>
                                  </Grid>
                                </Grid> */}
                              </Grid>
                            </CustomExpansionPanel>
                          </Grid>
                          <Grid item xs={12}>
                            <CustomExpansionPanel
                              bodyPadding="20px 24px !important"
                              // asyncContainerPadding={mobileView}
                              summaryTitle={
                                <div
                                  style={{
                                    fontSize: '1.125rem',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '30px',
                                    letterSpacing: '0.1px',
                                    color: 'black',
                                    marginLeft: '-1px',
                                  }}
                                >
                                  Payment Settings
                                </div>
                              }
                              summarySubTitle={
                                formProps.values.paymentOptions?.length &&
                                (formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) ||
                                  formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS)) ? (
                                  <div>
                                    {[
                                      toUpper(formProps.values?.defaultCurrency),
                                      (formProps.values?.paymentType === 'Simple' && 'Cohere Payment Processor') ||
                                        (formProps.values?.paymentType === 'Advance' &&
                                          `${
                                            userDetail.firstName.charAt(0).toUpperCase() + userDetail.firstName.slice(1)
                                          }'s Stripe Account`),
                                      user?.isStandardAccount === true &&
                                        formProps?.values?.paymentType === 'Advance' &&
                                        (formProps.values?.taxType === 'No' ? 'No Tax' : formProps.values?.taxType),
                                      formProps.values?.coachPaysStripeFee ? 'Coach Pays Stripe Processing Fees' : '',
                                    ]
                                      .filter(k => !!k)
                                      .join(', ')}
                                  </div>
                                ) : (
                                  <></>
                                )
                              }
                              // mobileView={mobileView}
                              // onToggle={setIsSettingsAccordianOpen}
                              // expand={isSettingsAccordianOpen}
                              // disabledExpansion={!isSettingsAccordianOpen}
                            >
                              <Grid container spacing={2}>
                                {(showOnlyFreeOption || user?.isStandardAccount) &&
                                  (formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) ||
                                    formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS)) && (
                                    <>
                                      <Grid item sm={9} xs={12} direction="column">
                                        <Grid item sm={9} xs={12}>
                                          <StyledHeader id="lbl-currency">Currency</StyledHeader>
                                        </Grid>
                                        <SelectWrapStyled>
                                          <SelectStyled
                                            style={{
                                              fontFamily: 'Avenir',
                                              border: '1px solid #DFE3E4',
                                              borderRadius: '4px',
                                              fontSize: '1rem',
                                              fontWeight: '400',
                                              lineHeight: '24px',
                                              letterSpacing: '0.15px',
                                            }}
                                            labelId="lbl-currency"
                                            // label=""
                                            variant="outlined"
                                            name="defaultCurrency"
                                            fullWidth
                                            disabled={isFeeDisabled}
                                            onChange={event => handleChangeCurrency(event, formProps)}
                                            value={formProps.values?.defaultCurrency}
                                            items={getCurrency(contribution)}
                                          />
                                        </SelectWrapStyled>
                                      </Grid>
                                      {/* {!showOnlyFreeOption && (
                                        <Grid item container xs={12}>
                                          <StyledText>{contribution.defaultCurrency?.toUpperCase()}</StyledText>
                                        </Grid>
                                      )} */}
                                    </>
                                  )}

                                {(formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) ||
                                  formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS)) && (
                                  <>
                                    {user?.isStandardAccount && (
                                      <>
                                        <Grid item sm={9} xs={12} direction="column">
                                          <Grid item sm={9} xs={12}>
                                            <StyledHeader id="lbl-payment-processor">Payment Processor</StyledHeader>
                                          </Grid>
                                          <SelectWrapStyled>
                                            <SelectStyled
                                              style={{
                                                fontFamily: 'Avenir',
                                                border: '1px solid #DFE3E4',
                                                borderRadius: '4px',
                                                fontSize: '1rem',
                                                fontWeight: '400',
                                                lineHeight: '24px',
                                                letterSpacing: '0.15px',
                                              }}
                                              labelId="lbl-payment-processor"
                                              label=""
                                              variant="outlined"
                                              name="paymentType"
                                              fullWidth
                                              disabled={isFeeDisabled}
                                              onChange={event => handlePaymentProcessor(event, formProps)}
                                              value={formProps.values?.paymentType}
                                              items={
                                                user.connectedStripeAccountId === null
                                                  ? [
                                                      {
                                                        value: 'Advance',
                                                        title: `${
                                                          userDetail.firstName.charAt(0).toUpperCase() +
                                                          userDetail.firstName.slice(1)
                                                        }'s Stripe Account`,
                                                      },
                                                    ]
                                                  : [
                                                      { value: 'Simple', title: 'Cohere Payment Processor' },
                                                      {
                                                        value: 'Advance',
                                                        title: `${
                                                          userDetail.firstName.charAt(0).toUpperCase() +
                                                          userDetail.firstName.slice(1)
                                                        }'s Stripe Account`,
                                                      },
                                                    ]
                                              }
                                            />
                                          </SelectWrapStyled>
                                        </Grid>
                                      </>
                                    )}
                                    {/* {user?.isStandardAccount && formProps.values?.paymentType === 'Advance' && ( */}
                                    {
                                      <>
                                        <Grid item sm={9} xs={12} direction="column">
                                          <Grid item sm={9} xs={12}>
                                            <StyledHeader id="lbl-tax-type">Tax Type</StyledHeader>
                                          </Grid>
                                          <SelectWrapStyled>
                                            <SelectStyled
                                              style={{
                                                fontFamily: 'Avenir',
                                                border: '1px solid #DFE3E4',
                                                borderRadius: '4px',
                                                fontSize: '1rem',
                                                fontWeight: '400',
                                                lineHeight: '24px',
                                                letterSpacing: '0.15px',
                                              }}
                                              labelId="lbl-tax-type"
                                              label=""
                                              variant="outlined"
                                              name="taxType"
                                              fullWidth
                                              disabled={isFeeDisabled}
                                              defaultValue={formProps.values?.taxType}
                                              isOptionDisabled={option => option.isdisabled}
                                              onChange={event => handleTaxType(event, formProps)}
                                              value={formProps.values?.taxType}
                                              items={
                                                formProps.values?.paymentType === 'Simple'
                                                  ? [
                                                      {
                                                        value: 'Exclusive',
                                                        title: 'Exclusive',
                                                        disabled: isDisabled,
                                                      },
                                                      {
                                                        value: 'Inclusive',
                                                        title: 'Inclusive',
                                                        disabled: isDisabled,
                                                      },
                                                    ]
                                                  : [
                                                      {
                                                        value: 'Exclusive',
                                                        title: 'Exclusive',
                                                        disabled: isDisabled,
                                                      },
                                                      {
                                                        value: 'Inclusive',
                                                        title: 'Inclusive',
                                                        disabled: isDisabled,
                                                      },
                                                      { value: 'No', title: 'No Tax' },
                                                    ]
                                              }
                                            />
                                          </SelectWrapStyled>
                                        </Grid>
                                      </>
                                    }
                                    {/* )} */}
                                  </>
                                )}

                                <Grid item sm={9} xs={12} direction="row">
                                  <StyledTooltipWrap>
                                    <FormControlLabelStyled
                                      name="coachPaysStripeFee"
                                      control={<BlueCheckbox color="primary" />}
                                      fontSize="16px"
                                      fontWeight="400"
                                      label="Coach Pays for Processing Fee"
                                      checked={
                                        formRef?.current?.values?.paymentOptions?.includes(PAYMENT_OPTIONS.FREE) &&
                                        formRef?.current?.values?.paymentOptions?.length === 1
                                          ? false
                                          : formProps.values.coachPaysStripeFee
                                      }
                                      onChange={formProps.handleChange('coachPaysStripeFee')}
                                      disabled={
                                        isFeeDisabled ||
                                        (formRef?.current?.values?.paymentOptions?.includes(PAYMENT_OPTIONS.FREE) &&
                                          formRef?.current?.values?.paymentOptions?.length === 1) ||
                                        (formProps.values.paymentType === 'Simple' && transfersEnabled === false)
                                      }
                                    />
                                    <StyledTooltip
                                      title="If the box is checked, you are choosing to pay the Stripe credit card processing fee. If left unchecked, your client will be charged the processing fee at checkout. The Stripe processing fee is a 2.9% plus 30 cents surcharge on top of your base pricing."
                                      arrow
                                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                      className="mb-2"
                                    >
                                      <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                    </StyledTooltip>
                                  </StyledTooltipWrap>
                                </Grid>

                                {contribution?.isWorkshop == false &&
                                  user?.isStandardAccount &&
                                  formProps.values?.paymentType === 'Advance' && (
                                    <>
                                      <Grid item sm={9} xs={12} direction="row">
                                        <StyledTooltipWrap>
                                          <FormControlLabelStyled
                                            name="isInvoiced"
                                            fontWeight="400"
                                            control={<BlueCheckbox color="primary" />}
                                            label="Payment Invoice"
                                            checked={formProps.values?.isInvoiced}
                                            value={formProps.values?.isInvoiced}
                                            onChange={event => handleChangeInvoice(event, formProps)}
                                            disabled={
                                              isFeeDisabled ||
                                              (formProps.values.paymentType === 'Simple' &&
                                                transfersEnabled === false) ||
                                              (formProps.values.paymentType === 'Advance' &&
                                                standardAccountTransfersEnabled === false)
                                            }
                                          />
                                          <StyledTooltip
                                            title="If your clients pay by invoice, they will be able to join after purchasing and you will be able to collect payment via credit card or bank transfer."
                                            arrow
                                            enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                            className="mb-2"
                                          >
                                            <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                          </StyledTooltip>
                                        </StyledTooltipWrap>
                                        {invoicePopup && (
                                          <>
                                            <Modal
                                              CrossIconHide
                                              isOpen={invoicePopup}
                                              widthRequiredIs
                                              handleOnCancel
                                              onCancel={() => {
                                                setInvoicePopup(false);
                                                formProps.setFieldValue('isInvoiced', false);
                                              }}
                                              onSubmit={() => {
                                                setInvoicePopup(false);
                                                formProps.setFieldValue('isInvoiced', true);
                                              }}
                                              submitTitle={'I understand'}
                                            >
                                              <p>
                                                If your clients pay by invoice, they will be able to join after
                                                purchasing and you will be able to collect payment via credit card or
                                                bank transfer.
                                              </p>
                                            </Modal>
                                          </>
                                        )}
                                      </Grid>
                                    </>
                                  )}
                              </Grid>
                            </CustomExpansionPanel>
                          </Grid>
                        </FormControlFullWidthStyled>
                      </Grid>
                    );
                  }}
                </FieldArray>
              </Grid>
              <Grid item xs={12} sm={4} style={smView ? { paddingLeft: 0 } : { paddingTop: 0 }}>
                <div
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #E7E7E7',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '30px',
                      letterSpacing: '0.1px',
                      minHeight: '50px',
                      padding: '10px 24px',
                      borderBottom: '1px solid #E7E7E7',
                    }}
                  >
                    Payment Summary
                  </div>
                  <StyledSummaryBodyContainer>
                    {contribution?.isWorkshop &&
                      (formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.FREE) ||
                        formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) ||
                        formProps.values.paymentOptions.includes('SplitPayments')) && (
                        <>
                          <StyledText>The following pricing links will be created for this contribution:</StyledText>
                        </>
                      )}
                    {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.FREE) && contribution?.isWorkshop && (
                      <SummaryPricingTextContainer>
                        <StyledHeader className="value-title">Free:</StyledHeader>
                        <StyledText className="value-body">
                          A link will be created for clients to join for free
                        </StyledText>
                      </SummaryPricingTextContainer>
                    )}
                    {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) && (
                      <>
                        {/* <StyledHeader>Full Payment Pricing Options:</StyledHeader> */}
                        {contribution?.isWorkshop ? (
                          <SummaryPricingTextContainer>
                            <StyledHeader className="value-title">Workshop Price:</StyledHeader>
                            <StyledText className="value-body">
                              {formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'}
                              {formatTotalPrice(formProps.values)}
                            </StyledText>
                          </SummaryPricingTextContainer>
                        ) : (
                          <SummaryPricingTextContainer>
                            <StyledHeader className="value-title">Full Payment Pricing Options:</StyledHeader>
                            <StyledText className="value-body">
                              Price per Course: {formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'}
                              {formatTotalPrice(formProps.values)}
                            </StyledText>
                          </SummaryPricingTextContainer>
                        )}
                      </>
                    )}
                    {formProps.values.paymentOptions.includes('SplitPayments') && contribution?.isWorkshop && (
                      <SummaryPricingTextContainer>
                        {/* <StyledHeader>Split Payment Pricing Options:</StyledHeader> */}
                        <StyledHeader className="value-title">Price per Split Payment:</StyledHeader>
                        <StyledText className="value-body">
                          {formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'}
                          {renderSplitCost(formProps.values)}
                        </StyledText>
                      </SummaryPricingTextContainer>
                    )}
                  </StyledSummaryBodyContainer>
                </div>
              </Grid>
            </Grid>

            {supportPopup && (
              <>
                <Modal
                  CrossIconHide
                  isOpen={supportPopup}
                  widthRequiredIs
                  handleOnCancel
                  disableConfirm
                  onCancel={() => {
                    setSupportPopup(false);
                  }}
                >
                  <p>
                    You are creating contribution on standard stripe account. Please make sure automatic tax calculation
                    is enabled from stripe settings otherwise client's won't be able to purchase the contribution.
                  </p>
                </Modal>
              </>
            )}
          </Form>
        );

        return children(formProps, renderCardBody);
      }}
    </Formik>
  );
};

WorkShop.propTypes = {
  contribution: PropTypes.shape(),
  saveToStorageHandler: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

WorkShop.defaultProps = {
  contribution: null,
};

export default WorkShop;
