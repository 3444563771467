import React from 'react';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from 'pages/Collective/Icons/SearchIcon';
import classes from './SearchBar.module.scss';
import CrossIcon from 'pages/Collective/Icons/CrossIcon';
// import { Box, useMediaQuery } from '@mui/material';
// import { theme } from 'styles/theme';

const SearchBar = ({ placeholder = 'Search here', variant = '', onChange = () => {}, onClick }) => {
  // const isMobileViewActive = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div
      className={
        variant === 'outlined'
          ? classes.outlinedContainer
          : variant === 'filled'
          ? classes.filledContainer
          : classes.container
      }
    >
      <IconButton aria-label="search" className={classes.searchIcon}>
        {variant === 'filled' ? <SearchIcon width="19px" height="19px" color="#FFFFFF" /> : <SearchIcon />}
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        className={variant === 'filled' ? classes.filledInput : classes.input}
        placeholder={placeholder}
        inputProps={{
          onChange: onChange,
        }}
      />
      {variant === 'filled' && <CrossIcon width="17px" height="17px" color="#FFFFFF" onClick={onClick} />}
    </div>
  );
};

export default SearchBar;
