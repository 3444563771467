import React from 'react';
import CustomPurchaseBlock from './PurchaseBlock/CustomPurchaseBlock';
import CustomPurchaseBlockOneToOne from './PurchaseBlock/CustomPurchaseBlockOneToOne';
import { ContributionType } from 'helpers/constants';
import PropTypes from 'prop-types';
import useClientPreview from 'hooks/useClientPreview';
import { PAYMENT_OPTIONS } from '../../../../src/constants';
import CustomPurchaseBlockOneToOneClientPreview from './PurchaseBlock/CustomPurchaseBlockOneToOneClientPreview';

const PaymentCard = ({ contribution, easyBooking, type, ...restProps }) => {
  const { clientPreviewMode } = useClientPreview();
  const paymentOptions = contribution?.paymentInfo?.paymentOptions;
  const onlyFreeSingleSession = paymentOptions?.length === 1 && paymentOptions?.includes(PAYMENT_OPTIONS.FREE);
  const onlyFreeSessionPackage =
    paymentOptions?.length === 1 && paymentOptions?.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE);
  const onlyFreeSingleAndFreeSessionPackage =
    paymentOptions?.length === 2 &&
    paymentOptions?.includes(PAYMENT_OPTIONS.FREE) &&
    paymentOptions?.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE);
  const onlyFreeOptionsAllowed = onlyFreeSingleSession || onlyFreeSessionPackage || onlyFreeSingleAndFreeSessionPackage;

  if (type === ContributionType.contributionOneToOne && clientPreviewMode && onlyFreeOptionsAllowed) {
    return <CustomPurchaseBlockOneToOneClientPreview />;
  }

  return type === ContributionType.contributionOneToOne ? (
    contribution?.isPurchased === false ? (
      <CustomPurchaseBlockOneToOne easyBooking={easyBooking} />
    ) : contribution?.isPurchased === true && contribution.purchaseStatus === 'unpurchased' ? (
      <CustomPurchaseBlockOneToOne easyBooking={easyBooking} />
    ) : (
      <></>
    )
  ) : (
    <CustomPurchaseBlock {...restProps} />
  );
};

PaymentCard.propTypes = {
  type: PropTypes.string,
};

PaymentCard.defaultProps = {
  type: null,
};

export default PaymentCard;
