import React, { useEffect, useMemo, useState } from 'react';
import classes from './Header.module.scss';
import CohereLogo from 'pages/Collective/Icons/CohereLogo';
import { Box, useMediaQuery } from '@mui/material';
import Button from 'components/FormUI/Button';
import HamburgerMenu from 'pages/Collective/Icons/HamburgerMenu';
import { theme } from 'styles/theme';
import * as pageActions from 'actions/page';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core';
import Navbar from '../Navbar/Navbar';
import CrossIcon from 'pages/Collective/Icons/CrossIcon';
import SearchIcon from 'pages/Collective/Icons/SearchIcon';
import { ClickAwayListener } from '@material-ui/core';
import ServicesDropdown from './Components/ServicesDropdown/ServicesDropdown';
import LoggedInActions from './Components/LoggedInActions/LoggedInActions';
import LoggedOutActions from './Components/LoggedOutActions/LoggedOutActions';
import AllPageSvgIcon from 'pages/Collective/Icons/AllPageSvgIcon';
import GroupsIcon from 'pages/Collective/Icons/GroupsIcon';
import ChatIcon from 'pages/Collective/Icons/ChatIcon';
import JourneySvgIcon from 'pages/Collective/Icons/JourneySvgIcon';
import BottomNavigation from './Components/BottomNavigation/BottomNavigation';
import SearchBar from '../SearchBar/SearchBar';

const useStyles = makeStyles(() => ({
  drawer: {
    marginTop: '80px',
    '& .MuiDrawer-paper': {
      padding: '30px',
      marginTop: '80px',
      width: '100%',
    },
    '& .MuiBackdrop-root': {
      marginTop: '80px',
    },
  },
}));

const Header = () => {
  const dispatch = useDispatch();
  const stylesClasses = useStyles();
  const isMobileSidebarOpen = useSelector(state => state.page.isMobileSidebarOpen);
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [showSearch, setShowSearch] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const showHeaderLogo = isLoggedIn && showSearch && isMobileView;
  // console.log('showHeaderLogo -->', showHeaderLogo);
  // console.log('showSearch -->', showSearch);

  // useEffect(() => {
  //   console.log('Header UseEffect Called');
  // });

  const toggleMobileSidebar = () => {
    dispatch(pageActions.toggleMobileSidebar());
  };
  const mobileNavLinks = [
    'All',
    'Academy',
    'Coaches',
    'Cohere Plus',
    'Workshops',
    'Group Courses',
    'Communities',
    'Memberships',
    '1:1s',
  ];
  const navLinks = useMemo(
    () => (isLoggedIn ? ['All', 'Journey', 'Communities', 'Academy', 'Coaches'] : ['All', 'Academy', 'Coaches']),
    [isLoggedIn],
  );
  const bottomNavLinks = [
    {
      icon: <AllPageSvgIcon />,
      name: 'All',
    },
    {
      icon: <GroupsIcon width="24px" height="24px" fill="#FFFFFF" />,
      name: 'Community',
    },
    {
      icon: <ChatIcon width="17px" height="17px" />,
      name: 'Chat',
    },
    {
      icon: <JourneySvgIcon />,
      name: 'Journey',
    },
  ];

  const handleSearchClick = () => {
    setShowSearch(!showSearch);
  };
  const handleCancelClick = () => {
    setShowSearch(false);
  };
  return (
    <div className={classes.headerContainer}>
      <div className={classes.leftCol}>
        {!isMobileSidebarOpen && (
          <Drawer className={stylesClasses.drawer} open={!isMobileSidebarOpen} onClose={toggleMobileSidebar}>
            <Navbar navLinks={mobileNavLinks} />
          </Drawer>
        )}
        {isMobileView &&
          (!isMobileSidebarOpen ? (
            <CrossIcon width="20px" height="20px" color="#FFFFFF" onClick={toggleMobileSidebar} />
          ) : (
            <HamburgerMenu width="22px" height="22px" onClick={toggleMobileSidebar} />
          ))}
        {!showHeaderLogo && (
          <div className={classes.headerLogo}>
            {isMobileView ? <CohereLogo width="125" height="30" /> : <CohereLogo width="164" height="38" />}
          </div>
        )}

        {!isMobileView && (
          <>
            <Navbar navLinks={navLinks} />
            <ServicesDropdown />
          </>
        )}
        {!isMobileView && !isLoggedIn ? (
          !showSearch ? (
            <div className={classes.searchIcon}>
              <SearchIcon color="#FFFFFF" width="19px" height="19px" onClick={handleSearchClick} />
            </div>
          ) : (
            <ClickAwayListener
              onClickAway={() => {
                setShowSearch(false);
              }}
            >
              <div className={classes.searchField}>
                <SearchBar variant="filled" onClick={handleCancelClick} />
              </div>
            </ClickAwayListener>
          )
        ) : null}
      </div>

      <div className={classes.rightCol}>
        {isLoggedIn ? (
          <>
            {!showSearch && !isMobileView && (
              <>
                <div className={classes.headerText}>
                  <p>Cohere Plus</p>
                  <p>Platform</p>
                </div>
                <div className={classes.separator}></div>
              </>
            )}
            <LoggedInActions showSearch={showSearch} setShowSearch={setShowSearch} />
          </>
        ) : (
          <>
            {!isMobileView && (
              <>
                <div className={classes.headerText}>
                  <p>Cohere Plus</p>
                  <p>Platform</p>
                </div>
                <div className={classes.separator}></div>
              </>
            )}
            <LoggedOutActions />
          </>
        )}
      </div>
      {isLoggedIn && isMobileView && <BottomNavigation bottomNavLinks={bottomNavLinks} />}
    </div>
  );
};

export default Header;
