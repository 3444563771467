import React from 'react';
import { Grid, Typography } from '@material-ui/core';

function InviteSent({ isDarkThemeEnabled }) {
  return (
    <Grid container style={{ margin: '15px 0px' }}>
      <Typography style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}>
        Once your client is confirmed you will receive the automated calendar invite
      </Typography>
    </Grid>
  );
}

export default InviteSent;
