import React from 'react';
import classes from './LoggedInActions.module.scss';
import Button from 'components/FormUI/Button';
import { ClickAwayListener } from '@material-ui/core';
import { useMediaQuery } from '@mui/material';
import { theme } from 'styles/theme';
import SearchIcon from 'pages/Collective/Icons/SearchIcon';
import FavoriteEmptyIcon from 'pages/Collective/Icons/FavoriteEmptyIcon';
import NotificationDot from 'pages/Collective/Icons/NotificationDot';
import NotificationIcon from 'pages/Collective/Icons/NotificationIcon';
import profileImg from '../../../../../../assets/LoggedInProfileImg.png';
import ChatIcon from 'pages/Collective/Icons/ChatIcon';
import SearchBar from 'pages/Collective/Components/SearchBar/SearchBar';
const LoggedInActions = ({ showSearch, setShowSearch }) => {
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const handleCancelClick = () => {
    setShowSearch(false);
  };
  const handleSearchClick = () => {
    setShowSearch(!showSearch);
  };

  return (
    <div className={classes.loggedInActions}>
      {!showSearch ? (
        <>
          <Button className={isMobileView ? classes.createBtnResponsive : classes.createBtn} onClick={() => {}}>
            Create
          </Button>
          <div className={classes.searchIcon}>
            <SearchIcon color="#FFFFFF" width="19px" height="19px" onClick={handleSearchClick} />
          </div>
        </>
      ) : (
        <ClickAwayListener
          onClickAway={() => {
            setShowSearch(false);
          }}
        >
          <SearchBar variant="filled" onClick={handleCancelClick} />
        </ClickAwayListener>
      )}
      {!isMobileView && (
        <>
          <div className={classes.favoriteIcon}>
            <FavoriteEmptyIcon />
          </div>
          <div className={classes.chatIcon}>
            <div className={classes.redDot}>
              <NotificationDot />
            </div>
            <ChatIcon />
          </div>
          <div className={classes.notificationIcon}>
            <div className={classes.redDot}>
              <NotificationDot />
            </div>
            <NotificationIcon />
          </div>
        </>
      )}
      <img src={profileImg} className={classes.profileImage} alt="profile_image" />
    </div>
  );
};

export default LoggedInActions;
