import React from 'react';
import classes from './ServiceCard.module.scss';

const ServiceCard = ({ item }) => {
  return (
    <div className={classes.mobileCard}>
      <img src={item.imageSrc} alt="" />
      <div className={classes.cardDetails}>
        <div className={classes.title}>{item.title}</div>
        <div className={classes.noOfSerives}>{item.noOfServices}</div>
      </div>
    </div>
  );
};

export default ServiceCard;
