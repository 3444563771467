import React from 'react';

const YoutubeIcon = () => {
  return (
    <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5739 3.04923C20.3393 2.16414 19.6472 1.46707 18.7706 1.23089C17.1792 0.799805 10.7996 0.799805 10.7996 0.799805C10.7996 0.799805 4.42006 0.799805 2.82858 1.22859C1.94974 1.46477 1.25988 2.16184 1.02537 3.04694C0.599609 4.65203 0.599609 7.9998 0.599609 7.9998C0.599609 7.9998 0.599609 11.3476 1.02537 12.9504C1.25988 13.8355 1.95202 14.5325 2.82858 14.7687C4.42006 15.1998 10.7996 15.1998 10.7996 15.1998C10.7996 15.1998 17.1792 15.1998 18.7706 14.7687C19.6495 14.5325 20.3393 13.8355 20.5739 12.9504C20.9996 11.3476 20.9996 7.9998 20.9996 7.9998C20.9996 7.9998 20.9996 4.65203 20.5739 3.04923ZM8.77327 11.0724V4.92719L14.0554 7.97687L8.77327 11.0724Z"
        fill="white"
      />
    </svg>
  );
};

export default YoutubeIcon;
