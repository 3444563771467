import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const getCalendars = () => axiosInstance.get('/ExternalCalendar/GetAllExternalCalendarAccounts').then(get('data'));

const addCalendarAccount = ({ contributionId, isCreate, isMasterCalendar = false }) => {
  let params = '';

  // if (contributionId) {
  //   params = `?contributionId=${contributionId}`;
  // }

  // if (isCreate) {
  params = `?isCreated=false&&isMasterCalendar=${isMasterCalendar}`;
  // }

  return axiosInstance
    .get(`/ExternalCalendar/AddExternalCalendarAccount${params}`)
    .then(response => {
      if (response.data) {
        // const re = "https://dev.cohere.live";
        // const preparedLocalLink = response.data.replace(
        //   re,
        //   "http://localhost:3000"
        // );
        window.location.replace(response.data);
      }
    })
    .catch(error => {});
};

const removeCalendarAccount = email =>
  axiosInstance.delete(`/ExternalCalendar/RemoveExternalCalendarAccount?emailAddress=${email}`).then(get('data'));

const removeCalendarAccountFromConflictsCheck = email =>
  axiosInstance
    .put(`ExternalCalendar/DisableConflictsCheckForExternalCalendar?emailAddress=${email}`)
    .then(get('data'));

const getAcсountsToCheckConflicts = () =>
  axiosInstance.get(`/ExternalCalendar/GetExternalCalendarAccountsToCheckConflicts`).then(get('data'));

const signInCallback = (code, isMasterCalendar) =>
  axiosInstance
    .get(`/ExternalCalendar/ExternalCalendarSignInCallback?code=${code}&isMasterCalendar=${isMasterCalendar}`)
    .then(get('data'));

const getAccountsToSendReminders = () =>
  axiosInstance.get(`/ExternalCalendar/GetExternalCalendarAccountToSendReminders`).then(get('data'));

const putMailForCalendarRemindersRequest = email =>
  axiosInstance
    .put(`/ExternalCalendar/EnableEventRemindersForExternalCalendar?emailAddress=${email}`)
    .then(get('data'));

const putMailsForConfictCheckRequest = emailsArr =>
  axiosInstance.put('/ExternalCalendar/EnableConflictsCheckForExternalCalendars', emailsArr).then(get('data'));

const getBusyTimeRequest = (startTime, endTime) =>
  axiosInstance.get(`/ExternalCalendar/GetBusyTime?startTime=${startTime}&endTime=${endTime}`).then(get('data'));

const setDefaultCalendar = email =>
  axiosInstance.put(`/ExternalCalendar/SetExternalCalendarAsDefault?emailAddress=${email}`).then(get('data'));

const getMasterCalendarEvents = ({ isPreviewMasterCalendarEvents = false, email = '' }) =>
  axiosInstance
    .post(
      `/ExternalCalendar/PreviewMasterCalendarEvents?isPreviewMasterCalendarEvents=${isPreviewMasterCalendarEvents}&email=${email}`,
    )
    .then(get('data'));

const getNylasAccountForCohereAccountAsyncByEmail = (email = null) =>
  axiosInstance
    .get(`/ExternalCalendar/GetNylasAccountForCohereAccountAsyncByEmail`, { params: { Email: email } })
    .then(get('data'));

const addOrUpdateExternalCalendarSettings = (settings = {}) =>
  axiosInstance.post('/ExternalCalendar/AddOrUpdateExternalCalendarSettings', settings).then(get('data'));

export {
  getCalendars,
  addCalendarAccount,
  removeCalendarAccount,
  getAcсountsToCheckConflicts,
  getAccountsToSendReminders,
  putMailForCalendarRemindersRequest,
  putMailsForConfictCheckRequest,
  getBusyTimeRequest,
  signInCallback,
  setDefaultCalendar,
  removeCalendarAccountFromConflictsCheck,
  getMasterCalendarEvents,
  getNylasAccountForCohereAccountAsyncByEmail,
  addOrUpdateExternalCalendarSettings,
};
