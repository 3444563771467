import React from 'react';

const FilterResponsiveIcon = ({ width = '20', height = '20', fill = '#215C73' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1514_11785)">
        <path
          d="M1.29167 4.26036H3.45767C3.62759 4.88557 3.99851 5.43749 4.51322 5.83097C5.02792 6.22446 5.6578 6.43765 6.30569 6.43765C6.95357 6.43765 7.58345 6.22446 8.09816 5.83097C8.61286 5.43749 8.98379 4.88557 9.15371 4.26036H18.7083C18.9183 4.26036 19.1197 4.17695 19.2681 4.02849C19.4166 3.88002 19.5 3.67866 19.5 3.4687C19.5 3.25873 19.4166 3.05737 19.2681 2.9089C19.1197 2.76044 18.9183 2.67703 18.7083 2.67703H9.15371C8.98379 2.05182 8.61286 1.4999 8.09816 1.10642C7.58345 0.712929 6.95357 0.499741 6.30569 0.499741C5.6578 0.499741 5.02792 0.712929 4.51322 1.10642C3.99851 1.4999 3.62759 2.05182 3.45767 2.67703H1.29167C1.0817 2.67703 0.88034 2.76044 0.731874 2.9089C0.583408 3.05737 0.5 3.25873 0.5 3.4687C0.5 3.67866 0.583408 3.88002 0.731874 4.02849C0.88034 4.17695 1.0817 4.26036 1.29167 4.26036ZM6.30529 2.08328C6.5793 2.08328 6.84716 2.16453 7.07499 2.31676C7.30282 2.469 7.48039 2.68537 7.58525 2.93852C7.69011 3.19167 7.71754 3.47023 7.66409 3.73898C7.61063 4.00772 7.47868 4.25458 7.28493 4.44833C7.09118 4.64209 6.84432 4.77404 6.57557 4.82749C6.30683 4.88095 6.02827 4.85351 5.77512 4.74865C5.52196 4.6438 5.30559 4.46622 5.15336 4.23839C5.00113 4.01056 4.91988 3.74271 4.91988 3.4687C4.92029 3.10139 5.06639 2.74925 5.32612 2.48952C5.58584 2.2298 5.93799 2.0837 6.30529 2.08328Z"
          fill={fill}
        />
        <path
          d="M18.7083 9.20833H16.5423C16.3727 8.58298 16.0019 8.03086 15.4873 7.63721C14.9726 7.24356 14.3427 7.03027 13.6947 7.03027C13.0468 7.03027 12.4168 7.24356 11.9022 7.63721C11.3875 8.03086 11.0167 8.58298 10.8471 9.20833H1.29167C1.0817 9.20833 0.88034 9.29174 0.731874 9.44021C0.583408 9.58867 0.5 9.79004 0.5 10C0.5 10.21 0.583408 10.4113 0.731874 10.5598C0.88034 10.7083 1.0817 10.7917 1.29167 10.7917H10.8471C11.0167 11.417 11.3875 11.9691 11.9022 12.3628C12.4168 12.7564 13.0468 12.9697 13.6947 12.9697C14.3427 12.9697 14.9726 12.7564 15.4873 12.3628C16.0019 11.9691 16.3727 11.417 16.5423 10.7917H18.7083C18.9183 10.7917 19.1197 10.7083 19.2681 10.5598C19.4166 10.4113 19.5 10.21 19.5 10C19.5 9.79004 19.4166 9.58867 19.2681 9.44021C19.1197 9.29174 18.9183 9.20833 18.7083 9.20833ZM13.6947 11.3854C13.4207 11.3854 13.1528 11.3042 12.925 11.1519C12.6972 10.9997 12.5196 10.7833 12.4148 10.5302C12.3099 10.277 12.2825 9.99846 12.3359 9.72972C12.3894 9.46097 12.5213 9.21412 12.7151 9.02036C12.9088 8.82661 13.1557 8.69466 13.4244 8.6412C13.6932 8.58775 13.9717 8.61518 14.2249 8.72004C14.478 8.8249 14.6944 9.00247 14.8466 9.2303C14.9989 9.45813 15.0801 9.72599 15.0801 10C15.0797 10.3673 14.9336 10.7194 14.6739 10.9792C14.4142 11.2389 14.062 11.385 13.6947 11.3854Z"
          fill={fill}
        />
        <path
          d="M18.7083 15.7396H9.15371C8.98379 15.1144 8.61286 14.5625 8.09816 14.169C7.58345 13.7755 6.95357 13.5623 6.30569 13.5623C5.6578 13.5623 5.02792 13.7755 4.51322 14.169C3.99851 14.5625 3.62759 15.1144 3.45767 15.7396H1.29167C1.0817 15.7396 0.88034 15.823 0.731874 15.9715C0.583408 16.1199 0.5 16.3213 0.5 16.5313C0.5 16.7412 0.583408 16.9426 0.731874 17.091C0.88034 17.2395 1.0817 17.3229 1.29167 17.3229H3.45767C3.62759 17.9481 3.99851 18.5 4.51322 18.8935C5.02792 19.287 5.6578 19.5002 6.30569 19.5002C6.95357 19.5002 7.58345 19.287 8.09816 18.8935C8.61286 18.5 8.98379 17.9481 9.15371 17.3229H18.7083C18.9183 17.3229 19.1197 17.2395 19.2681 17.091C19.4166 16.9426 19.5 16.7412 19.5 16.5313C19.5 16.3213 19.4166 16.1199 19.2681 15.9715C19.1197 15.823 18.9183 15.7396 18.7083 15.7396ZM6.30529 17.9167C6.03128 17.9167 5.76343 17.8354 5.5356 17.6832C5.30776 17.531 5.13019 17.3146 5.02533 17.0614C4.92047 16.8083 4.89304 16.5297 4.9465 16.261C4.99995 15.9922 5.1319 15.7454 5.32565 15.5516C5.51941 15.3579 5.76627 15.2259 6.03501 15.1725C6.30375 15.119 6.58232 15.1464 6.83547 15.2513C7.08862 15.3562 7.30499 15.5337 7.45722 15.7616C7.60946 15.9894 7.69071 16.2572 7.69071 16.5313C7.69008 16.8985 7.54392 17.2505 7.28424 17.5102C7.02456 17.7699 6.67253 17.916 6.30529 17.9167Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1514_11785">
          <rect width="19" height="19" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FilterResponsiveIcon;
