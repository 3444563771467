import React, { useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Input from 'components/FormUI/Input';
import MaskedInput from 'components/FormUI/MaskedInput';
import { LabelText } from 'components/UI/Text/TextStyles';
import { StyledLink } from 'pages/Auth/SignInPage';
import Select from 'components/FormUI/Select';
import {
  ACCOUNT_FORM_HEADERS,
  ACCOUNT_FORM_FIELDS,
  RESTORE_PASS,
  PURCHASE_MODAL_STEPS,
} from '../PurchaseModal.constants';
import * as timeZoneActions from 'actions/timeZone';

import './AccountForm.scss';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import useRouter from 'hooks/useRouter';

function AccountForm({ handleChange, touched, errors, step, timeZoneLoading, timeZones, fetchTimeZones }) {
  const isShowInput = (step, input) => {
    const visible = {
      memberInit: [ACCOUNT_FORM_FIELDS.email],
      memberLogin: [ACCOUNT_FORM_FIELDS.email, ACCOUNT_FORM_FIELDS.password, RESTORE_PASS],
      memberCreate: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
      ],
    };
    return visible[step].includes(input);
  };
  const { domain } = useRouter();
  const confirmEmailRef = useRef(null);
  const emailRef = useRef(null);
  useEffect(() => {
    const disablePaste = e => {
      e.preventDefault();
    };

    const confirmEmailInput = confirmEmailRef.current;
    if (!confirmEmailInput) return;

    confirmEmailInput.addEventListener('paste', disablePaste);
    return () => {
      confirmEmailInput.removeEventListener('paste', disablePaste);
    };
  }, [step]);

  useEffect(() => {
    if (emailRef.current && step !== PURCHASE_MODAL_STEPS.init) {
      emailRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }

    if ((!timeZones || timeZones.length === 0) && !timeZoneLoading) {
      fetchTimeZones();
    }
  }, [step, timeZones, fetchTimeZones]);

  return (
    <Grid item container xs={12} className="my-3">
      <Grid item xs={12}>
        <LabelText>{ACCOUNT_FORM_HEADERS[step]}</LabelText>
        <br />
        <br />
      </Grid>
      <Grid item container xs={12} justify="space-between">
        <Grid item container spacing={2} sm={6} xs={12}>
          <Grid item xs={12}>
            <Input
              fullWidth
              name={ACCOUNT_FORM_FIELDS.email}
              type="text"
              label="Email"
              error={touched.Email && errors.Email}
              ref={emailRef}
            />
          </Grid>
          {isShowInput(step, ACCOUNT_FORM_FIELDS.confirmEmail) && (
            <Grid item xs={12}>
              <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.confirmEmail}
                type="email"
                label="Confirm email"
                onChange={handleChange}
                error={touched.ConfirmEmail && errors.ConfirmEmail}
                ref={confirmEmailRef}
              />
            </Grid>
          )}
          {isShowInput(step, ACCOUNT_FORM_FIELDS.password) && (
            <Grid item xs={12}>
              <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.password}
                type="password"
                label="Password"
                onChange={handleChange}
                error={touched.Password && errors.Password}
              />
            </Grid>
          )}
        </Grid>
        {isShowInput(step, RESTORE_PASS) && (
          <Grid item xs={12}>
            <StyledLink onClick={() => redirectToAppIfIsCoachDomain(domain, '/auth/restore-pass')}>
              I forgot my password
            </StyledLink>
          </Grid>
        )}
        <Grid item container spacing={2} sm={6} xs={12}>
          {isShowInput(step, ACCOUNT_FORM_FIELDS.firstName) && (
            <Grid item xs={12}>
              <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.firstName}
                type="text"
                label="First name"
                onChange={handleChange}
                error={touched.FirstName && errors.FirstName}
              />
            </Grid>
          )}
          {isShowInput(step, ACCOUNT_FORM_FIELDS.lastName) && (
            <Grid item xs={12}>
              <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.lastName}
                type="text"
                label="Last name"
                onChange={handleChange}
                error={touched.LastName && errors.LastName}
              />
            </Grid>
          )}
          {isShowInput(step, ACCOUNT_FORM_FIELDS.timeZoneId) && (
            <Grid item xs={12}>
              <Select
                label="Timezone"
                name={ACCOUNT_FORM_FIELDS.timeZoneId}
                fullWidth
                onChange={handleChange}
                items={
                  timeZones?.length > 0
                    ? timeZones.map(timeZone => {
                        return { value: timeZone.countryName, title: timeZone.name };
                      })
                    : []
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

AccountForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
  step: PropTypes.string.isRequired,
  timeZoneId: PropTypes.string,
  timeZone: PropTypes.shape({
    timeZones: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  timeZoneLoading: PropTypes.bool.isRequired,
  fetchTimeZones: PropTypes.func.isRequired,
};

const mapStateToProps = ({ timeZone }) => ({
  timeZones: timeZone?.timeZones,
  timeZoneLoading: timeZone?.loading,
});

const actions = {
  fetchTimeZones: timeZoneActions.fetchTimeZones,
};

export default connect(mapStateToProps, actions)(AccountForm);
