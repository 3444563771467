import React from 'react';
import classes from './MainContainer.module.scss';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const MainContainer = ({ children }) => {
  return (
    <div className={classes.container}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default MainContainer;
