import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  mdiDotsVertical,
  mdiHeart,
  mdiMessage,
  mdiBookmark,
  mdiPinOutline,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiPinOffOutline,
} from '@mdi/js';
import Linkify from 'react-linkify';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SendIcon from '@material-ui/icons/Send';
import map from 'lodash/map';
import some from 'lodash/some';
import find from 'lodash/find';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import * as _ from 'lodash';
import { ContributionType, UserRoles } from 'helpers/constants';
import { colors } from 'utils/styles';
import { addComment, addLike, notifyTaggedUsers, editPost, removeLike } from 'services/community.service';
import EditModal from '../EditModal/EditModal';
import { Attachments } from '../Attachments/Attachments';
import {
  AvatarComponent,
  StyledClickDropDown,
  StyledContainer,
  StyledDate,
  StyledLikeInfo,
  StyledMessage,
  StyledMetaData,
  StyledName,
  StyledText,
  StyledContainerComment,
  AvatarComponentComment,
  StyledInputWrapper,
  StyledTextAreaWrap,
  StyledCommentsToggle,
  StyledAttachmentsWrap,
  StyledButton,
  StyleTextLink,
  StyledProfileLink,
  StyledTextError,
} from './Post.styled';
import useContribution from '../../../hooks/useContribution';
import * as actions from '../../../../../actions/community';
import Icon from '../../../../../components/UI/Icon';
import ClickDropDown, { StyledDropDownItem } from '../../../../../components/UI/ClickDropDown';
import { useAccount, useRouter, usePartnerCoach, useUnifiedCommunity } from '../../../../../hooks';
import { COUNT_POSTS_BY_PAGE } from '../../../../../constants';
import useDiffDate from '../../hooks/useDiffDate';
import { StyledTextArea } from '../CreatePost/CreatePost.styled';
import LinkPreview from '../LinkPreview/LinkPreview';
import chatService from 'services/chat.service';
import Comment from '../Comment/Comment';
import UserMentionInput from 'components/UI/mentions/UserMentionInput';
import EventIcon from '@material-ui/icons/Event';
import { determineDarkThemedColorToUse } from 'services/contributions.service';
import moment from 'moment';
import { TemplateType } from 'helpers/constants/templateType';
import { ColorSchemeService } from 'services/color.service';
import { addhttp, isValidUrl, lightOrDark } from 'utils/utils';
import styled from 'styled-components';
import './Post.scss';
import { wordWrap } from 'polished';
import { useRef } from 'react';
import { toRem } from '../../../../../utils/styles';

const ROW_HEIGHT = 20;

const StyledPostOuterContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledCoachTag = styled.div`
  font-family: Avenir;
  font-weight: 500;
  border-radius: 4px;
  padding: 3px 8px;
  border: 1px solid #b2cae0;
  font-size: 10px;
  margin-left: 5px;
  margin-top: 5px;
  color: #215c73;
  background-color: #f1f8ff;
  ${({ mobileView }) =>
    mobileView &&
    `
  padding: 2px 8px;
  font-size: 8px;
`}
`;
const StyledCommunityTag = styled.div`
  font-family: Avenir;
  font-weight: 500;
  border-radius: 4px;
  padding: 3px 8px;
  border: 1px solid #a58b62;
  font-size: 10px;
  margin-left: 5px;
  margin-top: 5px;
  color: #a58b62;
  background-color: rgba(209, 185, 137, 0.1);
  ${({ mobileView }) =>
    mobileView &&
    `
  padding: 2px 8px;
  font-size: 8px;
`}
`;

const StyledCommunityName = styled.div`
  display: flex;
  align-items: center;
  font-family: Avenir;
  font-weight: 700;
  font-size: ${toRem(13)};
  line-height: 12px;
  color: #215c73;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
`;

const Post = ({
  modal,
  post,
  communityPosts,
  pods = [],
  defaultTextareaValue = '',
  backFunction,
  supportPopup,
  getActiveContribution,
  searchedWord,
  currentGroup,
  reloadPostsOnEdit,
  onHashtagClick,
}) => {
  const { isUnifiedCommunity } = useUnifiedCommunity();
  const contrib = useContribution();
  const contribution = isUnifiedCommunity ? post?.contribution : contrib;
  const colorToUse = determineColorToUse(isUnifiedCommunity ? {} : contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(isUnifiedCommunity ? {} : contribution);
  const { themedColor, themedCardBackgroundColor, themedBackgroundColor } = getThemedColors(
    isUnifiedCommunity ? {} : contribution,
  );
  const hashtagref = useRef(null);
  const { domain, history } = useRouter();
  const podIdPrefix = 'podId-';
  const contributionAuthor = useSelector(state => state.cohealerInfo.info);
  const theme = useTheme();
  const [showAllLikes, setShowAllLikes] = useState(false);
  // TODO move to redux
  const [item, setItem] = useState(post);
  const [isMyLike, setIsMyLike] = useState(false);
  const [likeCount, setLikeCount] = useState(item?.likes?.length);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showSingleComment, setShowSingleComment] = useState(true);
  const [showAllComments, setShowAllComments] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [commentPostPending, setCommentPostPending] = useState(false);
  const dispatch = useDispatch();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  // const { partnerCoaches } = usePartnerCoach(contribution?.id);
  const { user } = useAccount();
  const { currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const isClient = currentRole === UserRoles.client;
  const isMyPost = user.id === item?.userInfo?.id;
  const { createDataDiff } = useDiffDate(item?.createTime, user?.timeZoneId);
  const scheduledTime = moment(post.scheduledTime).format('MMM DD, YYYY h:mm A');
  const [userMentions, setUserMentions] = useState([]);
  const [resetUserMentionInputValue, setResetUserMentionInputValue] = useState(false);
  const [textareaValue, setTextareaValue] = useState(defaultTextareaValue);

  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  const type = contribution?.type;
  // const shouldChangeColor = type != ContributionType.contributionOneToOne;
  const btnColor = colorToUse?.PrimaryColorCode;
  const AccentColor = colorToUse?.AccentColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.AccentColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  // const users = [
  //   ...contribution?.participants?.map(x => ({
  //     imageUrl: x.avatarUrl,
  //     display: `${x.firstName} ${x.lastName}`,
  //     id: x.id,
  //   })),
  //   ...(contribution?.contributionPartners &&
  //     contribution?.contributionPartners.map(
  //       x =>
  //       ({
  //         imageUrl: x.avatarUrl,
  //         display: `${x.firstName} ${x.lastName}`,
  //         id: x.userId,
  //       } || []),
  //     )),
  //   ...pods.map(x => ({ id: `${podIdPrefix}${x.id}`, display: x.name })),
  // ];

  const users = [
    ...(contribution?.participants?.map(x => ({
      imageUrl: x.avatarUrl,
      display: `@${x.firstName} ${x.lastName}`,
      id: x.id,
    })) || []),
    ...(contribution?.contributionPartners?.map(x => ({
      imageUrl: x.avatarUrl,
      display: `@${x.firstName} ${x.lastName}`,
      id: x.userId,
    })) || []),
    ...pods.map(x => ({ id: `${podIdPrefix}${x.id}`, display: x.name })),
  ];

  if (user.id === contribution?.userId || contribution?.contributionPartners?.some(x => x.userId == user.id)) {
    users.push({
      imageUrl: null,
      display: `channel`,
      id: 'channelId',
    });
  }
  if (contributionAuthor && !users.some(e => e.id === contributionAuthor.id)) {
    users.push({
      imageUrl: contributionAuthor.avatarUrl,
      display: `@${contributionAuthor.firstName} ${contributionAuthor.lastName}`,
      id: contributionAuthor.id,
    });
  }

  const fetchPosts = values => {
    // dispatch(actions.getAllPostsForContribution(`${contribution?.id}/1/${COUNT_POSTS_BY_PAGE}`)).then(() => { });
    // dispatch(
    //   actions.getAllPostsForContribution(
    //     `${contribution?.id}/1/${COUNT_POSTS_BY_PAGE}${!_.isEmpty(currentGroup) && currentGroup?.orderNo !== -1 ? `?groupId=${currentGroup.id}` : ''
    //     }`,
    //   ),
    // ).then(() => { });
    reloadPostsOnEdit();
  };

  useEffect(() => {
    const post = communityPosts?.posts?.find(p => p.id === item.id);
    if (post) {
      setItem(post);
    }
  }, [communityPosts]);

  const handleTogglePin = () => {
    editPost({
      ...item,
      userId: item.userInfo.id,
      isApprovalRequired: isCoach ? false : contribution?.isPostApprovalRequired,
      isPinned: !item.isPinned,
    }).then(() => {
      fetchPosts();
    });
  };
  const handleClickEdit = useCallback(() => {
    setModalOpened(true);
  }, [setModalOpened]);
  const handleModalClose = useCallback(() => {
    setModalOpened(false);
  }, [setModalOpened]);

  useEffect(() => {
    // const interval = setInterval(() => {
    //   fetchPosts();
    //   console.log('interval fetching new posts... ' + new Date())
    // }, 30000);
    // return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setIsMyLike(some(item.likes, { userInfo: { id: user.id } }));
  }, [item.likes, user.id]);

  const handleToggleLike = () => {
    if (isMyLike) {
      const currentLike = find(item.likes, { userInfo: { id: user.id } });
      removeLike(currentLike?.id).then(() => {
        const newLikes = filter(item.likes, like => like.id !== currentLike.id);
        setItem({ ...item, likes: [...newLikes] });
        setIsMyLike(false);
        setLikeCount(likeCount - 1);
      });
    } else {
      addLike({
        postId: item.id,
      }).then(data => {
        setItem({ ...item, likes: [...item.likes, data] });
        setIsMyLike(true);
        setLikeCount(likeCount + 1);
      });
    }
  };

  const handleToggleCommentLike = comment => {
    const currentLike = find(comment.likes, { userInfo: { id: user.id } });
    if (currentLike) {
      removeLike(currentLike?.id).then(() => {
        const newLikes = filter(comment.likes, like => like.id !== currentLike.id);
        comment.likes = newLikes;
        setLikeCount(likeCount - 1);
      });
    } else {
      addLike({
        postId: item.id,
        commentId: comment.id,
      }).then(data => {
        comment.likes.push(data);
        setLikeCount(likeCount + 1);
      });
    }
  };

  const handleClickDelete = () => {
    dispatch(actions.deletePostById(item.id)).then(() => {
      // dispatch(actions.getAllPostsForContribution(`${contribution?.id}/1/${COUNT_POSTS_BY_PAGE}${!_.isEmpty(currentGroup) && currentGroup?.orderNo !== -1 ? `?groupId=${currentGroup.id}` : ''
      //   }`));
      // dispatch(
      //   actions.getAllPostsForContribution(
      //     `${contribution.id}/1/${COUNT_POSTS_BY_PAGE}${!_.isEmpty(currentGroup) && currentGroup?.orderNo !== -1 ? `?groupId=${currentGroup.id}` : ''
      //     }`,
      //   ),
      // );
      fetchPosts();
    });
  };
  const handleToggleCommentInput = () => {
    setShowCommentInput(!showCommentInput);
  };

  const handleCommentCreate = (e, { resetForm }) => {
    setCommentPostPending(true);
    setShowSingleComment(false);
    addComment({
      text: textareaValue,
      postId: item.id,
    })
      .then(data => {
        resetForm({});
        setItem({ ...item, comments: [...item.comments, data] });
        setCommentPostPending(false);
        setShowCommentInput(false);
        setShowSingleComment(true);
      })
      .then(async () => {
        if (!isEmpty(userMentions)) {
          let mentionedUserIds;

          if (userMentions.some(e => e.id === 'channelId')) {
            mentionedUserIds = uniq([
              ...contribution?.participants?.map(x => x.id),
              ...(contribution?.contributionPartners && contribution?.contributionPartners.map(x => x.userId)),
              ...pods.flatMap(podItem => podItem.clientIds),
              contribution?.userId,
            ]);
          } else {
            mentionedUserIds = uniq([
              ...userMentions.filter(x => !x.id.includes(podIdPrefix)).map(x => x.id),
              ...userMentions
                .filter(x => x.id.includes(podIdPrefix))
                .flatMap(x =>
                  pods.filter(pod => `${podIdPrefix}${pod.id}` === x.id).flatMap(podItem => podItem.clientIds),
                ),
            ]).filter(x => isEmpty(x));
          }

          await notifyTaggedUsers({
            mentionAuthorUserName: `${user.firstName} ${user.lastName}`,
            message: textareaValue,
            contributionName: contribution?.title,
            ContributionId: contribution?.id,
            mentionedUserIds,
            PostId: item?.id,
            AuthorUserId: user?.id,
            replyLink: `https://${domain}/contribution-view/${contribution?.id}/community/social/${item.id}`,
          });
        }
        setTextareaValue('');
        setResetUserMentionInputValue(true);
      });
  };
  const handleKeyUp = useCallback((plainText, mentions) => {
    setTextareaValue(plainText);
    setUserMentions(mentions);
    setResetUserMentionInputValue(false);
  });
  const handleToggleComments = ({ target: { value } }) => {
    setShowAllComments(!showAllComments);
  };
  const handleToggleShowLikes = () => {
    setShowAllLikes(!showAllLikes);
  };

  const onStartDirectChat = useCallback(
    participantId => {
      window.location.href = addhttp(domain?.concat(`/conversations/all?chatId=${participantId}`));
    },
    [domain],
  );

  const containsHTML = str => /<[a-z][\s\S]*>/i.test(str);

  const generateHTMLText = (text, searchedWord) => {
    let newSentence;
    if (!!searchedWord?.length) {
      let matchPattern = text?.split(' ');
      newSentence = matchPattern?.map(word => {
        const lowerCaseWord = word.toLowerCase();
        const lowerCaseSeaarchedWord = searchedWord.toLowerCase();
        if (
          lowerCaseWord.includes(lowerCaseSeaarchedWord) ||
          lowerCaseSeaarchedWord.includes(lowerCaseWord) ||
          word.includes('#')
        ) {
          if (containsHTML(word)) {
            const str = word.toString();
            const text = str.replace(/(<([^>]+)>)/gi, '');
            return `<span style="background-color: #f6e8ab;">${text}</span>`;
          } else {
            return `<span style="background-color: #f6e8ab;">${word}</span>`;
          }
        } else {
          return word;
        }
      });
      return newSentence.join(' ');
    }

    const pattern = /(?:[@#](?:Channel|All|\w+\s+\w+)|[#]\w+\s|[#]\w+|(?:https?:\/\/)?(?:www\.)?\S+)/gi;
    let matchPattern = text?.match(pattern);
    matchPattern = matchPattern?.map(pattern => pattern.trim());
    const newMatchedPattern = matchPattern?.map((tag, index) => {
      const isMatchedUser = contribution?.participants?.find(
        participant =>
          `@${participant.firstName} ${participant.lastName}` === `${matchPattern[index]} ${matchPattern[index + 1]}`,
      );
      if (
        `${matchPattern[index]} ${matchPattern[index + 1]}` === `@${user.firstName} ${user.lastName}` ||
        isMatchedUser
      ) {
        return `${matchPattern[index]} ${matchPattern[index + 1]}`;
      } else if (matchPattern[0].includes('#')) {
        return `${matchPattern[0]}`;
      } else {
        return tag;
      }
    });
    let updatedText = text;
    if (matchPattern) {
      let filteredUser = [];
      filteredUser = newMatchedPattern?.filter((keyword, index) => {
        if (
          keyword.toLowerCase() === '@All'.toLowerCase() ||
          keyword.toLowerCase() === '@channel'.toLowerCase() ||
          keyword === `@${user.firstName} ${user.lastName}` ||
          keyword.includes('www.') ||
          keyword.includes('http:') ||
          keyword.includes('https:') ||
          keyword.includes('#')
        ) {
          return keyword;
        }
      });
      const a = contribution?.participants?.filter(participant => {
        const name = `@${participant.firstName} ${participant.lastName}`.trimEnd();
        const userMatched = matchPattern?.find(userName => name === userName);
        if (userMatched) {
          filteredUser.push(name);
        }
      });
      if (!matchPattern) {
        return updatedText;
      }
      filteredUser.forEach(match => {
        const isUrl = match.includes('http:') || match.includes('https:') || match.includes('www.');
        let replacement = ``;
        if (isUrl) {
          const urlWithProtocol = match.includes('http:') || match.includes('https:');
          if (urlWithProtocol) {
            replacement = `<span style="color: ${colorToUse.AccentColorCode};cursor:pointer"><a href=${match} target="_blank">${match}</a></span>`;
          } else {
            const link = `https://${match}`;
            replacement = `<span style="color: ${colorToUse.AccentColorCode};cursor:pointer"><a href=${link} target="_blank">${match}</a></span>`;
          }
        } else if (match) {
          if (match.startsWith('#')) {
            const [hashtag, ...followingWords] = match.split(' ');
            const hashtagText = hashtag;
            const nonHighlightedText = followingWords.length ? ` ${followingWords.join(' ')}` : ''; // Include non-highlighted text
            replacement = isDarkThemeEnabled
              ? `<span style="color: ${colorToUse.AccentColorCode};cursor:pointer;" class="hashtag-clickable" data-hashtag="${hashtagText}">${hashtag}<span style="color:white;cursor:pointer;">${nonHighlightedText}</span></span>`
              : `<span style="color: ${colorToUse.AccentColorCode};cursor:pointer;" class="hashtag-clickable" data-hashtag="${hashtagText}">${hashtag}<span style="color:black;cursor:pointer;">${nonHighlightedText}</span></span>`;
          } else {
            replacement = `<span  style="color: ${colorToUse.AccentColorCode};cursor:pointer;" id="hashtagclick">${match}</span>`;
          }
        } else {
          replacement = match; // Keep non-hashtag words unchanged
        }
        updatedText = updatedText.replace(match, replacement);
      });
    }
    if (updatedText === '') {
      updatedText = `</br>`;
    }

    return updatedText;
  };

  const getClassName = (isDarkThemeEnabled, isUnifiedCommunity, className) => {
    if (isUnifiedCommunity) {
      return isDarkThemeEnabled ? `${className}-dark-themed-unified` : `${className}-unified`;
    } else {
      return isDarkThemeEnabled ? `${className}-dark-themed` : className;
    }
  };
  const clickableHashtags = document.querySelectorAll('.hashtag-clickable');
  clickableHashtags.forEach(hashtagSpan => {
    hashtagSpan.addEventListener('click', () => {
      const clickedHashtag = hashtagSpan.dataset.hashtag;

      onHashtagClick(clickedHashtag);
    });
  });

  const filteredArray = item?.hashTags.filter(hashtag => item?.text.includes(hashtag) === false);

  return (
    <StyledContainer
      style={{ backgroundColor: themedCardBackgroundColor, color: themedColor }}
      className={isDarkThemeEnabled ? 'dark-post-container' : ''}
      modal={modal}
      direction="row"
      xs={12}
      isUnifiedCommunity={isUnifiedCommunity}
    >
      <Grid container direction="row" justifyContent="space-between">
        <StyledProfileLink
          isMyPost={isMyPost}
          isDarkThemeEnabled={isDarkThemeEnabled}
          onClick={() => {
            if (!isUnifiedCommunity) {
              if (!supportPopup) {
                onStartDirectChat(item.userInfo.id);
              }
            }
          }}
        >
          <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
            {supportPopup && (
              <div>
                <ArrowBackIosIcon onClick={backFunction} style={{ color: '#4a4a4a' }} fontSize="small" />
              </div>
            )}
            <div style={{ display: 'flex' }}>
              <AvatarComponent
                alt={`${item.userInfo.firstName}${item.userInfo.lastName}`}
                src={item.userInfo.avatarUrl}
                mobileView={mobileView}
              >
                {item.userInfo.firstName[0]}
                {item.userInfo.lastName[0]}
              </AvatarComponent>
              <Box component="div">
                <div style={{ flexDriection: 'row', display: 'flex', alignItems: 'center' }}>
                  <StyledName>
                    <div style={{ marginTop: '7px' }}>
                      {' '}
                      {item.userInfo.firstName} {item.userInfo.lastName}
                    </div>
                    {item.isScheduled && !isClient && (
                      <EventIcon
                        mobileView={mobileView}
                        size="10px"
                        className="ml-1 smoked-gary-color hoverable"
                        style={{ color: `${colors.gold}`, fontSize: '20px' }}
                      />
                    )}
                    {item.isBubbled && <EmojiObjectsIcon style={{ color: colors.gold }} className="ml-1" />}
                  </StyledName>
                  <div
                    style={
                      mobileView
                        ? { display: 'flex', flexDirection: 'row', marginBottom: '5px' }
                        : { display: 'flex', flexDirection: 'row' }
                    }
                  >
                    {item?.userInfo?.isCohealer && isUnifiedCommunity && (
                      <>
                        <div>
                          <StyledCoachTag mobileView={mobileView}>COACH</StyledCoachTag>
                        </div>
                      </>
                    )}
                    {item?.userInfo?.isCohealer &&
                      isUnifiedCommunity &&
                      item?.userInfo?.id === item?.contribution?.userId &&
                      isMyPost && (
                        <>
                          <div>
                            <StyledCommunityTag mobileView={mobileView}>MY COMMUNITY</StyledCommunityTag>
                          </div>
                        </>
                      )}
                  </div>
                </div>
                {isUnifiedCommunity && item?.contribution?.title && (
                  <StyledCommunityName
                    onClick={() => {
                      history.push(
                        `/contribution-view/${item?.contributionId}/community${
                          item.groupId ? '/'.concat(item?.groupId) : ''
                        }`,
                      );
                    }}
                  >
                    {item?.contribution?.title}{' '}
                    {item?.group && (
                      <>
                        ,
                        <span style={{ fontSize: '14px' }}>
                          {isValidUrl(item.group.imageUrl) ? (
                            <img
                              src={item.group.imageUrl}
                              alt="group-icon"
                              style={{ width: '12px', height: '12px' }}
                              className="mx-1"
                            />
                          ) : (
                            item.group.imageUrl
                          )}
                        </span>
                        {item.group.title}
                      </>
                    )}
                  </StyledCommunityName>
                )}
                <StyledDate style={{ color: themedColor }}>
                  {item.isScheduled && !isClient ? 'Scheduled for ' + scheduledTime : createDataDiff}
                </StyledDate>
              </Box>
            </div>
          </Box>
        </StyledProfileLink>
        <StyledClickDropDown>
          <Box display="flex" style={{ marginTop: '7px' }}>
            {item?.userInfo?.isCohealer && !isUnifiedCommunity && (
              <span>
                <Icon color={colorToUse.AccentColorCode} path={mdiBookmark} right="5" valign="top" />
                Coach
              </span>
            )}
            {item?.isPinned && <Icon path={mdiPinOutline} left="20" top="3" size={0.9} />}
            {(isMyPost || isCoach) && (
              <ClickDropDown icon={mdiDotsVertical}>
                {isCoach && !isUnifiedCommunity && (
                  <StyledDropDownItem
                    style={{ color: themedColor, backgroundColor: themedBackgroundColor }}
                    onClick={handleTogglePin}
                  >
                    {item?.isPinned ? (
                      <>
                        <Icon color={themedColor} path={mdiPinOffOutline} right="5" size={0.9} />
                        Unpin at the top
                      </>
                    ) : (
                      <>
                        <Icon color={themedColor} path={mdiPinOutline} right="5" size={0.9} />
                        Pin to top
                      </>
                    )}
                  </StyledDropDownItem>
                )}
                {isMyPost && (
                  <StyledDropDownItem
                    style={{ color: themedColor, backgroundColor: themedBackgroundColor }}
                    onClick={handleClickEdit}
                  >
                    <Icon color={themedColor} path={mdiPencilOutline} right="5" size={0.9} />
                    Edit
                  </StyledDropDownItem>
                )}
                {(isMyPost || isCoach) && (
                  <StyledDropDownItem
                    style={{ color: themedColor, backgroundColor: themedBackgroundColor }}
                    onClick={handleClickDelete}
                  >
                    <Icon color={themedColor} path={mdiDeleteOutline} right="5" size={0.9} />
                    Delete
                  </StyledDropDownItem>
                )}
              </ClickDropDown>
            )}
          </Box>
        </StyledClickDropDown>
      </Grid>
      <StyledText isUnifiedCommunity style={{ color: themedColor }}>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <StyleTextLink
              style={{ color: isCoach ? colors.darkOceanBlue : colorToUse.AccentColorCode }}
              target="blank"
              href={decoratedHref}
              key={key}
            >
              {decoratedText}
            </StyleTextLink>
          )}
        >
          {!isEmpty(item.text) &&
            item.text
              .split('\n')
              .map(str => <div dangerouslySetInnerHTML={{ __html: generateHTMLText(str, searchedWord) }} />)}
        </Linkify>
      </StyledText>
      {/* {item?.hashTags && (
      {filteredArray && (
        <div style={{ marginBottom: '10px' }}>
          {filteredArray.map((e, i) => (
            <div
              style={{
                height: '15%',
                backgroundColor: isUnifiedCommunity ? '#F5F5F5' : colorToUse?.AccentColorCode,
                color: isUnifiedCommunity ? '#215C73' : textColor,
                display: 'inline-block',
                marginLeft: i === 0 ? '' : '5px',
                paddingLeft: '10px',
                paddingRight: '10px',
                padding: isUnifiedCommunity ? '8px' : '8px',
                marginTop: '5px',
                width: e.length > 40 && '60ch',
                wordWrap: 'break-word',
                borderRadius: isUnifiedCommunity ? '4px' : '4px',
                fontSize: '16px',
                fontWeight: '500',
                cursor: 'pointer',
              }}
              onClick={() => {
                onHashtagClick(e);
              }}
            >
              {`${e}`}
            </div>
          ))}
        </div>
      )} */}
      <StyledAttachmentsWrap>
        <Attachments modal={modal} attachments={item.attachments} colorToUse={colorToUse} />
      </StyledAttachmentsWrap>
      {!isEmpty(item?.links) && map(item?.links, (item, index) => <LinkPreview key={index} item={item} />)}
      {item.likes.length > 1 && (
        <StyledLikeInfo onClick={handleToggleShowLikes} style={{ color: themedColor, opacity: '85%' }}>
          {showAllLikes
            ? map(
                item.likes,
                (like, index) =>
                  `${like.userInfo?.firstName} ${like.userInfo?.lastName}${
                    index + 1 >= item.likes.length ? '.' : ','
                  }  `,
              )
            : `${item.likes[0]?.userInfo?.firstName} ${item.likes[0]?.userInfo?.lastName} and ${
                item.likes.length - 1
              } liked this`}
        </StyledLikeInfo>
      )}

      {item.likes.length === 1 && !isUnifiedCommunity && (
        <StyledLikeInfo onClick={handleToggleShowLikes} style={{ color: themedColor, opacity: '85%' }}>
          {item.likes[0]?.userInfo?.firstName} {item.likes[0]?.userInfo?.lastName} liked this
        </StyledLikeInfo>
      )}

      {
        <StyledMetaData container>
          <StyledMessage onClick={handleToggleCommentInput}>
            <Icon color="#a9a9a9" path={mdiMessage} right="5" valign="top" />
            <span>Reply</span>
          </StyledMessage>
          <div>
            <Icon
              onClick={handleToggleLike}
              color={isMyLike ? btnColor : '#a9a9a9'}
              // color={isMyLike  ? PrimaryColor : '#a9a9a9'}
              path={mdiHeart}
              right="5"
              valign="top"
            />
            <span>{likeCount > 0 ? likeCount : ''}</span>
          </div>
        </StyledMetaData>
      }
      {/* {isUnifiedCommunity && (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div>
            <Icon
              onClick={handleToggleLike}
              color={isMyLike ? btnColor : '#a9a9a9'}
              // color={isMyLike  ? PrimaryColor : '#a9a9a9'}
              path={mdiHeart}
              right="5"
              valign="top"
            />
            <span>{likeCount > 0 ? likeCount : ''}</span>
            <span style={{ marginLeft: '5px', marginRight: '50px' }}>LOVE</span>
          </div>
          <StyledMessage onClick={handleToggleCommentInput}>
            <Icon color="#a9a9a9" path={mdiMessage} right="5" valign="top" />
            <span>{item?.comments?.length > 0 ? item?.comments.length : ''}</span>
            <span style={{ marginLeft: '5px' }}>COMMENTS</span>
          </StyledMessage>
        </div>
      )} */}
      {isUnifiedCommunity && (item?.comments?.length > 0 || showCommentInput) && <hr style={{ width: '100%' }} />}
      {showCommentInput && (
        <Formik
          enableReinitialize
          initialValues={{
            text: '',
          }}
          validateOnMount
          isInitialValid={false}
          onSubmit={handleCommentCreate}
          id="post-comment-form"
        >
          {formProps => {
            return (
              <div
                style={
                  isUnifiedCommunity
                    ? { backgroundColor: themedBackgroundColor, borderRadius: '50px' }
                    : { backgroundColor: themedBackgroundColor }
                }
                className="create-post-root"
              >
                <Grid container className="flex-flow-column">
                  <Grid container className="flex-flow-row align-items-start">
                    <Grid container className="flex-flow-row align-items-start">
                      {!isUnifiedCommunity && (
                        <Box component="div" display="flex" alignItems="center">
                          <AvatarComponentComment
                            alt={`${user.firstName} ${user.lastName}`}
                            src={user.avatarUrl}
                            mobileView={mobileView}
                          >
                            {`${user.firstName && user.firstName[0]}${user.lastName && user.lastName[0]}`}
                          </AvatarComponentComment>
                          <Box component="div">
                            <StyledName>
                              {user.firstName} {user.lastName}
                            </StyledName>
                          </Box>
                        </Box>
                      )}
                      <StyledPostOuterContainer>
                        <Grid
                          style={{ width: '90%' }}
                          // className={` ${isDarkThemeEnabled ? `create-post-container-dark-themed-unified ` : `create-post-container-unified`
                          //   } flex-flow-row`}
                          className={`${getClassName(
                            isDarkThemeEnabled,
                            isUnifiedCommunity,
                            'create-post-container',
                          )} flex-flow-row`}
                          container
                        >
                          <div
                            // className={
                            //   isDarkThemeEnabled
                            //     ? 'create-post-mention-input-container-dark-themed-unified'
                            //     : 'create-post-mention-input-container-unified'
                            // }
                            className={getClassName(
                              isDarkThemeEnabled,
                              isUnifiedCommunity,
                              'create-post-mention-input-container',
                            )}
                          >
                            <UserMentionInput
                              placeholder="What’s on your mind..."
                              defaultValue={textareaValue}
                              users={isUnifiedCommunity ? [] : users}
                              onInputChange={handleKeyUp}
                              resetInputValue={resetUserMentionInputValue}
                              onResetInputValueCallback={() => setResetUserMentionInputValue(false)}
                            />
                          </div>
                        </Grid>
                        <div style={{ position: 'absolute', bottom: '15px', right: '15px' }}>
                          <StyledButton
                            disabled={commentPostPending || textareaValue?.length <= 0}
                            autoWidth
                            type="submit"
                            id="post-create-form"
                            style={{ backgroundColor: btnColor }}
                            onClick={formProps.handleSubmit}
                          >
                            {commentPostPending ? (
                              <CircularProgress
                                size={36}
                                className="chat-messages-history-list__load-more-messages-progress"
                              />
                            ) : (
                              <SendIcon
                                fontSize="small"
                                style={{ color: textColor }}
                                className="smoked-gary-color hoverable"
                              />
                            )}
                          </StyledButton>
                        </div>
                      </StyledPostOuterContainer>
                    </Grid>
                    {!formProps.isValid && (
                      <StyledTextError mobileView={mobileView}>{formProps.errors.text}</StyledTextError>
                    )}
                  </Grid>
                </Grid>
              </div>
            );
          }}
        </Formik>
      )}
      {item.comments.length > 1 && (
        <StyledCommentsToggle onClick={handleToggleComments}>
          {showAllComments ? 'Hide comments' : `View all ${item.comments.length} comments`}
        </StyledCommentsToggle>
      )}
      <div id="YYYY">
        {showAllComments ? (
          map(item.comments, comment => {
            return (
              <>
                <Comment
                  commentData={comment}
                  themedColor={themedColor}
                  themedCardBackgroundColor={themedCardBackgroundColor}
                  themedBackgroundColor={themedBackgroundColor}
                  isCoach={isCoach}
                  user={user}
                  generateHTMLText={generateHTMLText}
                  handleToggleCommentLike={handleToggleCommentLike}
                  allComments={item.comments}
                  fetchPosts={fetchPosts}
                  pods={pods}
                  contributionData={contribution}
                />
              </>
            );
          })
        ) : (
          <>
            {' '}
            {showSingleComment && (
              <Comment
                commentData={item?.comments[0]}
                themedColor={themedColor}
                themedCardBackgroundColor={themedCardBackgroundColor}
                themedBackgroundColor={themedBackgroundColor}
                generateHTMLText={generateHTMLText}
                isCoach={isCoach}
                user={user}
                handleToggleCommentLike={handleToggleCommentLike}
                allComments={item.comments}
                fetchPosts={fetchPosts}
                pods={pods}
                contributionData={contribution}
              />
            )}
          </>
        )}
      </div>
      {modalOpened && (
        <EditModal
          isOpen={modalOpened}
          onModalClose={handleModalClose}
          item={item}
          contribution={contribution}
          fetchPosts={fetchPosts}
          isUnifiedCommunity={isUnifiedCommunity}
        />
      )}
    </StyledContainer>
  );
};

Post.propTypes = {
  item: PropTypes.shape({}),
  communityPosts: PropTypes.object,
  currentGroup: PropTypes.shape({}),
};

const mapStateToProps = ({ communityPosts, contributions, communitySections: { currentGroup } }) => ({
  communityPosts,
  contribution: contributions.activeContribution,
  currentGroup,
});

export default connect(mapStateToProps)(Post);
