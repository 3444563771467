import React from 'react';
import classes from './LoggedOutActions.module.scss';
import Button from 'components/FormUI/Button';
import { useMediaQuery } from '@mui/material';
import { theme } from 'styles/theme';

const LoggedOutActions = () => {
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.loggedOutActions}>
      <Button className={isMobileView ? classes.loginResponsiveButton : classes.loginButton} onClick={() => {}}>
        Login
      </Button>
      <Button className={isMobileView ? classes.signupResponsiveButton : classes.signupButton} onClick={() => {}}>
        Join Now
      </Button>
    </div>
  );
};

export default LoggedOutActions;
