import AcademyPage from 'pages/Collective/Pages/Academy/AcademyPage';
import AllPage from 'pages/Collective/Pages/All/AllPage';
import CoachesPage from 'pages/Collective/Pages/Coaches/CoachesPage';
import CommunitiesPage from 'pages/Collective/Pages/Communities/CommunitiesPage';
import MyJourneyPage from 'pages/Collective/Pages/Journey/MyJourneyPage';
import Services from 'pages/Collective/Pages/Services/Services';
import Signup from 'pages/Collective/Pages/Signup/Signup';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const CollectiveContainer = ({ match: { path } }) => {
  return (
    <>
      <Switch>
        <Route path={`${path}/all`} exact component={AllPage} />
        <Route path={`${path}/journey`} exact component={MyJourneyPage} />
        <Route path={[`${path}/communities`, `${path}/community`]} exact component={CommunitiesPage} />
        <Route path={`${path}/academy`} exact component={AcademyPage} />
        <Route path={`${path}/coaches`} exact component={CoachesPage} />
        <Route path={`${path}/services`} exact component={Services} />
        <Route path={`${path}/signup`} exact component={Signup} />
        <Redirect to={`${path}/all`} />
      </Switch>
    </>
  );
};

export default CollectiveContainer;
