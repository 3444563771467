import React from 'react';

const HamburgerMenu = ({ width = '32px', height = '32px', onClick }) => {
  return (
    <div style={{ cursor: 'pointer' }} onClick={onClick}>
      <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.4 8H2.6C2.048 8 2 8.447 2 9C2 9.553 2.048 10 2.6 10H15.4C15.952 10 16 9.553 16 9C16 8.447 15.952 8 15.4 8ZM15.4 12H2.6C2.048 12 2 12.447 2 13C2 13.553 2.048 14 2.6 14H15.4C15.952 14 16 13.553 16 13C16 12.447 15.952 12 15.4 12ZM2.6 6H15.4C15.952 6 16 5.553 16 5C16 4.447 15.952 4 15.4 4H2.6C2.048 4 2 4.447 2 5C2 5.553 2.048 6 2.6 6Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default HamburgerMenu;
