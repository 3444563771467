import React, { useState } from 'react';
import classes from './ServicesDropdown.module.scss';
import DownArrow from 'pages/Collective/Icons/DownArrow';
import { ClickAwayListener } from '@material-ui/core';
import Button from 'components/FormUI/Button';

const ServicesDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const DropdownOptions = ['Workshops', 'Group Courses', 'Communities', 'Memberships', '1:1'];

  const handleOnClick = () => {
    setShowDropdown(!showDropdown);
  };
  return (
    <>
      <div className={classes.container}>
        <Button className={classes.servicesDropdown} onClick={handleOnClick}>
          <span className={classes.title}>Services</span>
          <DownArrow />
        </Button>
        {showDropdown && (
          <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
            <div className={classes.navbarDropdown}>
              {DropdownOptions.map(item => (
                <div className={classes.dropdownItem}>{item}</div>
              ))}
            </div>
          </ClickAwayListener>
        )}
      </div>
    </>
  );
};

export default ServicesDropdown;
