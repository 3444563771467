import React from 'react';
import classes from './SessionsCard.module.scss';
import ShareIcon from 'pages/Collective/Icons/ShareIcon';
import Favorite from 'pages/Collective/Icons/Favorite';
import Button from 'components/FormUI/Button';
import LockedServiceIcon from 'pages/Collective/Icons/LockedServiceIcon';
import { Box, useMediaQuery } from '@mui/material';
import { theme } from 'styles/theme';

const SessionsCard = ({ item }) => {
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className={classes.card}>
      <div className={classes.hoverIcons}>
        <ShareIcon />
        <Favorite />
      </div>
      <img className={classes.image} src={item.image_url} />
      <div className={classes.lockedIcon}>
        <LockedServiceIcon />
      </div>
      <div className={classes.description}>
        <div className={classes.title}>{item.title}</div>
        <div className={classes.subTitle}>{item.subTitle}</div>
        <div className={classes.calender}>
          {item.calenderIcon}
          <div className={classes.date}> {item.date}</div>
        </div>
        <Button className={isMobileView ? classes.responsiveBtn : classes.enrollBtn} onClick={() => {}}>
          {item.btnText}
        </Button>
      </div>
    </div>
  );
};

export default SessionsCard;
