import React from 'react';

const CalenderMobileIcon = ({ fill = '#215C73' }) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1433_77354)">
        <rect x="5" y="5" width="38" height="38" rx="19" fill="white" shape-rendering="crispEdges" />
        <rect
          x="5.59375"
          y="5.59375"
          width="36.8125"
          height="36.8125"
          rx="18.4062"
          stroke="#DFE3E4"
          stroke-width="1.1875"
          shape-rendering="crispEdges"
        />
        <g clip-path="url(#clip0_1433_77354)">
          <path
            d="M32.3125 14.5H31.125V12.125H28.75V14.5H19.25V12.125H16.875V14.5H15.6875C14.3694 14.5 13.3244 15.5687 13.3244 16.875L13.3125 33.5C13.3125 34.8062 14.3694 35.875 15.6875 35.875H32.3125C33.6187 35.875 34.6875 34.8062 34.6875 33.5V16.875C34.6875 15.5687 33.6187 14.5 32.3125 14.5ZM32.3125 33.5H15.6875V21.625H32.3125V33.5ZM32.3125 19.25H15.6875V16.875H32.3125V19.25ZM20.4375 26.375H18.0625V24H20.4375V26.375ZM25.1875 26.375H22.8125V24H25.1875V26.375ZM29.9375 26.375H27.5625V24H29.9375V26.375ZM20.4375 31.125H18.0625V28.75H20.4375V31.125ZM25.1875 31.125H22.8125V28.75H25.1875V31.125ZM29.9375 31.125H27.5625V28.75H29.9375V31.125Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1433_77354"
          x="0.25"
          y="0.25"
          width="47.5"
          height="47.5"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.375" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1433_77354" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1433_77354" result="shape" />
        </filter>
        <clipPath id="clip0_1433_77354">
          <rect width="28.5" height="28.5" fill="white" transform="translate(9.75 9.75)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalenderMobileIcon;
