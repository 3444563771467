import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useAccount, useRouter, usePartnerCoach } from '../../hooks';
import Modal from 'components/UI/Modal';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { dataURLtoFile, getIpGlobal } from 'utils/utils';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { colors } from 'utils/styles/styles';
import { TemplateType } from 'helpers/constants/templateType';
import { determineColorToUse } from 'services/contributions.service';
import { ContributionType, UserRoles } from 'helpers/constants';
import { Typography } from '@material-ui/core';
import { ModalTermsAndConditions } from './TermsAndConditions';
import SignatureCanvas from 'react-signature-canvas';
import { useStyles } from 'pages/ContributionView/components/PurchaseBlock/hooks/useStyles';
import { getThemedColors } from 'services/contributions.service';
import Loader from 'components/UI/Loader';
import { getSingleContract } from 'services/contributions.service';
import CreateCustomWaiver from 'pages/CreateContribution/components/CreateCustomWaiver';
import { setSignedContract } from 'services/contributions.service';
import { saveSignoffData } from 'services/contributions.service';

const StyledDiv = styled.span`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;

const StyledLink = styled.a`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;

export const AgreeToTermsAndConditions = ({ showTerms, onCancel, actionButtonTitle, onSubmitClick, agreeButton }) => {
  const { serviceProviderName, customToS } = useContribution();
  const [showTermsAndCond, setShowTermsAndCond] = useState(false);
  const [clientSignature, setClientSignature] = useState(null);
  const contribution = useContribution();
  const { currentRole } = useAccount();
  const [waiverData, setWaiverData] = useState(null);
  const [isShowWaiver, setIsShowWaiver] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [isSigned, setIsSigned] = useState(null);
  const {
    newThemedBackgroundColor: themedBackgroundColor,
    newThemedTextColor: themedColor,
    newThemedCardColor,
  } = getThemedColors(contribution, false);
  const canvasRef = useRef();
  let colorToUse = determineColorToUse(contribution.activeContribution);
  const isCoach = currentRole === UserRoles.cohealer;
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  const type = contribution?.type;
  // const shouldChangeColor = type != ContributionType.contributionOneToOne;
  const btnColor = colorToUse?.PrimaryColorCode;
  const [disableBtn, setDisableBtn] = useState(true);
  const styledProps = {
    cardBackgroundColor: newThemedCardColor,
    color: themedColor,
    backgroundColor: themedBackgroundColor,
  };
  const classNames = useStyles(styledProps);

  const disableJoinBtn =
    disableBtn && contribution?.isElectronicSignatureActive && contribution?.customWaiverId === null;
  const getIP = async () => {
    const res = await fetch('https://api.ipify.org/?format=json');
    const data = await res.json();
    return data.ip;
  };

  const clearSign = () => {
    if (
      canvasRef?.current
      // && formRef?.current
    ) {
      canvasRef.current.clear();
      setDisableBtn(true);
      // formRef.current.setFieldValue(null);
    }
  };

  const saveSignature = async val => {
    let returnval = false;
    const formData = new FormData();
    const sign = dataURLtoFile(val);
    formData.append('file', sign);
    const userIP = await getIpGlobal();
    await saveSignoffData(formData, contribution?.id, userIP)
      .then(res => {
        if (res === 'Signoff data succesfully saved.') {
          returnval = true;
        }
      })
      .catch(err => {});
    return returnval;
  };

  const submitUserSignature = async val => {
    let isSignedSubmitted = false;
    setLoadingPayment(true);
    const formData = new FormData();
    const sign = dataURLtoFile(val);
    formData.append('file', sign);
    const userIP = await getIpGlobal();
    const details = {
      contributionId: contribution.id,
      contractId: waiverData.id,
      ipAddress: userIP,
    };
    try {
      setSignedContract(formData, details);
      isSignedSubmitted = true;
    } catch (e) {
      isSignedSubmitted = false;
    }
    setIsShowWaiver(false);
    setLoadingPayment(false);
    return isSignedSubmitted;
  };

  const getWaiverForm = async () => {
    setLoadingPayment(true);
    try {
      getSingleContract(contribution?.id).then(data => {
        setWaiverData({
          ...waiverData,
          formName: data?.formName,
          formDescription: data?.formDescription,
          formText: data?.formText,
          description: data?.formDescription,
          templateId: data.id,
          id: data.id,
          completeDescription: data?.formText,
        });
        setIsShowWaiver(true);
        setLoadingPayment(false);
      });
    } catch (e) {
      setLoadingPayment(false);
    }
    setLoadingPayment(false);
  };

  const isSignSaved = async signature => {
    let isSignedSubmitted = null;
    if (isSigned !== null) {
      isSignedSubmitted = await submitUserSignature(signature);
    } else {
      isSignedSubmitted = await saveSignature(signature);
    }
    return isSignedSubmitted;
  };

  const saveUserSignature = async values => {
    setLoadingPayment(true);
    const formData = new FormData();
    const sign = dataURLtoFile(values.clientSignature);
    setWaiverData({
      ...waiverData,
      clientName: values.clientName,
    });
    formData.append('file', sign);
    setIsSigned(true);
    setIsShowWaiver(false);
    setLoadingPayment(false);
    submitUserSignature(values.clientSignature);
  };

  return (
    <>
      <Modal
        // extraFooterElements={agreeButton}
        isOpen={showTerms}
        onCancel={() => {
          setDisableBtn(true);
          onCancel();
        }}
        title="Terms and Conditions"
        onSubmit={() => {
          if (contribution?.customWaiverId != null && contribution?.isElectronicSignatureActive) {
            getWaiverForm();
          } else {
            onSubmitClick();
          }
        }}
        submitTitle={actionButtonTitle}
        disableSubmitClick={disableJoinBtn}
      >
        <>
          {customToS === null &&
            contribution?.isElectronicSignatureActive === false &&
            contribution?.customWaiverId === null && (
              <Grid item>
                <span style={{ color: themedColor }} className="mr-1">
                  By clicking this {actionButtonTitle}, I agree to the
                </span>
                <StyledLink
                  style={{ color: btnColor }}
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    setShowTermsAndCond(true);
                  }}
                >
                  Terms and Conditions.
                </StyledLink>
              </Grid>
            )}
          {customToS && (
            <Grid item>
              <span className="mr-1">
                By clicking {actionButtonTitle}, I also agree to {serviceProviderName}'s
              </span>
              <StyledLink color={colorToUse?.PrimaryColorCode} href={customToS}>
                Terms and Conditions.
              </StyledLink>
            </Grid>
          )}
          {contribution?.isElectronicSignatureActive && contribution?.customWaiverId === null && (
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              md={12}
              xs={12}
              style={{ padding: '5px 15px' }}
            >
              <div style={{ position: 'relative' }}>
                <div
                  type="button"
                  onClick={clearSign}
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: '10px',
                    top: '8px',
                    // color: colorToUse.PrimaryColorCode,
                    fontWeight: 'bold',
                  }}
                >
                  Clear
                </div>
                <SignatureCanvas
                  ref={canvasRef}
                  penColor="black"
                  canvasProps={{ height: '270px', className: classNames.inputField }}
                  onEnd={() => {
                    setDisableBtn(false);
                    if (canvasRef?.current) {
                      const val = canvasRef?.current?.getTrimmedCanvas().toDataURL('image/png');
                      // setFieldValue(ACCOUNT_FORM_FIELDS.signature, val);
                      setClientSignature(val);
                    }
                  }}
                />
              </div>
              {/* <p
              style={{
                marginBottom: '0px',
                fontSize: '12px',
                marginLeft: '5px',
                color: colors.gold,
              }}
            >
              Sign Here To Join
            </p> */}
            </Grid>
          )}
          {customToS ? (
            contribution?.isElectronicSignatureActive && contribution?.customWaiverId === null ? (
              <Typography variant="caption">
                By signing and clicking join,I agree to the{' '}
                <StyledLink
                  href="/"
                  color={colorToUse?.PrimaryColorCode}
                  onClick={e => {
                    e.preventDefault();
                    setShowTermsAndCond(true);
                  }}
                >
                  Terms and Conditions
                </StyledLink>
                , I also agree to {serviceProviderName}
                {"'"}s{' '}
                <StyledLink color={colorToUse?.PrimaryColorCode} href={customToS}>
                  Terms and Conditions
                </StyledLink>
                , and I'm at least 18 years old.
              </Typography>
            ) : (
              <Typography variant="caption">
                By clicking join,I agree to the{' '}
                <StyledLink
                  href="/"
                  color={colorToUse?.PrimaryColorCode}
                  onClick={e => {
                    e.preventDefault();
                    setShowTermsAndCond(true);
                  }}
                >
                  Terms and Conditions
                </StyledLink>
                , I also agree to {serviceProviderName}
                {"'"}s{' '}
                <StyledLink color={colorToUse?.PrimaryColorCode} href={customToS}>
                  Terms and Conditions
                </StyledLink>
                , and I'm at least 18 years old.
              </Typography>
            )
          ) : (
            contribution?.customWaiverId != null && (
              <>
                <Typography variant="caption">
                  By {contribution?.customWaiverId != null ? 'clicking' : 'signing and clicking'} join, I agree to the{' '}
                  <StyledLink
                    color={colorToUse?.PrimaryColorCode}
                    href="/"
                    onClick={e => {
                      e.preventDefault();
                      setShowTermsAndCond(true);
                    }}
                  >
                    Terms and Conditions
                  </StyledLink>
                  , I also agree to {serviceProviderName}
                  {"'"}s{' '}
                  <StyledDiv color={colorToUse?.PrimaryColorCode}>
                    {contribution?.customWaiverTemplateName?.trim()}
                  </StyledDiv>
                  , and I'm at least 18 years old.
                </Typography>
                <br />
                {customToS && (
                  <Typography variant="caption">
                    By {contribution?.customWaiverId != null ? 'clicking' : 'signing and clicking'} Reserve, I also
                    agree to {serviceProviderName}
                    {"'"}s{' '}
                    <StyledLink color={colorToUse?.PrimaryColorCode} href={customToS}>
                      Terms and Conditions
                    </StyledLink>
                    , and I'm at least 18 years old.
                  </Typography>
                )}
              </>
            )
          )}
          <ModalTermsAndConditions showTerms={showTermsAndCond} onCancel={() => setShowTermsAndCond(false)} />
        </>
      </Modal>
      {isShowWaiver && (
        <CreateCustomWaiver
          isOpen={isShowWaiver}
          onSubmit={values =>
            saveUserSignature(values).then(async () => {
              isSignSaved(clientSignature);
              onSubmitClick();
            })
          }
          contribution={contribution}
          waiverData={waiverData}
          isDarkModeAllowed={true}
          onCancel={() => setIsShowWaiver(false)}
        />
      )}
      {loadingPayment && <Loader />}
    </>
  );
};

AgreeToTermsAndConditions.propTypes = {
  showTerms: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  actionButtonTitle: PropTypes.string,
  agreeButton: PropTypes.object.isRequired,
};
