import React from 'react';
import classes from './CoachCard.module.scss';
// import FavoriteEmptyIcon from 'pages/Collective/Icons/FavoriteEmptyIcon';
import Favorite from 'pages/Collective/Icons/Favorite';

const CoachCard = ({ item }) => {
  return (
    <div className={classes.card}>
      <div className={classes.profile}>
        <img className={classes.image} src={item.img_url} alt="" />
        <div className={classes.icon}>
          <Favorite />
        </div>
      </div>
      <div className={classes.coachName}>{item.coachName}</div>
      <div className={classes.clients_services}>
        <div className={classes.clientsCount}>
          {item.numberOfClients}
          <span className={classes.clients}>Clients</span>
        </div>
        <div className={classes.servicesCount}>
          {item.numberOfServices}
          <span className={classes.services}>Services</span>
        </div>
      </div>
      <div className={classes.tags}>
        {item.tags.map((tag, index) => (
          <div className={classes.tag}>{tag}</div>
        ))}
      </div>
    </div>
  );
};

export default CoachCard;
