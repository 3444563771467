import React from 'react';

const BackwardSvg = () => {
  return (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.19398 10.7081L0.897949 6.00036L6.19398 1.2926"
        stroke="#666666"
        stroke-width="1.35002"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BackwardSvg;
