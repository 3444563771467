import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import './CustomBigCalendar.scss';
import BackwardSvg from 'pages/Collective/Icons/BackwardSvg';
import ForwardSvg from 'pages/Collective/Icons/ForwardSvg';
const localizer = momentLocalizer(moment);

const CustomBigCalendar = () => {
  const [events, setEvents] = useState([
    {
      start: moment().toDate(),
      end: moment().add(1, 'days').toDate(),
      title: 'Event',
    },
  ]);

  const goToBack = toolbar => {
    if (toolbar?.view === 'day') {
      toolbar.date.setDate(toolbar.date.getDate() - 1);
    } else if (toolbar?.view === 'week') {
      toolbar.date.setDate(toolbar.date.getDate() - 7);
    } else {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    }
    toolbar.onNavigate('prev');
  };

  const goToNext = toolbar => {
    if (toolbar?.view === 'day') {
      toolbar.date.setDate(toolbar.date.getDate() + 1);
    } else if (toolbar?.view === 'week') {
      toolbar.date.setDate(toolbar.date.getDate() + 7);
    } else {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    }
    toolbar.onNavigate('next');
  };

  const CustomToolbar = toolbar => {
    return (
      <span>
        <div className="calender-custom-header">
          <div className="back-Button" onClick={() => goToBack(toolbar)}>
            <BackwardSvg />
          </div>
          <div className="current-Button">{toolbar?.label}</div>
          <div className="next-Button" onClick={() => goToNext(toolbar)}>
            <ForwardSvg />
          </div>
        </div>
      </span>
    );
  };

  const CustomEvent = ({ event }) => {
    const startTime = moment(event.start).format('h:mm A');

    return (
      <div className="custom-event" onClick={handleEventDetails}>
        <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4" cy="4.63574" r="4" fill="#E1BB62" />
        </svg>
        <span className="event-time">{startTime}</span>
        <span className="event-title">{event.title}</span>
      </div>
    );
  };

  const handleEventDetails = () => {};

  return (
    <div className="Academy-calender-container">
      <Calendar
        className="academy-calendar"
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={events}
        style={{ height: '390px' }}
        views={''}
        components={{
          toolbar: CustomToolbar,
          event: CustomEvent,
        }}
      />
    </div>
  );
};

export default CustomBigCalendar;
