import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { Box, TextField } from '@mui/material';
import CustomButton from 'components/FormUI/Button';
import Logo from 'components/FormUI/Logo';
import Button from 'components/FormUI/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import { getImprovedBioText } from 'services/openAI.service';
import { useAccount } from 'hooks/index';
import { editUserProfile, getUserProfile } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { useDispatch } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const StyledModal = styled(Modal)`
  padding: ${({ mobileView }) => (mobileView ? '20px 5px !important' : '')};

  .cross-icon-hide {
    padding-top: 0px !important;
  }

  .body {
    padding: {({ mobileView }) => (mobileView ? '0px 4px !important' : '0px 16px !important')};
    max-height: 95vh !important;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const AddBioTitle = styled.p`
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? 'white' : 'rgba(0, 0, 0, 0.87)')};
  font-style: normal;
  line-height: normal;
  margin-top: 15px;
  margin-bottom: 24px;
  font-family: Avenir;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.1899999976158142px;
  text-align: left;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-bottom: auto;
  margin-top: 18px;
  margin-right: -4px;
  ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'color: #fff' : 'color: black')};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px 10px 10px;
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#fff' : '#000')};
  padding: ${({ mobileView }) => (mobileView ? '0px 5px 10px 5px' : '0px 10px 10px 10px')};
`;

const Subtitle = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#fff' : '#000')};
`;

const FooterContainer = styled(Box)`
  margin: 10px 0px;
  display: flex;
  width: 100%;
  justify-content: right;
  gap: 16px;
  margin-top: 5px !important;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  margin-top: 10px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 0px 24px 10px 24px;
  background-color: ${({ color }) => color};
`;

const CancelButton = styled(CustomButton)`
  height: 48px;
  padding: 7px 24px 7px 24px;
  gap: 0px;
  border-radius: 5px;
  justify: space-between;
  opacity: 0px;
  border: ${({ isdarkthemeenabled }) =>
    isdarkthemeenabled ? '1px solid white' : '1px solid var(--cohere-greys-000000, #000)'};
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? 'white' : '#000')};
  background-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#333' : 'white')};
  &:hover {
    background-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#333' : 'white')};
  }
`;

const NextButton = styled(CustomButton)`
  height: 48px;
  padding: 10px 24px 10px 24px;
  gap: 0px;
  border-radius: 5px;
  justify: space-between;
  opacity: 0px;
  display: flex;
  padding: 12px 32px;
  align-items: center;
  align-self: stretch;
  background: var(--Cohere-Secondary-Brown, #c9b382);
`;

const Instructions = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#fff' : '#000')};
  margin-bottom: 10px;
  width: 100%;
`;

const ImproveTextActions = styled.div`
  gap: 10px;
  align-items: center;
  display: none;
  bottom: 33px;
  right: 12px;
  z-index: 3;
  position: relative;

  &.expanded {
    .actions-container {
      gap: 10px;
      align-items: center;
      display: none;
      right: 1px;
      border-radius: 4px;
      opacity: 0px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 12px 0px rgba(53, 71, 90, 0.2);
      padding: 0px 2px 0px 10px;
    }
  }
`;

const ImproveTextContainer = styled.div`
  width: 100%;

  .magic-write-button {
    min-width: 128px;
    width: 138px;
    height: 35px;
    min-height: 35px;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    line-height: 22px;
    padding: 4px;
    border-radius: 4px;
    opacity: 0px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 12px 0px rgba(53, 71, 90, 0.2);

    &:hover {
      background: rgba(245, 245, 245, 1);
    }

    img {
      height: 24px;
      width: 24px;
      margin-right: 5px;
      margin-bottom: 3px;
    }

    &.expanded {
      box-shadow: unset;
      margin: 2px;
    }
  }

  .disabled-icon {
    opacity: 30%;
    cursor: default;
    pointer-events: none;
  }

  &:hover,
  &.loading {
    .improve-text-actions {
      display: flex !important;

      div {
        display: flex !important;
      }
    }
  }

  .typing-animation {
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #888;
    display: flex;
    align-items: center;
    background: inherit;
    background-color: inherit;
  }

  .typing-dot {
    animation: blink 1.5s infinite step-start;
    margin: 0 2px;
    opacity: 0;
  }

  .typing-dot:nth-child(1) {
    animation-delay: 0s;
  }

  .typing-dot:nth-child(2) {
    animation-delay: 0.3s;
  }

  .typing-dot:nth-child(3) {
    animation-delay: 0.6s;
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const BioTextarea = styled(TextField)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;

  textarea {
    height: ${({ mobileView }) => (mobileView ? '200px !important' : '')};
  }

  & .MuiInputBase-input::placeholder {
    color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#FFFFFF' : '#000000')};
    font-family: Avenir;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#FFFFFF' : '')};
  }

  .MuiInputBase-root {
    padding: 0px;
  }

  & .MuiInputBase-root:hover {
    border-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#FFFFFF' : '')};
  }

  & .MuiInputBase-input {
    color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#FFFFFF' : '')};
    caret-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#FFFFFF' : '')};
    font-family: Avenir;
    padding: 10px;
  }

  & .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: #116582 !important;
      border-width: 1px !important;
    }
  }
`;

const AddBioModal = ({
  isOpen,
  handleClose,
  handleSubmit,
  isdarkthemeenabled,
  mobileView,
  currentBioIndex,
  setCurrentBioIndex,
  bios,
  setBios,
  updateProfileBio,
  setUpdateProfileBio,
}) => {
  const { user } = useAccount();
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user?.id) {
        const userProfile = await getUserProfile(user.id);
        setCurrentUser(userProfile);
      }
    };
    fetchUserProfile();
  }, [user]);

  const handleCheckboxChange = event => {
    setUpdateProfileBio(event.target.checked);
  };

  const handleImproveTextClick = async () => {
    const newBio = await getImprovedBioTextAPI();
    if (newBio === false) return;
    let newBios = [];
    if (bios[currentBioIndex]?.text?.trim()) {
      newBios = [...bios.slice(0, currentBioIndex + 1), { text: newBio, undoStack: [], redoStack: [] }];
    } else {
      newBios = [
        ...bios.slice(0, currentBioIndex),
        {
          ...bios[currentBioIndex],
          text: newBio,
          undoStack: [],
          redoStack: [],
        },
        ...bios.slice(currentBioIndex + 1),
      ];
    }
    setBios(newBios);
    setCurrentBioIndex(newBios.length - 1);
  };

  const handleForward = () => {
    if (currentBioIndex < bios.length - 1) {
      setCurrentBioIndex(currentBioIndex + 1);
    }
  };

  const handleBackward = () => {
    if (currentBioIndex > 0) {
      setCurrentBioIndex(currentBioIndex - 1);
    }
  };

  const handleUndo = () => {
    if (currentBioIndex >= 0 && bios[currentBioIndex]?.undoStack?.length > 0) {
      const currentUndoStack = [...bios[currentBioIndex]?.undoStack];
      const lastAction = currentUndoStack.pop();
      const newRedoStack = [...bios[currentBioIndex]?.redoStack, bios[currentBioIndex]?.text];
      const newBios = [
        ...bios.slice(0, currentBioIndex),
        {
          ...bios[currentBioIndex],
          text: lastAction,
          undoStack: currentUndoStack,
          redoStack: newRedoStack,
        },
        ...bios.slice(currentBioIndex + 1),
      ];
      setBios(newBios);
    }
  };

  const handleRedo = () => {
    if (currentBioIndex >= 0 && bios[currentBioIndex]?.redoStack?.length > 0) {
      const currentRedoStack = [...bios[currentBioIndex]?.redoStack];
      const lastAction = currentRedoStack.pop();
      const newUndoStack = [...bios[currentBioIndex]?.undoStack, bios[currentBioIndex]?.text];
      const newBios = [
        ...bios.slice(0, currentBioIndex),
        {
          ...bios[currentBioIndex],
          text: lastAction,
          redoStack: currentRedoStack,
          undoStack: newUndoStack,
        },
        ...bios.slice(currentBioIndex + 1),
      ];
      setBios(newBios);
    }
  };

  const handleBioChange = event => {
    const newText = event.target.value;
    const currentBio = bios[currentBioIndex];
    const newUndoStack = [...currentBio?.undoStack, currentBio?.text];
    const newBios = [
      ...bios.slice(0, currentBioIndex),
      { ...currentBio, text: newText, undoStack: newUndoStack, redoStack: [] },
      ...bios.slice(currentBioIndex + 1),
    ];
    setBios(newBios);
  };

  const handleBioSubmit = async () => {
    if (updateProfileBio) {
      const updatedUser = {
        ...user,
        bio: bios[currentBioIndex]?.text,
      };
      setLoading(true);
      await editUserProfile(user.id, updatedUser)
        .then(async res => {
          dispatch(updateUser(updatedUser));
          handleSubmit(bios[currentBioIndex]?.text);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    handleSubmit(bios[currentBioIndex]?.text);
  };

  const getImprovedBioTextAPI = async () => {
    const data = {
      coachName: `${currentUser?.firstName} ${currentUser?.lastName}`,
      coachBio: bios[currentBioIndex]?.text,
      businessType: currentUser?.customBusinessType,
      occupation: currentUser?.occupation,
      profession: currentUser?.profession,
      businessTagline: currentUser?.profilePageViewModel?.tagLine,
      businessSubTagline: currentUser?.profilePageViewModel?.subtagLine,
      bioHeader: currentUser?.profilePageViewModel?.bioHeader,
      minLimit: 400,
      maxLimit: 450,
    };

    try {
      setLoading(true);
      const res = await getImprovedBioText(data);
      setLoading(false);
      return res.CoachBio;
    } catch (error) {
      setLoading(false);
      return false;
    }
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onCancel={handleClose}
      disableFooter
      CrossIconHide
      disableOverlayClick
      isCreatingContribution
      mobileView={mobileView}
    >
      <Container isdarkthemeenabled={isdarkthemeenabled} mobileView={mobileView}>
        <HeaderContainer>
          <AddBioTitle isdarkthemeenabled={isdarkthemeenabled} mobileView={mobileView}>
            Your Bio Can Be Used to Auto-Generate Messaging
          </AddBioTitle>
          <StyledCloseIcon isDarkThemeEnabled={isdarkthemeenabled} onClick={handleClose} />
        </HeaderContainer>
        <Subtitle isdarkthemeenabled={isdarkthemeenabled}>
          We will craft content tailored just for you based on the information you’ve provided in your bio. You can add
          any additional details below.
        </Subtitle>
        <Subtitle isdarkthemeenabled={isdarkthemeenabled}>
          If you don’t have a bio, please write a new one below.
        </Subtitle>
        <Instructions isdarkthemeenabled={isdarkthemeenabled}>
          <p style={{ fontSize: '16px', fontWeight: 800, marginBottom: '5px' }}>What to include:</p>
          <ul>
            <li>What type of work do you do</li>
            <li>Areas of expertise</li>
            <li>Your coaching style</li>
            <li>Certifications (if applicable)</li>
          </ul>
        </Instructions>
        <Subtitle isdarkthemeenabled={isdarkthemeenabled}>
          Anything else that could help us customize your messaging by describing the type of work you do! <br />
          Don’t worry about making this perfect, a rough outline is all we need.
        </Subtitle>
        <ImproveTextContainer className={loading ? 'loading' : ''}>
          <BioTextarea
            value={bios[currentBioIndex]?.text}
            onChange={handleBioChange}
            placeholder="Enter your bio here"
            multiline
            rows={6}
            variant="outlined"
            isdarkthemeenabled={isdarkthemeenabled}
            mobileView={mobileView}
            sx={{
              '& .MuiFormHelperText-root': {
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '0.75rem !important',
                fontWeight: 'normal !important',
                fontFamily: 'Avenir',
                lineHeight: '1.5rem',
                letterSpacing: '0.08px',
                marginLeft: '0px',
                marginRight: '0px',
                textAlign: 'end',
              },
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'right', position: 'sticky', height: '0px' }}>
            <ImproveTextActions
              className={
                bios?.length >= 2 ||
                bios[currentBioIndex]?.undoStack?.length > 0 ||
                bios[currentBioIndex]?.redoStack?.length > 0
                  ? 'improve-text-actions expanded'
                  : 'improve-text-actions'
              }
            >
              <div className="actions-container">
                {bios?.length >= 2 && (
                  <>
                    <ArrowBackIosIcon
                      onClick={handleBackward}
                      className={currentBioIndex === 0 ? 'disabled-icon' : ''}
                      style={{ cursor: 'pointer', width: '14px', height: '14px' }}
                    />
                    <label style={{ marginBottom: '0px' }}>
                      {currentBioIndex + 1}/{bios.length}
                    </label>
                    <ArrowForwardIosIcon
                      onClick={handleForward}
                      className={currentBioIndex === bios.length - 1 ? 'disabled-icon' : ''}
                      style={{ cursor: 'pointer', width: '14px', height: '14px' }}
                    />
                  </>
                )}
                {(bios[currentBioIndex]?.undoStack?.length > 0 || bios[currentBioIndex]?.redoStack?.length > 0) && (
                  <>
                    <UndoIcon
                      onClick={handleUndo}
                      className={bios[currentBioIndex]?.undoStack.length === 0 ? 'disabled-icon' : ''}
                      style={{ cursor: 'pointer', marginBottom: '2px' }}
                    />
                    <RedoIcon
                      onClick={handleRedo}
                      className={bios[currentBioIndex]?.redoStack.length === 0 ? 'disabled-icon' : ''}
                      style={{ cursor: 'pointer', marginBottom: '2px' }}
                    />
                  </>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className={
                    bios?.length > 1 ||
                    bios[currentBioIndex]?.undoStack?.length > 0 ||
                    bios[currentBioIndex]?.redoStack?.length > 0
                      ? 'expanded magic-write-button'
                      : 'magic-write-button'
                  }
                  style={{
                    color: 'black',
                    letterSpacing: '0.5px',
                    cursor: loading ? 'default' : 'pointer',
                  }}
                  onClick={handleImproveTextClick}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <div className="typing-animation">
                        <span className="typing-dot">.</span>
                        <span className="typing-dot">.</span>
                        <span className="typing-dot">.</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <Logo isai />
                      {!bios[currentBioIndex]?.text?.trim() ? 'Magic Write' : 'Improve Text'}
                    </>
                  )}
                </Button>
              </div>
            </ImproveTextActions>
          </div>
        </ImproveTextContainer>
        <StyledFormControlLabel
          control={<Checkbox color="primary" checked={updateProfileBio} onChange={handleCheckboxChange} />}
          label="Update Your Bio in Account Settings"
        />
        <StyledLine style={{ margin: '0px 24px 10px 24px', width: '100%' }} color="#e7e7e7" />
        <FooterContainer mt={2}>
          <CancelButton onClick={handleClose} isdarkthemeenabled={isdarkthemeenabled}>
            Cancel
          </CancelButton>
          <NextButton
            onClick={handleBioSubmit}
            disabled={!bios[currentBioIndex]?.text?.trim()}
            isdarkthemeenabled={isdarkthemeenabled}
          >
            Next
          </NextButton>
        </FooterContainer>
      </Container>
    </StyledModal>
  );
};

export default AddBioModal;
