import React from 'react';

const FacebookIcon = () => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.1495 9.05546C18.1495 4.05383 14.0956 0 9.09475 0C4.09387 0 0.0400391 4.05383 0.0400391 9.05546C0.0400391 13.5741 3.35132 17.3201 7.67983 17.9992V11.6725H5.38125V9.05471H7.67983V7.06024C7.67983 4.79034 9.03212 3.53691 11.0998 3.53691C12.0906 3.53691 13.1267 3.7135 13.1267 3.7135V5.94189H11.9857C10.8613 5.94189 10.5104 6.63992 10.5104 7.35606V9.05546H13.0218L12.6203 11.6732H10.5104V18C14.8389 17.3208 18.1502 13.5749 18.1502 9.05546H18.1495Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookIcon;
