import React from 'react';

const JourneySvgIcon = ({ width = '20', height = '20' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7205 11.826C14.3437 11.826 15.9695 11.8462 17.5927 11.8184C18.9462 11.7932 19.8082 10.9601 19.778 9.77609C19.7503 8.64004 18.916 7.84985 17.6179 7.83471C15.2032 7.80694 12.786 7.80189 10.3714 7.85238C10.0059 7.85995 9.54966 8.14018 9.30768 8.43555C8.33224 9.62966 7.19548 10.0816 5.92513 9.67005C4.67494 9.2636 3.82804 8.15532 3.80284 6.88043C3.77763 5.56261 4.63965 4.3887 5.94025 3.97467C7.20556 3.57074 8.33476 4.03274 9.32029 5.2218C9.58242 5.53484 10.1017 5.79235 10.51 5.79992C12.9247 5.85294 15.3393 5.79992 17.7565 5.83526C19.8612 5.86556 21.5323 7.38029 21.7692 9.3974C22.0414 11.72 20.1183 13.7952 17.6103 13.8204C14.3614 13.8532 11.1124 13.8078 7.86594 13.8431C6.60063 13.8558 5.77641 14.7318 5.80414 15.8779C5.83187 16.9711 6.6334 17.741 7.83569 17.8269C8.00205 17.8395 8.1684 17.8319 8.33476 17.8319C10.5427 17.8319 12.7482 17.8193 14.9562 17.8395C15.5712 17.8445 15.9896 17.7436 16.3778 17.1478C17.1491 15.9612 18.4144 15.5851 19.6494 15.984C20.9021 16.3879 21.7516 17.4987 21.7793 18.7711C21.807 20.0863 20.945 21.2628 19.6444 21.6793C18.4093 22.0757 17.1491 21.7021 16.3753 20.5105C15.9846 19.9096 15.5586 19.8187 14.9486 19.8238C12.6575 19.844 10.3688 19.8465 8.07766 19.8263C5.68568 19.8061 3.76503 18.0162 3.79527 15.8779C3.83056 13.5124 5.5899 11.8487 8.09783 11.8311C9.63787 11.8184 11.1804 11.8311 12.7205 11.8285V11.826ZM7.9466 6.81731C7.9466 6.15841 7.41476 5.62573 6.7569 5.62573C6.09904 5.62573 5.56721 6.15841 5.56721 6.81731C5.56721 7.47622 6.09904 8.0089 6.7569 8.0089C7.41476 8.0089 7.9466 7.47622 7.9466 6.81731ZM20.0124 18.7736C20.0124 18.1147 19.4806 17.582 18.8227 17.582C18.1648 17.582 17.633 18.1147 17.633 18.7736C17.633 19.4325 18.1648 19.9652 18.8227 19.9652C19.4806 19.9652 20.0124 19.4325 20.0124 18.7736Z"
        fill="white"
      />
    </svg>
  );
};

export default JourneySvgIcon;
