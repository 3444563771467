import React from 'react';

const NotificationIcon = () => {
  return (
    <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.02564 22C10.2667 22 11.2821 20.9846 11.2821 19.7436H6.76923C6.76923 20.9846 7.78462 22 9.02564 22ZM15.7949 15.2308V9.58974C15.7949 6.12615 13.9559 3.22667 10.7179 2.45949V1.69231C10.7179 0.755897 9.96205 0 9.02564 0C8.08923 0 7.33333 0.755897 7.33333 1.69231V2.45949C4.10667 3.22667 2.25641 6.11487 2.25641 9.58974V15.2308L0 17.4872V18.6154H18.0513V17.4872L15.7949 15.2308ZM13.5385 16.359H4.51282V9.58974C4.51282 6.7918 6.21641 4.51282 9.02564 4.51282C11.8349 4.51282 13.5385 6.7918 13.5385 9.58974V16.359Z"
        fill="white"
      />
    </svg>
  );
};

export default NotificationIcon;
