import React from 'react';

const Favorite = ({ width = '28', height = '29' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.829102" width="28" height="28" rx="14" fill="white" />
      <g clip-path="url(#clip0_209_22824)" transform="scale(0.8)" transform-origin="center">
        <path
          d="M18.5 5.8291C16.76 5.8291 15.09 6.6391 14 7.9191C12.91 6.6391 11.24 5.8291 9.5 5.8291C6.42 5.8291 4 8.2491 4 11.3291C4 15.1091 7.4 18.1891 12.55 22.8691L14 24.1791L15.45 22.8591C20.6 18.1891 24 15.1091 24 11.3291C24 8.2491 21.58 5.8291 18.5 5.8291ZM14.1 21.3791L14 21.4791L13.9 21.3791C9.14 17.0691 6 14.2191 6 11.3291C6 9.3291 7.5 7.8291 9.5 7.8291C11.04 7.8291 12.54 8.8191 13.07 10.1891H14.94C15.46 8.8191 16.96 7.8291 18.5 7.8291C20.5 7.8291 22 9.3291 22 11.3291C22 14.2191 18.86 17.0691 14.1 21.3791Z"
          fill="#E1BB62"
        />
      </g>
      <defs>
        <clipPath id="clip0_209_22824">
          <rect width="24" height="24" fill="white" transform="translate(2 2.8291)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Favorite;
