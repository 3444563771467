import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { mdiHeart, mdiTabletIpad, mdiRun, mdiAccountOutline } from '@mdi/js';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { replaceBreakToBr, replaceHtmlEscapedSpaceToNormal, wrapText, wrapTextWithColoredLinks } from 'utils/utils';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import TextBlock from './TextBlock';
import PurchaseBlock from './PurchaseBlock/PurchaseBlock';
import CustomPurchaseBlock from './PurchaseBlock/CustomPurchaseBlock';
import Icon from 'components/UI/Icon';
import {
  fetchClientContribution,
  fetchCohealerContribution,
  fetchContributionTimes,
  resetContribution,
  firstTimeRedirect,
} from 'actions/contributions';
import PaymentCard from './PaymentCard';
import { ContributionType, ContributionThemedColors } from 'helpers/constants';
import { getThemedColors } from 'services/contributions.service';
import { BsFillHeartFill, BsFillPersonFill, BsFillQuestionCircleFill, BsFillLightbulbFill } from 'react-icons/bs';
import { BiSolidMobile } from 'react-icons/bi';
import { FaMobileButton } from 'react-icons/fa6';
import LeadMagnetPurchaseBlock from 'pages/LeadMagnetView/components/LeadMagnetPurchaseBlock';
import useLeadMagnetTheme from 'hooks/useLeadMagnetTheme';

const DetailMainContainer = styled.div``;
const DetailH1 = styled.h1`
  color: ${props => props.themedColor};
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  padding: 0px 0px 0rem 0px;
  margin: 0px;
`;

const DetailSideIcon = styled.img`
  width: 35px;
  height: 35px;
  // background-color: ${props => props.themedColor};
  border-radius: 40px;
`;
const DetailRightTxtMain = styled.div`
  padding: 0px 0px 0px 1rem;
  ${({ mobileViewTem2 }) =>
    mobileViewTem2 &&
    css`
      padding: 0px;
    `};
`;
const DetailRighth1 = styled.h4`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: ${props => props.themedColor};
  ${({ mobileViewTem2 }) =>
    mobileViewTem2 &&
    css`
      font-size: 24px;
      padding: 12px 0px;
    `};
`;
const DetailP = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: ${props => props.themedColor};

  max-width: 100%;

  ${({ mobileViewTem2 }) =>
    mobileViewTem2 &&
    css`
      max-width: 100%;
    `};
`;
const DetailIconDiv = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '')};
`;
const DetailSideIconMain = styled.div`
  display: flex;
  // align-items: baseline;
  align-items: flex-start;
  padding: 2rem 0rem;
  ${({ mobileView }) =>
    mobileView &&
    css`
      display: block;
      text-align: center;
    `};
`;
const BorderDiv = styled.div`
  border: 1px solid #000000;
  opacity: 0.05;
  margin: '2rem 0px' // margin: ${props => props.margin};
    ${({ mobileViewTem2 }) =>
      mobileViewTem2 &&
      css`
        margin: 0px;
      `};
`;

const StyledBsFillHeartFill = styled(BsFillHeartFill)`
  color: ${({ color }) => color};
  height: 30px;
  width: 30px;

  ${({ mobileView }) =>
    mobileView &&
    `
height: 15px;
width: 15px;
`}
`;

const StyledBsFillPersonFill = styled(BsFillPersonFill)`
  color: ${({ color }) => color};
  height: 30px;
  width: 30px;

  ${({ mobileView }) =>
    mobileView &&
    `
height: 15px;
width: 15px;
`}
`;

const StyledBsFillLightbulbFill = styled(BsFillLightbulbFill)`
  color: ${({ color }) => color};
  height: 30px;
  width: 30px;

  ${({ mobileView }) =>
    mobileView &&
    `
height: 15px;
width: 15px;
`}
`;

const StyledBsFillQuestionCircleFill = styled(BsFillQuestionCircleFill)`
  color: ${({ color }) => color};
  height: 30px;
  width: 30px;

  ${({ mobileView }) =>
    mobileView &&
    `
height: 15px;
width: 15px;
`}
`;

const StyledFaMobileButton = styled(FaMobileButton)`
  color: ${({ color }) => color};
  height: 30px;
  width: 30px;

  ${({ mobileView }) =>
    mobileView &&
    `
height: 15px;
width: 15px;
`}
`;

const AboutDetailsBlock = ({
  whoIAm,
  whatWeDo,
  purpose,
  preparation,
  whoIAmLabel,
  whatWeDoLabel,
  purposeLabel,
  preparationLabel,
  viewPreparation,
  viewPurpose,
  viewWhatWeDo,
  viewWhoIAm,
  whoIAmIcon,
  whatWeDoIcon,
  purposeIcon,
  preparationIcon,
  setShouldRedirect,
  contribution,
  isCustomBrandingColorsActive,
  brandingColors,
  isLeadMagnet = false,
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const mobileViewTem2 = useMediaQuery(theme.breakpoints.down('sm'));

  const isPurchased = contribution?.isPurchased;
  const type = contribution?.type;
  const isMobileSidebarNeeded = useMediaQuery(theme.breakpoints.down('sm'));

  const testStr = 'coherepublic.s3.amazonaws.com';
  const isCustomWhoIAmIcon = whoIAmIcon?.includes('coherepublic') && whoIAmIcon?.includes('s3.amazonaws.com');
  const isCustomwhatWeDoIcon = whatWeDoIcon?.includes('coherepublic') && whatWeDoIcon?.includes('s3.amazonaws.com');
  const isCustomPurposeIcon = purposeIcon?.includes('coherepublic') && purposeIcon?.includes('s3.amazonaws.com');
  const isCustomPreparationIcon =
    preparationIcon?.includes('coherepublic') && preparationIcon?.includes('s3.amazonaws.com');

  const { themedColor } = getThemedColors(contribution);
  const { themedColors } = useLeadMagnetTheme();
  const newThemedColor = isLeadMagnet ? themedColors.themedColor : themedColor;
  const newIconCOlor = isLeadMagnet ? brandingColors.AccentColorCode : brandingColors.PrimaryColorCode;
  if (!isLeadMagnet && !whoIAm && !whatWeDo && !purpose && !preparation && (mobileView || isMobileSidebarNeeded)) {
    return (
      <Grid item md={12} sm={12} xs={12} style={{ paddingTop: '1rem' }}>
        {!isPurchased && (
          <CustomPurchaseBlock
            isCustomBrandingColorsActive={isCustomBrandingColorsActive}
            brandingColors={brandingColors}
            onJoin={setShouldRedirect}
          />
        )}
      </Grid>
    );
  }

  const MainContainer = styled.div``;

  return (
    <>
      {!mobileView && (
        <>
          <Grid container spacing={4}>
            {isLeadMagnet && (
              <Grid
                item
                md={8}
                sm={8}
                xs={12}
                style={
                  isLeadMagnet
                    ? {
                        paddingTop: '0rem',
                        paddingBottom: '0rem',
                        width: '1020px',
                        margin: 'auto',
                        maxWidth: '80%',
                        flexBasis: '80%',
                      }
                    : {}
                }
              >
                <LeadMagnetPurchaseBlock {...{ brandingColors }} />
              </Grid>
            )}
            <Grid item md={8} sm={8} xs={12} style={isLeadMagnet ? { maxWidth: '100%', flexBasis: '100%' } : {}}>
              <DetailMainContainer>
                {!viewPurpose && !!purpose && (
                  <DetailSideIconMain mobileView={mobileView}>
                    <DetailIconDiv marginTop="5px">
                      {isCustomPurposeIcon ? (
                        <DetailSideIcon themedColor={newThemedColor} src={purposeIcon}></DetailSideIcon>
                      ) : (
                        // <Icon path={mdiHeart} right="5" valign="top" />
                        <StyledBsFillHeartFill color={newIconCOlor} />
                      )}
                    </DetailIconDiv>
                    <DetailRightTxtMain mobileViewTem2={mobileViewTem2}>
                      <DetailRighth1 themedColor={newThemedColor} mobileViewTem2={mobileViewTem2}>
                        {' '}
                        {purposeLabel}
                      </DetailRighth1>
                      <DetailP
                        className="rich-text-contribution"
                        themedColor={newThemedColor}
                        mobileViewTem2={mobileViewTem2}
                      >
                        {wrapTextWithColoredLinks(
                          replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(purpose)),
                          brandingColors?.PrimaryColorCode,
                        )}
                      </DetailP>
                    </DetailRightTxtMain>
                  </DetailSideIconMain>
                )}
                {/* <BorderDiv mobileViewTem2={mobileViewTem2}></BorderDiv> */}

                {!viewWhoIAm && !!whoIAm && (
                  <DetailSideIconMain mobileView={mobileView}>
                    <DetailIconDiv marginTop="4px">
                      {isCustomWhoIAmIcon ? (
                        <DetailSideIcon themedColor={newThemedColor} src={whoIAmIcon}></DetailSideIcon>
                      ) : (
                        // <Icon path={mdiAccountOutline} right="5" valign="top" />
                        <StyledBsFillPersonFill color={newIconCOlor} />
                      )}
                    </DetailIconDiv>
                    <DetailRightTxtMain mobileViewTem2={mobileViewTem2}>
                      <DetailRighth1 themedColor={newThemedColor} mobileViewTem2={mobileViewTem2}>
                        {whoIAmLabel}
                      </DetailRighth1>
                      <DetailP
                        className="rich-text-contribution"
                        themedColor={newThemedColor}
                        mobileViewTem2={mobileViewTem2}
                      >
                        {wrapTextWithColoredLinks(
                          replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(whoIAm)),
                          brandingColors?.PrimaryColorCode,
                        )}
                      </DetailP>
                    </DetailRightTxtMain>
                  </DetailSideIconMain>
                )}
                {/* <BorderDiv mobileViewTem2={mobileViewTem2}></BorderDiv> */}
                {!viewWhatWeDo && whatWeDo && (
                  <DetailSideIconMain mobileView={mobileView}>
                    <DetailIconDiv marginTop="5px">
                      {isCustomwhatWeDoIcon ? (
                        <DetailSideIcon themedColor={newThemedColor} src={whatWeDoIcon}></DetailSideIcon>
                      ) : (
                        // <Icon path={mdiRun} right="5" valign="top" />
                        <StyledBsFillLightbulbFill color={newIconCOlor} />
                      )}
                    </DetailIconDiv>
                    <DetailRightTxtMain mobileViewTem2={mobileViewTem2}>
                      <DetailRighth1 themedColor={newThemedColor} mobileViewTem2={mobileViewTem2}>
                        {whatWeDoLabel}
                      </DetailRighth1>
                      <DetailP
                        className="rich-text-contribution"
                        themedColor={newThemedColor}
                        mobileViewTem2={mobileViewTem2}
                      >
                        {wrapTextWithColoredLinks(
                          replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(whatWeDo)),
                          brandingColors?.PrimaryColorCode,
                        )}
                      </DetailP>
                    </DetailRightTxtMain>
                  </DetailSideIconMain>
                )}
                {/* <BorderDiv mobileViewTem2={mobileViewTem2}></BorderDiv> */}
                {!viewPreparation && preparation && (
                  <DetailSideIconMain mobileView={mobileView}>
                    <DetailIconDiv marginTop="5px">
                      {isCustomPreparationIcon ? (
                        <DetailSideIcon themedColor={newThemedColor} src={preparationIcon}></DetailSideIcon>
                      ) : (
                        // <Icon path={mdiTabletIpad} right="5" valign="top" />
                        <StyledFaMobileButton color={newIconCOlor} />
                      )}
                    </DetailIconDiv>
                    <DetailRightTxtMain mobileViewTem2={mobileViewTem2}>
                      <DetailRighth1 themedColor={newThemedColor} mobileViewTem2={mobileViewTem2}>
                        {preparationLabel}
                      </DetailRighth1>
                      <DetailP
                        className="rich-text-contribution"
                        themedColor={newThemedColor}
                        mobileViewTem2={mobileViewTem2}
                      >
                        {wrapTextWithColoredLinks(
                          replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(preparation)),
                          brandingColors?.PrimaryColorCode,
                        )}
                      </DetailP>
                    </DetailRightTxtMain>
                  </DetailSideIconMain>
                )}
              </DetailMainContainer>
            </Grid>
            <Grid item md={4} sm={4} xs={12} style={{ paddingTop: '0rem' }}>
              {(!isPurchased || contribution?.type === ContributionType.contributionOneToOne) && !isLeadMagnet && (
                <PaymentCard
                  contribution={contribution}
                  type={type}
                  isCustomBrandingColorsActive={isCustomBrandingColorsActive}
                  brandingColors={brandingColors}
                  onJoin={setShouldRedirect}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}

      {mobileView && (
        <>
          <Grid container spacing={4} style={{ flexDirection: 'column-reverse' }}>
            <Grid item md={12} sm={12} xs={12}>
              <DetailMainContainer>
                {!viewPurpose && !!purpose && (
                  <DetailSideIconMain mobileView={mobileView}>
                    <DetailIconDiv>
                      {isCustomPurposeIcon ? (
                        <DetailSideIcon themedColor={newThemedColor} src={purposeIcon}></DetailSideIcon>
                      ) : (
                        // <Icon path={mdiHeart} right="5" valign="top" />
                        <StyledBsFillHeartFill color={newIconCOlor} />
                      )}
                    </DetailIconDiv>
                    <DetailRightTxtMain mobileViewTem2={mobileViewTem2}>
                      <DetailRighth1 themedColor={newThemedColor} mobileViewTem2={mobileViewTem2}>
                        {' '}
                        {purposeLabel}
                      </DetailRighth1>
                      <DetailP
                        className="rich-text-contribution"
                        themedColor={newThemedColor}
                        mobileViewTem2={mobileViewTem2}
                        style={{ textAlign: 'left' }}
                      >
                        {wrapTextWithColoredLinks(
                          replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(purpose)),
                          brandingColors?.PrimaryColorCode,
                        )}
                      </DetailP>
                    </DetailRightTxtMain>
                  </DetailSideIconMain>
                )}
                {/* <BorderDiv mobileViewTem2={mobileViewTem2}></BorderDiv> */}

                {!viewWhoIAm && !!whoIAm && (
                  <DetailSideIconMain mobileView={mobileView}>
                    <DetailIconDiv>
                      {isCustomWhoIAmIcon ? (
                        <DetailSideIcon themedColor={newThemedColor} src={whoIAmIcon}></DetailSideIcon>
                      ) : (
                        // <Icon path={mdiAccountOutline} right="5" valign="top" />
                        <StyledBsFillPersonFill color={newIconCOlor} />
                      )}
                    </DetailIconDiv>
                    <DetailRightTxtMain mobileViewTem2={mobileViewTem2}>
                      <DetailRighth1 themedColor={newThemedColor} mobileViewTem2={mobileViewTem2}>
                        {whoIAmLabel}
                      </DetailRighth1>
                      <DetailP
                        className="rich-text-contribution"
                        themedColor={newThemedColor}
                        mobileViewTem2={mobileViewTem2}
                        style={{ textAlign: 'left' }}
                      >
                        {wrapTextWithColoredLinks(
                          replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(whoIAm)),
                          brandingColors?.PrimaryColorCode,
                        )}
                      </DetailP>
                    </DetailRightTxtMain>
                  </DetailSideIconMain>
                )}
                {/* <BorderDiv mobileViewTem2={mobileViewTem2}></BorderDiv> */}
                {!viewWhatWeDo && whatWeDo && (
                  <DetailSideIconMain mobileView={mobileView}>
                    <DetailIconDiv>
                      {isCustomwhatWeDoIcon ? (
                        <DetailSideIcon themedColor={newThemedColor} src={whatWeDoIcon}></DetailSideIcon>
                      ) : (
                        // <Icon path={mdiRun} right="5" valign="top" />
                        <StyledBsFillLightbulbFill color={newIconCOlor} />
                      )}
                    </DetailIconDiv>
                    <DetailRightTxtMain mobileViewTem2={mobileViewTem2}>
                      <DetailRighth1 themedColor={newThemedColor} mobileViewTem2={mobileViewTem2}>
                        {whatWeDoLabel}
                      </DetailRighth1>
                      <DetailP
                        className="rich-text-contribution"
                        themedColor={newThemedColor}
                        mobileViewTem2={mobileViewTem2}
                        style={{ textAlign: 'left' }}
                      >
                        {wrapTextWithColoredLinks(
                          replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(whatWeDo)),
                          brandingColors?.PrimaryColorCode,
                        )}
                      </DetailP>
                    </DetailRightTxtMain>
                  </DetailSideIconMain>
                )}
                {!viewPreparation && preparation && (
                  <DetailSideIconMain mobileView={mobileView}>
                    <DetailIconDiv>
                      {isCustomPreparationIcon ? (
                        <DetailSideIcon themedColor={newThemedColor} src={preparationIcon}></DetailSideIcon>
                      ) : (
                        // <Icon path={mdiTabletIpad} right="5" valign="top" />
                        <StyledFaMobileButton color={newIconCOlor} />
                      )}
                    </DetailIconDiv>
                    <DetailRightTxtMain mobileViewTem2={mobileViewTem2}>
                      <DetailRighth1 themedColor={newThemedColor} mobileViewTem2={mobileViewTem2}>
                        {preparationLabel}
                      </DetailRighth1>
                      <DetailP
                        className="rich-text-contribution"
                        themedColor={newThemedColor}
                        mobileViewTem2={mobileViewTem2}
                        style={{ textAlign: 'left' }}
                      >
                        {wrapTextWithColoredLinks(
                          replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(preparation)),
                          brandingColors?.PrimaryColorCode,
                        )}
                      </DetailP>
                    </DetailRightTxtMain>
                  </DetailSideIconMain>
                )}
                {/* <BorderDiv mobileViewTem2={mobileViewTem2}></BorderDiv> */}
              </DetailMainContainer>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              {isLeadMagnet && <LeadMagnetPurchaseBlock {...{ brandingColors }} />}
              {(!isPurchased || contribution?.type === ContributionType.contributionOneToOne) && !isLeadMagnet && (
                <PaymentCard
                  contribution={contribution}
                  type={type}
                  isCustomBrandingColorsActive={isCustomBrandingColorsActive}
                  brandingColors={brandingColors}
                  onJoin={setShouldRedirect}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

AboutDetailsBlock.propTypes = {
  whoIAm: PropTypes.string,
  whatWeDo: PropTypes.string,
  purpose: PropTypes.string,
  preparation: PropTypes.string,
  whoIAmLabel: PropTypes.string,
  whatWeDoLabel: PropTypes.string,
  purposeLabel: PropTypes.string,
  preparationLabel: PropTypes.string,
};

AboutDetailsBlock.defaultProps = {
  whoIAm: 'None',
  whatWeDo: 'None',
  purpose: 'None',
  preparation: 'None',
  whoIAmLabel: 'Who this is for',
  whatWeDoLabel: 'What to expect',
  purposeLabel: 'Why this was created',
  preparationLabel: 'Additional details',
};

const mapStateToProps = ({ contributions, account }) => ({
  contribution: contributions?.activeContribution,
  error: contributions?.error,
  loading: contributions?.loading,
  currentRole: account?.currentRole,
});

const actions = {
  getClientContribution: fetchClientContribution,
  getCohealerContribution: fetchCohealerContribution,
  getContributionTimes: fetchContributionTimes,
};

export default connect(mapStateToProps, actions)(AboutDetailsBlock);
