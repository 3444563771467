import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// import Button from 'components/FormUI/Button'
import { determineColorToUse } from 'services/contributions.service';
import { useHttp } from 'hooks';
import Button from 'components/FormUI/Button';
import { lightOrDark } from 'utils/utils';
const BookSessionTime = ({ backgroundColor, onSubmit }) => {
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const { loading } = useHttp();
  let colorToUse = determineColorToUse(activeContribution);
  const btnColor = colorToUse.PrimaryColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  return (
    <Button
      style={{ borderRadius: '5px', textTransform: 'none' }}
      onClick={onSubmit}
      disabled={loading}
      backgroundColor={btnColor}
      textColor={textColor}
    >
      Book Session Time
    </Button>
  );
};

BookSessionTime.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default BookSessionTime;
